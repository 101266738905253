import React, { Fragment, useState, useMemo, useEffect } from "react";
import { useFormik, FieldArray, FormikProvider } from "formik";
import {
  defaultSchema,
  templateTypes,
  autoReplyDefaultData,
  dynamicValidationSchema,
  autoReplyDynamicData,
  GenericTemplateForm,
  ProductForm,
  TextOrLinkForm,
  TemplatePreview,
  getBasicFormSchema
} from "./AutoReplyHelper";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { saveAutoReplyRule } from "../../../services/PostsService";
import { values } from "lodash";
const BasicDetailsForm = ({
  selectedPost,
  orgDetails,
  appendNewRule,
  history,
  activeStep,
  nextStepCallback,
  formDataCallback,
  autoReplyData,
  ruleType
}) => {
  let [templateType, setTemplateType] = useState("generic");
  const formik = useFormik({
    initialValues: autoReplyData || autoReplyDefaultData,
    enableReinitialize: true,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      // saveRule(values);
      nextStepCallback("basicDetails", values);
      //formDataCallback(values);
      // saveRule(values);
    },
    validationSchema: getBasicFormSchema(ruleType),
  });
  function changeTempletType(type) {
    setTemplateType(type);
    formik.setFieldValue("template.type", type);
    formik.values.template = autoReplyDynamicData[values];
  }
  useEffect(() => {
    formik.setFieldValue("ruleType", ruleType);
  }, [ruleType])
  return (
    <Fragment>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-4">
            <label className="mb-2">
              <strong className="input-button-span">Rule Name</strong>
            </label>
            <input type={'hidden'} value={formik.values.ruleType} onChange={formik.handleChange} name={"ruleType"} />
            <input
              type="text"
              name="title"
              placeholder="Enter name here"
              value={formik.values.title}
              onChange={formik.handleChange}
              className="auto-reply-input"
            />
            <span className="text-danger">{formik.errors.title}</span>
          </div>
          <div className={"mb-4"}>
            <label className="mb-2">
              <strong className="input-button-span">Description</strong>
            </label>
            <input
              type="text"
              name="description"
              placeholder="Enter description here"
              value={formik.values.description}
              onChange={formik.handleChange}
              className="auto-reply-input"
            />
            <span className="text-danger">{formik.errors.description}</span>
          </div>
          {ruleType === 'auto_reply' &&
            <div className={"mb-4"}>
              <label className={"mb-2"}>
                <strong className="">Keywords</strong>
              </label>
              <TagsInput
                className="auto-reply-input"
                placeholder="Enter keywords here"
                value={formik.values.keywords}
                name="keywords"
                maxTags={20}
                onChange={(tags) => formik.setFieldValue("keywords", tags)}
              />
              <span className="text-danger">{formik.errors.keywords}</span>
            </div>
          }
          <input
            className={""}
            type={"checkbox"}
            value={formik.values.comment_reply.isEnabled}
            name="comment_reply.isEnabled"
            onChange={formik.handleChange}
            checked={formik.values.comment_reply.isEnabled}
          />
          <label className={"pl-1"}>
            {!!formik.values.comment_reply.isEnabled} Comment Reply
          </label>

          <div className={"mb-4"}>
            <label className={"mb-2"}>
              <strong className={"input-button-span"}>Add Reply</strong>
            </label>
            <div>
              {!!formik.values.comment_reply.isEnabled && (
                <Fragment>
                  <input
                    type="text"
                    name="comment_reply.text"
                    placeholder="Add reply here"
                    value={formik.values.comment_reply.text}
                    onChange={formik.handleChange}
                    className="auto-reply-input"
                  />
                  <span className={"text-danger"}>
                    {formik?.errors?.comment_reply?.text}
                  </span>
                </Fragment>
              )}
            </div>
          </div>
          <button type={"submit"} className={"btn btn-primary  input-button"}>
            Next
          </button>
        </form>
      </FormikProvider>
    </Fragment>
  );
};
export default BasicDetailsForm;