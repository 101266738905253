export const CREATE_POST_ACTION = "[Post Action] Create Post";
export const CONFIRMED_CREATE_POST_ACTION =
  "[Post Action] Confirmed Create Post";
export const GET_POSTS = "[Post Action] Get Posts";
export const CONFIRMED_GET_POSTS = "[Post Action] Confirmed Get Posts";
export const EDIT_POST_ACTION = "[Post Action] Edit Post";
export const CONFIRMED_EDIT_POST_ACTION = "[Post Action] Confirmed Edit Post";
export const CONFIRMED_DELETE_POST_ACTION =
  "[Post Action] Confirmed Delete Post";
export const SET_FILTER_ACTION = "[Filter Action] Filter Action";
export const GET_FILTERS = "[GET Filter Action] Filter Action";
export const SET_PAGE_INSIGHTS = "[SET Page Insights] Insight Action";
export const GET_PAGE_INSIGHTS = "[GET Page Insights] Insight Action";
export const FB_PERMISSIONS = "FB Permissions";
export const LATEST_POST = "Latest Posts";
export const TAGGED_IMAGE = "Latest Images";
export const TAGGED_VIDEOS = "Latest Vidoes";
export const TAGGED_STORIES = "Latest Stories";
export const DESTROY_SESSION = "Destroy Session";
export const GET_AUTO_REPLIES = "GET_AUTO_REPLIES";
export const UPDATE_AUTO_REPLIES = "UPDATE_AUTO_REPLIES";
export const SET_AUTO_REPLIES = "SET_AUTO_REPLIES";
export const GET_BRANDS_POST = "GET_BRANDS_POST";
export const SET_BRANDS_POST = "SET_BRANDS_POST";
export const APPEND_NEW_POSTS = "APPEND_NEW_POSTS";
export const APPEND_NEW_RULE = "APPEND_NEW_RULE";
export const DELETE_RULE = "DELETE_RULE";
export const TOGGLE_RULE = "TOGGLE RULE";
export  const UPDATE_RULE = "UPDATE RULE";



