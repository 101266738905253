import React, { useContext } from "react";
import { ReactReduxContext } from "react-redux";

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import LogoutPage from "./Logout";
/// Image
import profile from "../../../images/profile/17.jpg";
import avatar from "../../../images/avatar/1.jpg";

const Header = ({ onNote, toggle, onProfile, onNotification }) => {
  var path = window.location.pathname.split("/");
  let { store } = useContext(ReactReduxContext);
  let userDetails = store.getState().auth.auth.userDetails;
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
      ? filterName.includes("creators")
      : filterName.filter((f) => f !== "creators")
        ? filterName.filter((f) => f !== "ui")
        : filterName.includes("uc")
          ? filterName.filter((f) => f !== "uc")
          : filterName.includes("basic")
            ? filterName.filter((f) => f !== "basic")
            : filterName.includes("form")
              ? filterName.filter((f) => f !== "form")
              : filterName.includes("table")
                ? filterName.filter((f) => f !== "table")
                : filterName.includes("page")
                  ? filterName.filter((f) => f !== "page")
                  : filterName.includes("email")
                    ? filterName.filter((f) => f !== "email")
                    : filterName.includes("ecom")
                      ? filterName.filter((f) => f !== "ecom")
                      : filterName.includes("chart")
                        ? filterName.filter((f) => f !== "chart")
                        : filterName.includes("editor")
                          ? filterName.filter((f) => f !== "editor")
                          : filterName;
  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.join(" ")}
              </div>
            </div>

            <ul className="navbar-nav header-right">
              {/* <li className="nav-item">
                        <div className="input-group search-area d-lg-inline-flex d-none">
                           <input
                              type="text"
                              className="form-control"
                              placeholder="Search something here..."
                           />
                           <div className="input-group-append">
                              <span className="input-group-text">
                                 <i className="flaticon-381-search-2"></i>
                              </span>
                           </div>
                        </div>
                     </li>*/}
              {/*<li className="nav-item dropdown notification_dropdown">
                        <Link to={"#"}
                           className="nav-link bell bell-link"
                           onClick={() => onNote()}
                        >
                           <svg
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M22.5678 26.5202C22.8079 26.5202 23.0447 26.6115 23.2249 26.7856C24.3769 27.8979 26.0572 28.2683 27.551 27.8047C26.5897 25.802 26.4564 23.5075 27.2014 21.383C28.126 18.7398 28.3577 16.0905 27.3055 13.4334C26.381 11.0992 24.5971 9.15994 22.3395 8.05408C22.4784 8.79455 22.5484 9.54903 22.5484 10.3115C22.5484 13.5478 21.304 16.5916 19.0444 18.8823C16.7846 21.1733 13.7553 22.4615 10.5147 22.5097C9.91267 22.5191 9.31331 22.4837 8.72073 22.4056C10.5017 25.5274 13.8606 27.5606 17.5516 27.6153C19.1663 27.6403 20.7166 27.302 22.1604 26.6125C22.2904 26.5503 22.4296 26.5202 22.5678 26.5202Z"
                                 fill="#3E4954"
                              />
                              <path
                                 d="M10.541 0.00236249C4.79223 -0.111786 0.0134501 4.53885 -0.000411333 10.2863C-0.00380737 11.6906 0.270302 13.052 0.814361 14.3331C0.822262 14.3517 0.829608 14.3706 0.836262 14.3897C1.58124 16.5142 1.4481 18.8086 0.486678 20.8114C1.98059 21.2748 3.66073 20.9046 4.81275 19.7922C5.09656 19.518 5.5212 19.449 5.8773 19.6192C7.3209 20.3087 8.87143 20.648 10.486 20.6221C16.1898 20.5374 20.6576 16.0085 20.6576 10.3117C20.6576 4.73921 16.1193 0.114501 10.541 0.00236249ZM4.81898 11.8517C3.99305 11.8517 3.32348 11.1832 3.32348 10.3587C3.32348 9.53414 3.99305 8.86568 4.81898 8.86568C5.64492 8.86568 6.31449 9.53414 6.31449 10.3587C6.31442 11.1832 5.64492 11.8517 4.81898 11.8517ZM10.3286 11.8517C9.50268 11.8517 8.8331 11.1832 8.8331 10.3587C8.8331 9.53414 9.50268 8.86568 10.3286 8.86568C11.1545 8.86568 11.8241 9.53414 11.8241 10.3587C11.8241 11.1832 11.1545 11.8517 10.3286 11.8517ZM15.8383 11.8517C15.0124 11.8517 14.3428 11.1832 14.3428 10.3587C14.3428 9.53414 15.0124 8.86568 15.8383 8.86568C16.6642 8.86568 17.3338 9.53414 17.3338 10.3587C17.3338 11.1832 16.6642 11.8517 15.8383 11.8517Z"
                                 fill="#3E4954"
                              />
                           </svg>
                           <span className="badge light text-white bg-primary rounded-circle">
                              18
                           </span>
                        </Link>
                     </li>*/}
              <li
                className="nav-item dropdown notification_dropdown"
                style={{ display: "none" }}
              >
                <Link
                  to={"#"}
                  className="nav-link  ai-icon"
                  data-toggle="dropdown"
                  onClick={() => onNotification()}
                >
                  <svg
                    width="26"
                    height="28"
                    viewBox="0 0 26 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.45251 25.6682C10.0606 27.0357 11.4091 28 13.0006 28C14.5922 28 15.9407 27.0357 16.5488 25.6682C15.4266 25.7231 14.2596 25.76 13.0006 25.76C11.7418 25.76 10.5748 25.7231 9.45251 25.6682Z"
                      fill="#3E4954"
                    />
                    <path
                      d="M25.3531 19.74C23.8769 17.8785 21.3995 14.2195 21.3995 10.64C21.3995 7.09073 19.1192 3.89758 15.7995 2.72382C15.7592 1.21406 14.5183 0 13.0006 0C11.4819 0 10.2421 1.21406 10.2017 2.72382C6.88095 3.89758 4.60064 7.09073 4.60064 10.64C4.60064 14.2207 2.12434 17.8785 0.647062 19.74C0.154273 20.3616 0.00191325 21.1825 0.240515 21.9363C0.473484 22.6721 1.05361 23.2422 1.79282 23.4595C3.08755 23.8415 5.20991 24.2715 8.44676 24.491C9.84785 24.5851 11.3543 24.64 13.0007 24.64C14.646 24.64 16.1524 24.5851 17.5535 24.491C20.7914 24.2715 22.9127 23.8415 24.2085 23.4595C24.9477 23.2422 25.5268 22.6722 25.7597 21.9363C25.9983 21.1825 25.8448 20.3616 25.3531 19.74Z"
                      fill="#3E4954"
                    />
                  </svg>
                  <span className="badge light text-white bg-primary rounded-circle">
                    52
                  </span>
                </Link>
                <div
                  className={`dropdown-menu dropdown-menu-right ${toggle === "notification" ? "show" : ""
                    }`}
                >
                  <PerfectScrollbar
                    id="DZ_W_Notification1"
                    className={` widget-media dz-scroll p-3 height380 ${toggle === "notification" ? "ps ps--active-y" : ""
                      }`}
                  >
                    <ul className="timeline">
                      <li>
                        <div className="timeline-panel">
                          <div className="media mr-2">
                            <img alt="avatar" width="50" src={avatar} />
                          </div>
                          <div className="media-body">
                            <h6 className="mb-1">Dr sultads Send you Photo</h6>
                            <small className="d-block">
                              29 July 2020 - 02:26 PM
                            </small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="timeline-panel">
                          <div className="media mr-2 media-info">KG</div>
                          <div className="media-body">
                            <h6 className="mb-1">
                              Resport created successfully
                            </h6>
                            <small className="d-block">
                              29 July 2020 - 02:26 PM
                            </small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="timeline-panel">
                          <div className="media mr-2 media-success">
                            <i className="fa fa-home"></i>
                          </div>
                          <div className="media-body">
                            <h6 className="mb-1">Reminder : Treatment Time!</h6>
                            <small className="d-block">
                              29 July 2020 - 02:26 PM
                            </small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="timeline-panel">
                          <div className="media mr-2">
                            <img alt="avatar" width="50" src={avatar} />
                          </div>
                          <div className="media-body">
                            <h6 className="mb-1">Dr sultads Send you Photo</h6>
                            <small className="d-block">
                              29 July 2020 - 02:26 PM
                            </small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="timeline-panel">
                          <div className="media mr-2 media-danger">KG</div>
                          <div className="media-body">
                            <h6 className="mb-1">
                              Resport created successfully
                            </h6>
                            <small className="d-block">
                              29 July 2020 - 02:26 PM
                            </small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="timeline-panel">
                          <div className="media mr-2 media-primary">
                            <i className="fa fa-home"></i>
                          </div>
                          <div className="media-body">
                            <h6 className="mb-1">Reminder : Treatment Time!</h6>
                            <small className="d-block">
                              29 July 2020 - 02:26 PM
                            </small>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </PerfectScrollbar>
                  <Link to={"#"} className="all-notification">
                    See all notifications <i className="ti-arrow-right"></i>
                  </Link>
                </div>
              </li>
              <li
                className={`nav-item dropdown header-profile ${toggle === "profile" ? "show" : ""
                  }`}
                onClick={() => onProfile()}
              >
                <Link
                  to={"#"}
                  className="nav-link"
                  role="button"
                  data-toggle="dropdown"
                >
                  <img src={userDetails.userProfilePictureURL} width="20" alt="" />
                  <div className="header-info">
                    <span className="text-black">{userDetails.fullName}</span>
                    {/*<p className="fs-12 mb-0">Super Admin</p>*/}
                  </div>
                </Link>
                <div
                  className={`dropdown-menu dropdown-menu-right ${toggle === "profile" ? "show" : ""
                    }`}
                >
                  <Link to="/app-profile" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                      <circle cx="12" cy="7" r="4"></circle>
                    </svg>
                    <span className="ml-2">Profile </span>
                  </Link>
                  {/* <Link to="/email-inbox" className="dropdown-item ai-icon">
                    <svg
                      id="icon-inbox"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-success"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                      <polyline points="22,6 12,13 2,6"></polyline>
                    </svg>
                    <span className="ml-2">Inbox </span>
                  </Link> */}
                  {/* <Link
                    to={"/manage-facebook-pages"}
                    className={"dropdown-item ai-icon"}
                  >
                    <svg
                      id="icon-inbox"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-success"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                      <polyline points="22,6 12,13 2,6"></polyline>
                    </svg>
                    <span className="ml-2">Facebook Pages</span>
                  </Link> */}
                  <LogoutPage />
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
