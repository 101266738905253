import { lazy, Suspense, useEffect } from "react";
/// Components
import Index from "./jsx/index";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter, useHistory } from "react-router-dom";
// action
import { checkAutoLogin } from "./services/AuthService";
import {
  isAuthenticated,
  isAuthorized,
  isFbLoggedIn,
} from "./store/selectors/AuthSelectors";
import CreatorPublicProfile from "./jsx/pages/CreatorPublicProfile";
/// Style
//import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/Custom.css";
import { appendHashtagScript } from "./js/Helpers";

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});
const FacebookPages = lazy(() => import("./jsx/pages/FacebookPages"));
const FbLogin = lazy(() => import("./jsx/pages/FacebookLogin"));
const Organizations = lazy(() => import("./jsx/pages/Organizations"));
function App(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (!document.getElementById("hashtagScript")) {
      // appendHashtagScript();
    }
    if (!props.location.pathname.includes("/p/")) {
      checkAutoLogin(dispatch, props.history); //use same function to check org and auth
    } else {
      history.push(props.location.pathname);
    }
  }, [dispatch, props.history]);

  let routes = (
    <Switch>
      <Route path="/auth/login" component={Login} />
      <Route path="/auth/signup" component={SignUp} />
      <Route path="/auth/page-forgot-password" component={ForgotPassword} />
      <Route path="/auth/fb-login" component={FbLogin}></Route>
      <Route path="/auth/organizations" component={Organizations}></Route>
      <Route path="/p/:handle" component={CreatorPublicProfile}></Route>
      <Route path="/auth/facebook-pages" component={FacebookPages}></Route>
    </Switch>
  );
  let orgRoute = <Switch></Switch>;
  if (props.isAuthenticated && props.isFbLoggedIn && props.isAuthorized) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else if (
    props.isAuthenticated &&
    !props.isFbLoggedIn &&
    !props.isAuthorized
  ) {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    isAuthorized: isAuthorized(state),
    isFbLoggedIn: isFbLoggedIn(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
