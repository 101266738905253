import {
    SIGNUP_CONFIRMED_ACTION,
    LOADING_TOGGLE_ACTION,
    LOGIN_CONFIRMED_ACTION,
    LOGIN_FAILED_ACTION,
    LOGOUT_ACTION,

    SIGNUP_FAILED_ACTION,
    ORG_ACTION, Fb_Auth_Action
} from '../actions/AuthActions';

const initialState = {
    auth: {
        user_email: '',
        user_name: '',
        api_token: '',
        profile_picture: '',
        expireDate: '',
        refreshToken: '',
        fb_token: ''
    },
    org: {
        domain: '',
        org_token: '',
        org_icon_url: '',
        org_name: ''
    },
    fb: {
        isFbLoggedIn: false
    },
    errorMessage: '',
    successMessage: '',
    showLoading: false,
};
export function AuthReducer(state = initialState, action) {
    // if (action.type === SIGNUP_CONFIRMED_ACTION) {
    //     return {
    //         ...state,
    //         auth: action.payload,
    //         errorMessage: '',
    //         successMessage: 'Signup Successfully Completed',
    //         showLoading: false,
    //     };
    // }
    if (action.type === '[login action] confirmed login') {
        return {
            ...state,
            auth: action.payload,
            errorMessage: '',
            successMessage: 'Login Successfully Completed',
            showLoading: false,
        };
    }

    if (action.type === LOGOUT_ACTION) {
        return {
            ...state,
            errorMessage: '',
            successMessage: '',
            auth: {
                email: '',
                idToken: '',
                localId: '',
                expiresIn: '',
                refreshToken: '',
                fbToken: ''
            },
            org: {
                org: {
                    domain: '',
                    org_token: '',
                    org_icon_url: '',
                    org_name: ''
                },
            },
            fb: {
                fb: {
                    fb_token: ''
                }
            }
        };
    }

    if (
        action.type === SIGNUP_FAILED_ACTION ||
        action.type === LOGIN_FAILED_ACTION
    ) {
        return {
            ...state,
            errorMessage: action.payload,
            successMessage: '',
            showLoading: false,
        };
    }

    if (action.type === LOADING_TOGGLE_ACTION) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    if (action.type === ORG_ACTION) {
        return {
            ...state,
            org: action.payload,
            successMessage: "Organization Selected",
            errorMessage: "",
            showLoading: false
        }
    }
    if (action.type === Fb_Auth_Action) {
        return {
            ...state,
            fb: { isFbLoggedIn: action.payload },
            successMessage: 'Fb Logged In',
            errorMessage: '',
            showLoading: false
        }
    }
    return state;
}


