import React, { useState, useEffect } from 'react';
import StoryReplyImg from "../../../images/storyReply.png"

function ChatScreen({ messageData }) {
    const [userProfilePictureURL, setUserProfilePictureURL] = useState("");
    const [fullName, setFullName] = useState("");

    useEffect(() => {
        if (!!localStorage.getItem("tokenDetails")) {
            const tokenDetails = JSON.parse(localStorage.getItem("tokenDetails"));
            const { userDetails } = tokenDetails;
            const { fullName } = userDetails;
            setFullName(fullName);

        }
        if (!!localStorage.getItem("orgDetails")) {
            const tokenDetails = JSON.parse(localStorage.getItem("orgDetails"));
            const { org_icon_url } = tokenDetails;
            setUserProfilePictureURL(org_icon_url);

        }

    }, []);

    return (
        <div className="" id='iphone-x'>

            <div className="iphone-chat-header d-flex align-items-center">
                <div className='user-id'>
                    <img
                        src={userProfilePictureURL}
                        alt="new"
                    />

                </div>
                <span className="iphone-chat-title">{fullName}</span>
            </div>
            <div className="iphone-image pb-0">
                <label className={'mb-0'}>
                    <span style={{ fontSize: '10px' }}>Mentioned you in their story.</span>
                </label>
            </div>
            <div className="iphone-image">

                <img src={StoryReplyImg} alt='image' />
            </div>
            <div className="iphone-chat">
                {
                    !!messageData && (
                        <div className="iphone-chat-messages">
                            <div className="message">
                                <span className="message-text">{messageData}</span>
                            </div>
                        </div>
                    )
                }

            </div>
        </div>
    );
}

export default ChatScreen;
