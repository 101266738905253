import React, { useState, useEffect, Fragment } from "react";
import { useParams, useLocation, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import { showAlert } from "../../../js/Helpers";
import ChatScreen from "./ChatScreen";
import Switch from "react-switch";
import swal from "sweetalert";
import { message } from "../../../message/message"
import { saveStoryReply, getStoryReplies, toggleStoryAutorepy } from "../../../services/PostsService"
function StoryReplyForm({
    history,
    orgDetails,
}) {
    // const { uuid } = useParams();

    const [status, setStatus] = useState()
    const { storyReplyMessagePlaceholder } = message;
    const [messageData, setMessageData] = useState("");
    const [storyCampaignName, setStoryCampaignName] = useState();
    const [uuid, setUuid] = useState()
    const [wordCount, setWordCount] = useState();
    // const wordCount = messageData.trim().split(/\s+/).filter(Boolean).length;
    const wordLimit = 1000;
    function getList() {
        getStoryReplies(orgDetails.org_token).then((res) => {
            !!res.data && (() => {
                if (res?.data?.items && Array.isArray(res?.data?.items) && res?.data?.items.length) {
                    const { rule_uuid, message, status } = res?.data?.items[0];
                    setUuid(rule_uuid);
                    // setStoryCampaignName(title);
                    setMessageData(message);
                    setStatus(status);
                }
            })()

        });
    }
    useEffect(() => {
        getList()
    }, []);

    const saveMessage = (e) => {
        const inputText = e.target.value;
        const wordCount = inputText.trim().length;
        setWordCount(wordCount)
        if (e.key === "Backspace") {
            setMessageData((prevMessageData) => prevMessageData.slice(0, -1));
        } else if ((wordLimit) >= wordCount) {
            setMessageData(inputText);
        } else {
            console.log("Input exceeded word limit!");
        }
    }

    const setStoryCampaign = (e) => {
        if (!!e?.target?.value) {
            setStoryCampaignName(e.target.value)
        }
        else {
            setStoryCampaignName("")
        }
    }
    const goToEditPage = (uuid) => {
        history.push({
            pathname: `/storyreply/edit`
        })

    }
    const submitForm = (e) => {
        showAlert(
            "Are you Sure?",
            "Do you want to Continue?",
            true,
            "warning",
            true,
            () => {
                let data = {};
                // data["title"] = storyCampaignName;
                data["message"] = messageData;
                saveStoryReply(orgDetails.org_token, uuid, data).then((res) => {
                    !!res.data && (() => {
                        swal({
                            title: !!uuid ? "Updated" : "Saved.",
                            icon: "success",
                            button: "Okay",
                        }).then((isConfirm) => {
                            !!isConfirm && goToEditPage(res.data.rule_uuid);
                            const { rule_uuid, message, status } = res?.data;
                            setUuid(rule_uuid);
                            // setStoryCampaignName(title);
                            setMessageData(message);
                            setStatus(status);
                        });
                    })()
                });
            }
        );
    }
    function toggleCommentAutorepyFn() {
        //setToggle(!toggle);
        showAlert(
            "Are you sure?",
            `Do you want to ${status !== "ACTIVE" ? "Enable" : "Disable"} the story reply?`,
            true,
            "warning",
            true,
            () => {
                toggleStoryAutorepy(status, orgDetails.org_token, uuid)
                    .then((res) => {
                        const val = status !== "ACTIVE" ? "ACTIVE" : "INACTIVE"
                        setStatus(val)
                        swal({
                            title: `${status !== "ACTIVE" ? "Enabled" : "Disabled"}`,
                            icon: "success",
                            button: "Okay",
                        }).then((isConfirm) => {


                        })

                    })
            }
        );
    }


    return (
        <Fragment>
            <div className="login-form-bx story-reply-box w-100">
                <div className="container-fluid" style={{ paddingTop: "0" }}>

                    {!!uuid && <div className="enable-disable" >
                        <div className="enable-disable-internal">
                            <label className="mb-2 ">
                                <span className={'ml-2'}>{(status === 'ACTIVE') ? "Enable" : "Disable"}</span>
                            </label>
                            <Switch className="story-reply-switch" onChange={toggleCommentAutorepyFn}
                                checked={!!(status === 'ACTIVE')} checkedIcon={false} uncheckedIcon={false}>
                            </Switch>
                        </div>
                    </div>}
                    <div className={`row ${!!uuid && status !== "ACTIVE" ? "blur-div" : ""}`} style={{ marginLeft: "0px !importent", marginRight: "0px !importent" }} >
                        <div className="col-lg-6 col-md-7 box-skew story-reply-box-size d-flex align-items-center justify-content-center">
                            <div className="authincation-content remove-shadow">
                                <div className="mb-4">
                                    <h3 className="mb-1 font-w600">
                                        Type your automated reply to story mentions
                                    </h3>
                                </div>
                                <form >
                                    {/* <div className="form-group">
                                        <label className="mb-2 ">
                                            <span >Name</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control ${!!uuid && status !== "ACTIVE" ? "blur-inout" : ""}`}
                                            placeholder={!!uuid ? storyCampaignName : "Enter Name"}
                                            value={storyCampaignName}
                                            onChange={setStoryCampaign}
                                        />
                                    </div> */}

                                    <div className="form-group">
                                        <label className="mb-2 ">
                                            <span >Message</span>
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-control ${!!uuid && status !== "ACTIVE" ? "blur-inout" : ""} `}
                                            onChange={saveMessage}
                                            value={messageData}
                                            placeholder={!!uuid ? messageData : storyReplyMessagePlaceholder}

                                        />
                                        {wordCount >= wordLimit && (
                                            <div className="text-danger fs-12">Charaters limit exceeded!</div>
                                        )
                                        }
                                        <span className="count">{messageData.length}/{wordLimit}</span><br /><br />

                                    </div>

                                    <div >
                                        {!!uuid ? <button onClick={submitForm} disabled={(status === "ACTIVE" && !!messageData) ? false : true} form="rewardRuleForm" className="btn btn-secondary" >
                                            Update
                                        </button>
                                            : <button onClick={submitForm} disabled={(!!messageData) ? false : true} form="rewardRuleForm" className=" btn btn-rounded " style={{ display: "flex", justifyContent: "center", alignItems: "end", flexDirection: "row-reverse", backgroundColor: "rgb(76, 2, 255)", color: "white" }}>Create</button>

                                        }
                                    </div>
                                </form>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-5 d-flex box-skew1 story-reply-box-size1 justify-content-center align-items-center">

                            <ChatScreen messageData={messageData} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
        orgDetails: state.auth.org,
        location: state.location
    };
};


export default connect(mapStateToProps)(StoryReplyForm); 
