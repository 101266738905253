import React, { Fragment, useState, useEffect } from "react";
import { Card, Col } from "react-bootstrap";
import BasicDetailsForm from "./BasicDetailsForm";
import { useParams, useLocation, useSearchParams } from "react-router-dom";
import {
  AutoReplyRuleInitialData,
  SelectPost,
  prepareBasicDetailsData,
  prepareGenricDetailsData,
  basicDetailsData,
  autoReplyDefaultData,
  getTextOrLinkTemplateJson,
  preparesTextFormData
} from "./AutoReplyHelper";
import { connect } from "react-redux";
import {
  getDummyPosts,
  saveAutoReplyRule,
  validatePost,
  fetchRule,
} from "../../../services/PostsService";
import { appendNewPosts, appendNewReply, setBrandPosts, updateAutoReply } from "../../../store/actions";
import { Stepper, Step, StepLabel } from "material-ui";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import MessageForm from "./MessageForm";
import { StepConnector } from "@material-ui/core";
import { showAlert } from "../../../js/Helpers";
import { ThreeDotsLoader, useQuery } from "../Commons/Common";
import { ToastContainer, toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import swal from "sweetalert";
const CreateAutoReply = ({
  history,
  posts,
  setPosts,
  orgDetails,
  appendNewRule,
  updateAutoReply,
  appendNewPosts,
}) => {
  const { uuid } = useParams();
  let searchParams = useQuery();
  const [steps, setSteps] = useState([
    { label: "Select post", isCompleted: false },
    { label: "Basic information", isCompleted: false },
    { label: "Message", isCompleted: false },
  ]);
  let [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep === steps.length - 1;
  let [templateType, setTemplateType] = useState("generic");
  let ruleType = ['auto_reply', 'default_reply'].includes(searchParams.get("type")) ? searchParams.get("type") : 'auto_reply';
  let [postInfo, setPostInfo] = useState({
    mediaId: "",
    mediaType: "",
    mediaUrl: "",
  });
  let [basicDetailsFormData, setBasicDetailsFormData] = useState(null);
  let [genericFormDetails, setGenericFormDetails] = useState(null);
  let [textFormData, setTextFormData] = useState(getTextOrLinkTemplateJson);
  let [data, setData] = useState({});
  let [isPostFetching, setIsPostFetching] = useState(true)

  let nextStepCallback = (type, payload, templateType) => {
    if (type === "basicDetails") {
      setActiveStep(activeStep + 1);
      setBasicDetailsFormData(payload);
    } else if (type === "templatePayload") {
      //setActiveStep(activeStep + 1);
      //setGenericFormDetails(payload);
      saveRule(templateType, payload);
    }
  };
  function goToListPage() {
    console.log("list page called phle hi");
    history.push(`/autoreply/list?type=${ruleType}`);
  }
  function saveRule(templateType, finalPayload) {
    showAlert(
      "Are You sure?",
      `Do you want the ${!!uuid ? "update" : "save"} the rule?`,
      true,
      "warning",
      true,
      function () {
        let data = {};
        data["media_id"] = postInfo.mediaId;
        data["media_url"] = postInfo.mediaUrl;
        data["media_type"] = postInfo.mediaType;
        data["template_type"] = templateType;
        Object.assign(data, basicDetailsFormData);
        data["template"] && (data.template = finalPayload);
        saveAutoReplyRule(data, orgDetails.org_token, uuid, ruleType).then((res) => {
          !!res.data && (() => {
            /*!!uuid &&  updateAutoReply(uuid, res.data);
            !uuid && appendNewRule(res.data);*/
            swal({
              title: !!uuid ? "Updated" : "Saved.",
              icon: "success",
              button: "Okay",
            }).then((isConfirm) => {
              !!isConfirm && goToListPage();
            });
          })()
        });
      }
    );
  }
  function setRuleData() {
    fetchRule(uuid, orgDetails.org_token, ruleType).then((res) => {
      console.log(res.data);
      const ruleData = res.data;
      setTemplateType(ruleData.template_type);
      setPostInfo({ mediaId: ruleData.media_id, mediaType: ruleData.media_type, mediaUrl: ruleData.media_url });
      setActiveStep(1);
      setBasicDetailsFormData(prepareBasicDetailsData(ruleData));
      (ruleData.template_type === 'generic') ? setGenericFormDetails(prepareGenricDetailsData(ruleData)) : setTextFormData(preparesTextFormData(ruleData));
    });
  }
  function getPosts() {
    setIsPostFetching(!posts.data.length);
    getDummyPosts(orgDetails.org_token, posts.nextUrl).then((res) => {
      !!res?.data?.data.length &&
        setPosts({
          data: [...posts.data, ...res.data.data],
          count: 20,
          isLoading: false,
          nextUrl: res.data?.paging?.next
        });
      setIsPostFetching(false);
      !!res.data &&
        !!res.data.errorMsg &&
        setPosts({ data: [], count: 0, isLoading: false });
    });
  }
  useEffect(() => {
    setPosts({ data: [], count: 0, isLoading: true, nextUrl: '' });
    !!uuid && setRuleData();
    !uuid && !posts.data.length && getPosts();
    return () => {
      setPosts({ data: [], count: 0, isLoading: true, nextUrl: '' })
    }
  }, []);
  function _renderStepContent(step, formikContext) {
    switch (step) {
      case 0:
        return (
          <InfiniteScroll className={'w-100'} dataLength={posts.data.length} next={() => getPosts()} hasMore={!!posts.nextUrl} endMessage={posts.data.length > 24 ? <p style={{ textAlign: 'center' }}><b>Yay! You have seen it all</b></p> : ''}
            loader={<ThreeDotsLoader hAlign={'center'} vAlign={'center'} text={"Fetching"} height={100} width={100} />}>
            <SelectPost
              posts={posts.data}
              onPostSelect={(post, mediaId, mediaUrl) =>
                onPostSelect(post, mediaId, mediaUrl)
              }
              isLoading={isPostFetching}
            />
          </InfiniteScroll>
        );
      case 1:
        return (
          <div
            className={"p-0 w-100"}
            style={{ display: "grid", gridTemplateColumns: "auto 340px" }}
          >
            <div className={"bg-white rounded p-4"}>
              <div className="basic-info">
                <label className={"mb-0"}>
                  <strong>Basic information</strong>
                </label>
              </div>
              <div className={"p-3"}>
                {
                  <BasicDetailsForm
                    selectedPost={postInfo.mediaId}
                    orgDetails={orgDetails}
                    appendNewRule={appendNewRule}
                    history={history}
                    activeStep={activeStep}
                    nextStepCallback={nextStepCallback}
                    autoReplyData={basicDetailsFormData}
                    //formDataCallback={formDataCallback}
                    ruleType={ruleType}
                  ></BasicDetailsForm>
                }
              </div>
            </div>
            <div></div>
          </div>
        );

      default:
        return (
          <MessageForm
            selectedPost={postInfo.mediaId}
            orgDetails={orgDetails}
            history={history}
            submitData={data}
            nextStepCallback={nextStepCallback}
            genericFormDetails={genericFormDetails}
            uuid={uuid}
            selectedTemplateType={templateType}
            textFormData={textFormData}
          />
        );
    }
  }
  //console.log("Brands ki post", posts);
  //let [posts, setPosts] = useState([]);
  function onPostSelect(post, mediaType, mediaurl) {
    showAlert(
      "Are You sure?",
      "Do you want to select this post?",
      true,
      "warning",
      true,
      function () {
        validatePost(post)
          .then((res) => {
            console.log(res);
            if (res.data.hasOwnProperty(ruleType) && !res.data[ruleType]) {
              showAlert(
                res.details,
                `This post is being used in another ${ruleType === 'auto_reply' ? 'Keyword Reply' : 'Default Reply'}. Please select different post.`,
                false,
                "error",
                true,
                function () {
                  console.log("Aborted");
                }
              );
            } else {
              setActiveStep(activeStep + 1);
              //setSelectedPost(post);
              setPostInfo({
                mediaId: post,
                mediaType: mediaType,
                mediaUrl: mediaurl,
              });
            }
          })
          .catch((e) => {
            toast.error("Something went wrong. Please try again.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          });
      }
    );
  }
  function moveToNextStep() {
    if (activeStep === 0) {
      history.push("/autoreply/list");
    } else if (activeStep === 1 && !!uuid) {
      history.push("/autoreply/list")
    } else {
      setActiveStep(activeStep - 1);
    }
  }

  return (
    <>
      <div
        style={{ display: "grid", gridTemplateColumns: "50% 50%" }}
        className={"w-100"}
      >
        <div className={"d-flex align-items-center"}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role={"button"}
            onClick={() => moveToNextStep()}
          >
            <path
              d="M8 16L0 8L8 0L9.05 1.05L2.85 7.25H16V8.75H2.85L9.05 14.95L8 16Z"
              fill="black"
            />
          </svg>
          <p
            className={"ml-2 mb-0"}
            style={{
              color: "black",
              fontSize: "20px",
              backgroundColor: "#f7f7f7",
            }}
            role={"button"}
            onClick={() => moveToNextStep()}
          >
            Create rule
          </p>
        </div>
        <MuiThemeProvider>
          <Stepper connector={false} activeStep={activeStep}>
            {steps.map((step) => (
              <Step>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </MuiThemeProvider>
        <ToastContainer />
      </div>
      {<div className={'w-100'}>{_renderStepContent(activeStep)}</div>}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
    posts: state.brandPosts,
    orgDetails: state.auth.org,
    location: state.location
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    appendNewRule: (rule) => dispatch(appendNewReply(rule)),
    setPosts: (posts) => dispatch(setBrandPosts(posts)),
    updateAutoReply: (uuid, data) => {
      dispatch(updateAutoReply({ data: data, uuid: uuid }))
    }
  };

};
export default connect(mapStateToProps, mapDispatchToProps)(CreateAutoReply);