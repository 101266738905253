import React, { Fragment, useEffect, useState } from "react";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { rewardTypes, showAlert, TableHeaders } from "../../../js/Helpers";
import { Card, Button, Table, Form as BootstrapForm, Modal } from "react-bootstrap";
import PostModal from "./../Commons/PostModal";
import { CustomCheckbox, TableHead, ThreeDotsLoader } from "../Commons/Common";
import moment from "moment";
import { deleteAutoReplyRule, toggleCommentAutorepy, saveAutoReplyRule } from "../../../services/PostsService"
import { useFormik } from "formik";
import swal from "sweetalert";
import Switch from "react-switch";
import InfiniteScroll from "react-infinite-scroll-component"
import Moment from "react-moment";

export let GenericTemplateValidationSchema = yup.object({
  message: yup.object().shape({
    attachment: yup.object().shape({
      type: yup.string(),
      payload: yup.object().shape({
        template_type: yup.string(),
        elements: yup.array().of(
          yup.object().shape({
            title: yup.string().required("Title is required"),
            image_url: yup.string().required("Image is required"),
            subtitle: yup.string().required("Subtitle is required"),
            default_action: {
              type: yup.string(),
              url: yup.string(),
            },
            buttons: yup.array().of(
              yup.object().shape({
                type: yup.string(),
                url: yup.string().required("Url is required"),
                title: yup.string().required("Button title is required"),
              })
            ),
          })
        ),
      }),
    }),
  }),
});

let getProductTemplateValidationSchema = () => {
  let schema = yup.object({
    message: yup.object().shape({
      attachment: yup.object().shape({
        type: yup.string(),
        payload: yup.object().shape({
          template_type: yup.string(),
          elements: yup.array().of(
            yup.object().shape({
              id: yup.string().required("Product is required"),
            })
          ),
        }),
      }),
    }),
  });
  return schema;
};
let getTextOrLinkTemplateValidationSchema = () => {
  let schema = yup.object({
    message: yup.object().shape({
      text: yup.string().required("Please provide text or link"),
    }),
  });
  return schema;
};


export const getBasicFormSchema = () => {
  let schema = yup.object({
    ruleType: yup.string(),
    title: yup.string().required("Name is required"),
    description: yup.string(),
    keywords: yup
      .array()
      .when("ruleType", {
        is: 'auto_reply',
        then: yup.array().max(20, "Only 20 keywords are allowed")
          .min(1, "Provide atleast one tag"),
        otherwise: yup.array()
      }),
    type: yup.string(),
    //template: dynamicValidationSchema["generic"],
    comment_reply: yup.object().shape({
      isEnabled: yup.boolean(),
      text: yup.string().when("isEnabled", {
        is: true,
        then: yup.string().required("Comment Reply text is required"),
        otherwise: yup.string().notRequired(),
      }),
    }),
  });
  return schema
}
export let getGenericTemplateJson = {
  message: {
    attachment: {
      type: "template",
      payload: {
        template_type: "generic",
        elements: [
          {
            title: "Welcome Back!",
            image_url:
              "https://storage.googleapis.com/media.wigzo.com/uploads/pF3NmMonSuaRiJ2M6eDv4A/userimagegallery/bf61528c-4004-40bf-945c-b1f4fc1b327a.png?_=2023-05-11T06:14:17.890Z",
            subtitle: "We have the right hat for everyone.",
            default_action: {
              type: "web_url",
              url: "https://www.originalcoastclothing.com",
            },
            buttons: [
              /*{
                                    "type": "web_url",
                                    "url": "https://www.originalcoastclothing.com",
                                    "title": "View Website"
                                }*/
            ],
          },
        ],
      },
    },
  },
};
let getProductTemplateJson = () => {
  return {
    message: {
      attachment: {
        type: "template",
        payload: {
          template_type: "product",
          elements: [
            {
              id: "",
            },
          ],
        },
      },
    },
  };
};
export let getTextOrLinkTemplateJson = {
  message: {
    text: "",
  },
};
export const autoReplyDynamicData = {
  generic: getGenericTemplateJson,
  text: getTextOrLinkTemplateJson,
};
export const autoReplyDefaultData = {
  title: "",
  description: "",
  ruleType: "",
  keywords: [],
  template: getGenericTemplateJson,
  comment_reply: {
    isEnabled: true,
    text: "",
  },
};
export const Post = ({ post, onPostSelect }) => {
  let [show, setShow] = useState(false);
  let hideModal = () => {
    setShow(false);
  };
  let showModal = () => {
    setShow(true);
  };
  return (
    <Fragment>
      <PostModal
        showModal={show}
        data={post}
        hideModal={() => hideModal()}
        userProfile={{}}
        mediaType={post.media_type}
        mediaUrl={post.media_url}
      />
      <label className="brand-post" key={post.id}>
        {/*<input type='radio' className='reward-type-input' name='rewardType' value={post.id} onChange={(e) => onPostSelect(e.target.value)}></input>*/}
        <div className={"reward-type w-100 p-3"}>
          <img
            src={
              post.media_type === "VIDEO" ? post.thumbnail_url : post.media_url
            }
            className={"mb-2"}
            onClick={(e) =>
              onPostSelect(
                post.id,
                post.media_type,
                post.media_type === "VIDEO"
                  ? post.thumbnail_url
                  : post.media_url
              )
            }
          ></img>
          {/*<h3 className='mt-2'>{post.title}</h3>*/}
          {/*<p className='text-center'>{post?.caption}</p>*/}
          <div
            className={"d-flex justify-content-end w-100 align-items-center"}
          >
            <span className={"mr-3"}>
              <i
                style={{ color: "#9266FD" }}
                className="fa fa-eye pull-right"
                onClick={() => showModal()}
              ></i>
            </span>
            <span>
              <a href={post.permalink} target={"_blank"}>
                {" "}
                <i
                  style={{ color: "#9266FD" }}
                  className={"fa fa-external-link"}
                ></i>
              </a>
            </span>
          </div>
        </div>
      </label>
    </Fragment>
  );
};
export const templateTypes = [
  { label: "Generic", value: "generic" },
  { label: "Text or Link", value: "text" },
];
export const SelectPost = ({ posts, onPostSelect, isLoading }) => {
  return (
    <Fragment>
      <div className={"bg-white p-4 rounded w-100"}>
        <div>
          <h4 style={{ color: "black" }}>Select Post</h4>
          <h4 style={{ color: "grey" }}>
            Click on a post and go to the next step
          </h4>
        </div>
        {!!isLoading && (
          <div className={"mb-5"}>
            <ThreeDotsLoader
              height={100}
              width={100}
              text={"Hold on! We are fetching latest post."}
            />
          </div>
        )}
        {!isLoading && (
          <div className={"brand-post-grid"}>
            {!!posts.length &&
              posts.map((post) => (
                <Post post={post} onPostSelect={onPostSelect} />
              ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export const TemplatePreview = ({ values, templateType }) => {
  return (
    <Fragment>
      {templateType === "generic" && (
        <Card className={"template-preview"}>
          <Card.Img
            variant="top"
            src={values?.message?.attachment?.payload?.elements[0]?.image_url}
          />
          <Card.Body>
            <Card.Title>
              {values?.message?.attachment?.payload?.elements[0]?.title}
            </Card.Title>
            <Card.Text>
              {values?.message?.attachment?.payload?.elements[0]?.subtitle}
            </Card.Text>
            <div className={"template-preview-buttons"}>
              {!!values?.message?.attachment?.payload?.elements[0]?.buttons
                .length &&
                values?.message?.attachment?.payload?.elements[0]?.buttons.map(
                  (button, index) => (
                    <Button variant="primary">{button.title}</Button>
                  )
                )}
            </div>
          </Card.Body>
        </Card>
      )}
    </Fragment>
  );
};
const NoRule = () => {
  return (
    <Fragment>
      <tr>
        <td colSpan={5} className={"text-center pt-4 pb-4"}>
          <img src="/no-rule.svg"></img>
          <h6 className="mt-3">No rules have been created yet!</h6>
          <p>Create a new rule by clicking on Create Rule Button.</p>
        </td>
      </tr>
    </Fragment>
  );
};

export const AutoReplyRuleTable = ({ ruleData, deleteRule, history, orgDetails, toggleRule, ruleType, getList }) => {
  console.log("ORG DETAILS", orgDetails)
  function toggleCommentAutorepyFn(status, uuid) {
    //setToggle(!toggle);
    showAlert(
      "Are you sure?",
      `Do you want to ${status !== "ACTIVE" ? "Enable" : "Disable"} the rule?`,
      true,
      "warning",
      true,
      () => {
        toggleCommentAutorepy(status, orgDetails.org_token, uuid, ruleType).then((res) => {
          console.log("TOGGLE REPONSE", res)
          if (res.data.status === 'success') {
            swal({
              title: `${status !== "ACTIVE" ? "Enabled" : "Disabled"}`,
              icon: "success",
              button: "Okay",
            }).then((isConfirm) => {
              getList(ruleType)
            })
          }

        });
      }
    );
  }
  function renderKeywordsList(keywords) {
    let keywordStr;
    try {
      keywordStr = JSON.parse(keywords);
      Array.isArray(keywordStr) &&
        (keywordStr = keywordStr.map((str) => (
          <span
            key={str}
            className={"badge badge-pill rounded mr-1 mb-1"}
            style={{ backgroundColor: "#E5DFF8", color: "#392297" }}
          >
            {str}
          </span>
        )));
      return keywordStr;
    } catch (e) {
      keywordStr = keywords;
    }
    return keywords;
  }
  function editRule(uuid) {
    //history.push(`/autoreply/edit/${uuid}`);
    history.push({
      pathname: `/autoreply/edit/${uuid}`,
      search: `?type=${ruleType}`
    })
  }
  const formatDateTime = (dateTime) => {
    const formattedDateTime = moment(dateTime, 'YYYY-DD-MMTHH:mm:ssZ').format('LLLL');
    return formattedDateTime
  }
  const [toggle, setToggle] = useState(false);

  return (
    <Fragment>
      <Table responsive className={"mb-0"}>
        <TableHead
          headers={ruleType === 'auto_reply' ? TableHeaders.AUTO_REPLIES.data : TableHeaders.DEFAULT_REPLIES.data}
          hasCheckbox={false}
        />
        <tbody>
          {!!ruleData?.length &&
            ruleData.map((datum) => (
              <tr key={datum.id}>
                <td className={"d-flex align-items-center"}>
                  <img
                    src={datum.media_url || "/content/fallback-src.svg"}
                    className={" mr-2"}
                    style={{
                      height: "35px",
                      width: "50px",
                      objectFit: "cover",
                      borderRadius: "3px"
                    }}
                  />
                  <div>
                    <h6 className={"mb-0"}>{datum.title}</h6>
                    <span className={"text-muted"}>{datum.description}</span>
                  </div>
                </td>
                {ruleType === 'auto_reply' && <td>{renderKeywordsList(datum.keywords)}</td>}
                <td>{datum.template_type}</td>
                <td>
                  {formatDateTime(datum?.updated_at)}
                </td>
                <td>
                  {/* <label className="switch mx-2">
                    <input type="checkbox" onClick={() => toggleCommentAutorepyFn(!toggle,orgDetails.org_token,datum.rule_uuid)}/>
                      <span className="slider round"></span>
                  </label>
                  {!!toggle?"Disable":"Enable"}*/}
                  <div title={`${(datum.status === 'ACTIVE') ? "Disable" : "Enable"} rule.`} className={'d-flex align-items-center'}>
                    <i title={"Delete Rule"}
                      className={"fa fa-trash mr-2"}
                      role={"button"} style={{ fontSize: '22px' }}
                      onClick={() => deleteRule(datum.rule_uuid)}
                    ></i>
                    <i title={"Edit Rule"}
                      className={"fa fa-edit mr-2"}
                      role={"button"}
                      style={{ fontSize: '22px' }}
                      onClick={() => editRule(datum.rule_uuid)}
                    ></i>
                    <Switch onChange={() => toggleCommentAutorepyFn(datum.status, datum.rule_uuid)}
                      checked={!!(datum.status === 'ACTIVE')} checkedIcon={false} uncheckedIcon={false}></Switch>
                    <span className={'ml-2'}>{(datum.status === 'ACTIVE') ? "Enabled" : "Disabled"}</span>
                  </div>
                </td>
              </tr>
            ))}
          {!ruleData.length && <NoRule></NoRule>}
        </tbody>
      </Table>
    </Fragment>
  );
};

export const prepareBasicDetailsData = (data) => {
  return {
    title: data?.title,
    description: data?.description,
    keywords: data?.keywords ? JSON.parse(data.keywords) : [],
    template: getGenericTemplateJson,
    comment_reply: {
      isEnabled: !!data?.comment_reply,
      text: data?.comment_reply,
    },
  };
};
export const prepareGenricDetailsData = (data) => {
  return {
    template_type: data?.template_type,
    template: data?.template ? JSON.parse(data.template) : [],
  };

};
export const preparesTextFormData = data => {
  let retObj;
  try {
    retObj = JSON.parse(data.template)
  } catch (err) {
    retObj = {
      message: {
        text: ''
      }
    }
  }
  return retObj;
};
const replyTypes = {
  discount_coupon: {
    title: "Keyword Reply",
    value: "auto_reply",
    description: "Send DM based on defined keywords",
    bgColor: "#EDFBFE",
    iconUrl: "/auto-reply/auto_reply.svg",
    disabled: false,
  },
  product_gifting: {
    title: "Default Reply",
    value: "default_reply",
    description: "Send default DM to specific post.",
    bgColor: "#FDF6E9",
    iconUrl: "/auto-reply/default_reply.svg",
    disabled: true,
  },
}

export const SelectRuleTypeModal = ({ showModal, toggleModal, ruleType }) => {
  let [selectedType, setSelectedType] = useState(ruleType || '');
  let history = useHistory();
  function selectType() {
    toggleModal(!showModal);
    history.push({
      pathname: '/autoreply/create', search: `?type=${selectedType}`
    })
  }
  useEffect(() => {
    return (() => {
      setSelectedType('');
    })
  }, [])
  return (
    <Modal className={'fade bd-example-modal-lg'} show={showModal} size={'lg'} onExited={() => {
      return true;
    }} onHide={() => {
      return true;
    }}>
      <Modal.Header>
        <Modal.Title>Select Rule Type</Modal.Title>
        <Button
          variant=""
          className="close"
          onClick={() => toggleModal()}
        >
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <h4>What do you want to create?</h4>
        <div className={'w-100 d-flex justify-content-start flex-wrap mt-5'} style={{ gap: '2rem' }}>
          {
            Object.keys(replyTypes).map(type => {
              return (
                <label className='' key={replyTypes[type].value}>
                  <input type='radio' className='reward-type-input' name='rewardType' checked={selectedType === replyTypes[type].value}
                    value={replyTypes[type].value} onChange={(e) => setSelectedType(e.target.value)} />
                  <div className={"reward-type"} style={{ background: replyTypes[type].bgColor, minHeight: '270px', width: '230px' }}>
                    <img src={replyTypes[type].iconUrl} className={'mb-2'} />
                    <h3 className='mt-2'>{replyTypes[type].title}</h3>
                    <p className='text-center'>{replyTypes[type].description}</p>
                  </div>
                </label>
              )
            })
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger light" onClick={() => toggleModal()}>
          Close
        </Button>
        <Button
          variant=""
          type="button"
          className="btn btn-primary"
          disabled={!selectedType}
          onClick={() => selectType()}
        >
          Select
        </Button>
      </Modal.Footer>
    </Modal>
  )
};