import React, { Fragment } from 'react'
import { Card, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { HeaderStrip } from '../Commons/Common'
import RuleForm from './RuleForm';
import { rewardRuleInitialData, showAlert } from '../../../js/Helpers'
import { saveOrUpdateRule } from '../../../services/PostsService'
const CreateRewardRule = (props) => {
    const { type } = useParams()
    const orgDetails = props.auth.org;
    let saveRule = (ruleData) => {
        showAlert("Are You Sure?", "Do you want to create the rule?", true, 'warning', 'OK', () => {
            saveOrUpdateRule(ruleData, orgDetails.org_token).then(res => {
                showAlert("Created", "", false, 'warning', 'Go to List', () => {
                    props.history.push('/rewards/list')
                })
            })
        })
    }
    return (
        <Fragment>
            <HeaderStrip title={'Create Rule'} buttonTitle={'Save'} type={'submit'}></HeaderStrip>
            <Col xl={9} md={10}>
                <Card>
                    <Card.Body>
                        <RuleForm type={type} formData={rewardRuleInitialData} org={orgDetails} history={props.history} saveRule={(ruleData) => saveRule(ruleData)}></RuleForm>
                    </Card.Body>
                </Card>
            </Col>
        </Fragment >
    )
}

const mapStateToProps = (state) => {
    return {
        ...state
    }
}
export default connect(mapStateToProps)(CreateRewardRule);