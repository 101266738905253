import React, { Fragment, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { getInfluencerProfile } from "../../services/PostsService";
import { Row, Col } from "react-bootstrap";
import {
  ThreeDotsLoader,
  HeadingStrip,
  UnsupportedMedia,
  PermissionMissing,
  GoBack,
  InstaStory,
  InstaPostCard
} from "../components/Commons/Common";
import _ from "lodash";
import { dummyPosts, chartDataForImpressions, chartDataForEngagement, prepareChartData } from "../../js/Helpers";
import { abbreviateNumber } from "js-abbreviation-number";
import ReactApexChart from "react-apexcharts";

const CreatorProfile = () => {
  const { handle } = useParams();
  const location = useLocation();
  const isMobile = window.innerWidth < 768 ? true : false;
  const org = useSelector((store) => store.auth.org);
  const [profileData, setProfileData] = useState({
    data: {},
    tagged_posts: [],
    loading: true,
    hasError: false,
    hasPermission: false
  });
  let [chartData, setChartData] = useState({
    impression: chartDataForImpressions,
    reach: chartDataForEngagement,
  });
  useEffect(() => {
    const fetchData = async () => {
      setProfileData({ data: [], tagged_posts: [], loading: true, hasError: false, hasPermission: false });
      try {
        var fetchedData = await getInfluencerProfile(handle, 'day', org.org_token);
        !!fetchedData &&
          !!fetchedData.data &&
          setProfileData({
            data: !!fetchedData.data.profile ? fetchedData.data.profile : fetchedData.data.extra_data,
            tagged_posts: fetchedData?.data?.tagged_posts || [],
            loading: false,
            hasError: false,
            hasPermission: !!fetchedData.data.profile
          });
        document.title = "NitroCollab - " + fetchedData?.data?.username
        let chartOptions = {
          impression: prepareChartData(
            fetchedData.data?.insights,
            "#1AB1F4",
            "Impressions",
            false
          ),
          reach: prepareChartData(
            fetchedData.data?.insights,
            "#FFA400",
            "Reach",
            false
          ),
        };
        setChartData(chartOptions);
      } catch (error) {
        setProfileData({
          data: !!fetchedData.data.profile ? fetchedData.data.profile : fetchedData.data.extra_data,
          tagged_posts: !!fetchedData?.data?.tagged_posts ? fetchedData?.data?.tagged_posts : [],
          hasPermission: false,
          loading: false,
          hasError: true,
        });
      }
    };
    fetchData();
  }, [handle]);
  return (
    <Fragment>
      <GoBack></GoBack>
      <Row style={!!location.pathname.includes('/p/') && !!isMobile ? { padding: '20px', margin: 0 } : !!location.pathname.includes('/p/') && !isMobile ? { padding: "40px" } : {}}>
        {!profileData.loading && (
          <Fragment>
            <Col xl={12} className={"mb-5"}>
              <div class="creator-profile-bio">
                <img
                  src={profileData?.data?.profile_picture_url || profileData?.data?.extra_data?.profile_picture_url}
                  className="img-rounded" style={!!isMobile ? { width: "70px" } : {}}
                ></img>
                <div className="d-flex flex-column ml-4">
                  <span className="mr-2 d-flex align-items-center ">
                    <img src={"/insta-icon-big.svg"} alt="" style={!!isMobile ? { width: '15px' } : {}} />
                    <span className="ml-2" style={!!isMobile ? { fontSize: '15px' } : {}}>
                      {profileData?.data?.username || profileData?.data?.username}
                    </span>
                  </span>
                  <span style={!!isMobile ? { fontSize: '14px' } : { fontSize: '19px' }}>{profileData?.data?.name || profileData?.data?.handle}</span>
                  <span style={!!isMobile ? { fontSize: '12px' } : {}}>{profileData?.data?.email}</span>
                  <a href={profileData.data?.website} target="_blank">
                    <span style={!!isMobile ? { fontSize: '12px' } : {}}>{profileData?.data?.website}</span>
                  </a>
                  <span style={!!isMobile ? { width: '100%', fontSize: '12px' } : { width: "450px" }}>
                    {profileData.data?.biography || profileData.data?.extra_data?.biography}
                  </span>
                </div>
              </div>
            </Col>
            <Col xl={12} lg={12} className={!!isMobile ? "p-0" : ""}>
              <div className={"ml-2" + (!!isMobile ? " heading-strip-mobile" : " heading-strip")}>{'Social Stats'}</div>
            </Col>
            <Col xl={12} className={"mt-2 mb-4" + (!!isMobile ? " p-0" : "")}>
              <div className={"bg-white rounded social-stats pt-4 pb-4" + (!!isMobile ? " three-equals" : " d-flex align-items-center justify-content-around")}>
                <div className="d-flex">
                  <img src="/influencer-post.svg" className="mr-3"></img>
                  <div>
                    <h5 className={!!isMobile ? "m-font-11" : ""}>Tagged Posts</h5>
                    <h3 className={!!isMobile ? "m-font-12" : ""}>
                      {profileData?.tagged_posts.length || 0}
                    </h3>
                  </div>
                </div>
                <div className="d-flex">
                  <img src="/following.svg" className="mr-3"></img>
                  <div>
                    <h5 className={!!isMobile ? "m-font-11" : ""}>Following</h5>
                    <h3 className={!!isMobile ? "m-font-12" : ""}>
                      {!!profileData.data?.follows_count ? abbreviateNumber(profileData.data?.follows_count) : 0}
                    </h3>
                  </div>
                </div>
                <div className="d-flex">
                  <img src="/followers.svg" className="mr-3"></img>
                  <div>
                    <h5 className={!!isMobile ? "m-font-11" : ""}>Followers</h5>
                    <h3 className={!!isMobile ? "m-font-12" : ""}>{!!profileData.data?.followers_count ? abbreviateNumber(profileData.data?.followers_count) : 0}</h3>
                  </div>
                </div>
                <div className="d-flex">
                  <img src="/influencer-engagement.svg" className="mr-3"></img>
                  <div>
                    <h5 className={!!isMobile ? "m-font-11" : ""}>Engagement</h5>
                    <h3 className={!!isMobile ? "m-font-12" : ""}>{`${profileData.data?.engagement_rate?.toFixed(2)}%` || 0}</h3>
                  </div>
                </div>
                <div className="d-flex">
                  <img src="/avg-likes.svg" className="mr-3"></img>
                  <div>
                    <h5 className={!!isMobile ? "m-font-11" : ""}>Impression</h5>
                    <h3 className={!!isMobile ? "m-font-12" : ""}>
                      {_.sumBy(chartData.impression.series[0].data)}
                    </h3>
                  </div>
                </div>
                <div className="d-flex">
                  <img src="/avg-comments.svg" className="mr-3"></img>
                  <div>
                    <h5 className={!!isMobile ? "m-font-11" : ""}>Reach</h5>
                    <h3 className={!!isMobile ? "m-font-12" : ""}>
                      {_.sumBy(chartData.reach.series[0].data)}
                    </h3>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} sm={12} xs={12} className={!!isMobile ? "p-0" : ""}>
              <div className={"ml-2 " + (!!isMobile ? " heading-strip-mobile" : " heading-strip")}>{'Impressions'}</div>
              <ReactApexChart
                options={chartData["impression"].options}
                series={chartData["impression"].series}
                type="area"
                height={300}
              />
            </Col>
            <Col xl={6} lg={6} sm={12} xs={12} className={!!isMobile ? "p-0" : ""}>
              <div className={"ml-2 " + (!!isMobile ? " heading-strip-mobile" : " heading-strip")}>{'Reach'}</div>
              <ReactApexChart
                options={chartData["reach"].options}
                series={chartData["reach"].series}
                type="area"
                height={300}
              />
            </Col>
            {
              !_.isEmpty(profileData?.tagged_posts) &&
              <Col xl={12} lg={12} className={!!isMobile ? "p-0" : ""}>
                <div className={"ml-2" + (!!isMobile ? " heading-strip-mobile" : " heading-strip")}>{'Tagged Posts'}</div>
              </Col>
            }
            {
              !_.isEmpty(profileData.tagged_posts) && <Col
                xl={12}
                lg={12}
                className={"mt-2 f-grid-box mb-3 " + (!!isMobile ? " " : " four-equals")}
              >
                {profileData.tagged_posts.slice(0, 4).map((datum) => {
                  return (
                    <Fragment>
                      {datum.media_type === 'STORY_MENTION' ?
                        <InstaStory datum={datum} profile={profileData.data} showProfile={false} profileIcon={profileData?.data?.profile_picture_url || profileData?.data?.extra_data?.profile_picture_url} userName={profileData?.data?.username || profileData?.data?.username}></InstaStory>
                        : <InstaPostCard data={datum} showProfile={false} profileIcon={profileData?.data?.profile_picture_url || profileData?.data?.extra_data?.profile_picture_url} userName={profileData?.data?.username || profileData?.data?.username}></InstaPostCard>}
                    </Fragment>
                  )
                })}
                {false && profileData?.tagged_posts.slice(0, 4).map((datum) => {
                  return (
                    <div
                      key={datum.id}
                      className={"mt-5 mb-5"}
                    >
                      <div className={"insta-user-post-card"}>
                        <div>
                          {datum.media_type === "VIDEO" && !!datum.media_url ? (
                            <video
                              controls
                              style={{ width: "100%", height: "350px" }}
                            >
                              <source src={datum.media_url} type="video/mp4" />
                            </video>
                          ) : datum.media_type === "VIDEO" && !datum.media_url ? (
                            <UnsupportedMedia paramlink={datum.permalink} />
                          ) : datum.media_type === "IMAGE" &&
                            !!datum.media_url ? (
                            <img
                              src={datum.media_url}
                              style={{ width: "100%", height: "350px" }}
                            />
                          ) : datum.media_type === 'STORY_MENTION' ? <InstaStory datum={datum} /> : (
                            <UnsupportedMedia paramlink={datum.permalink} />
                          )}
                        </div>
                        <div>
                          <div
                            className={
                              "justify-content-start align-items-start d-flex p-2"
                            }
                          >
                            <span
                              className={
                                "d-flex mr-3 insta-like align-items-center"
                              }
                            >
                              <i
                                className="fa fa-heart"
                                aria-hidden="true"
                                style={{ color: "red" }}
                              ></i>
                              <h5 className={"ml-1 mb-0"}>
                                {abbreviateNumber(datum?.extra_data?.like_count)} likes
                              </h5>
                            </span>
                            <span
                              className={
                                "d-flex insta-comment align-items-center"
                              }
                            >
                              <i
                                className="fa fa-comment-o"
                                aria-hidden="true"
                              ></i>
                              <h5 className={"ml-1 mb-0"}>
                                {abbreviateNumber(datum?.extra_data?.comments_count)} comments
                              </h5>
                            </span>
                          </div>
                          <div className="p-2">
                            <span className={"font-weight-bold"}>
                              {profileData?.data?.extra_data?.username}
                            </span>
                            <span className={"ml-2 post-caption"} style={!!isMobile ? { height: 'auto' } : {}}>
                              {datum?.extra_data?.caption}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Col>
            }
            {
              !(profileData.data.tagged_posts) || _.isEmpty(profileData.data.tagged_posts) &&
              <Fragment>
                <div className="d-flex flex-column align-items-center w-100">
                  <img src="/content/no_post.svg" alt="No Post" />
                  <h3 className="text-center" style={{ maxWidth: "70%" }}>We don't have permissions to access the full profile of this creator. Please ask the creator to signup through one of our program</h3>
                </div>
              </Fragment>
            }
          </Fragment>
        )
        }
        {
          !!profileData.loading && (
            <Col xl={12}>
              <ThreeDotsLoader
                height={100}
                width={100}
                text={"Hold on! We are fetching profile details."}
              />
            </Col>
          )
        }
      </Row >
    </Fragment >
  );
};
export default CreatorProfile;
