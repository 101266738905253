import React, { Fragment } from "react";
import {
  Switch,
  Route,
  BrowserRouter,
  useParams,
  Link,
  useRouteMatch,
  Redirect,
  useHistory,
} from "react-router-dom";
import {
  Create,
  Overview,
} from "../components/GrowthTools/GrowthToolsComponents";
import { Row, Col } from "react-bootstrap";
const GrowthTools = (props) => {
  const { path, url } = useRouteMatch();
  return (
    <BrowserRouter>
      <Fragment>
        <Row>
          <Col xl={12} md="12">
            <div
              className={
                "d-flex flex-wrap align-items-center justify-content-between search-job bg-white rounded py-3 px-md-3 px-0 mb-4 align-items-center"
              }
            >
              <h3>Programs</h3>
              {
                <Link
                  className="btn btn-primary btn-rounded invisible"
                  to={`${path}/create`}

                >
                  Create
                </Link>
              }
            </div>
          </Col>
        </Row>
        <Switch>
          <Route path={`${path}/create`} component={Create}></Route>
          <Route path={`${path}/overview`} component={Overview}></Route>
          <Redirect from={`${path}`} to={`${path}/overview`}></Redirect>
        </Switch>
      </Fragment>
    </BrowserRouter>
  );
};
export default GrowthTools;
