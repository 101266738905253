import { Form, Formik, useFormik } from "formik";
import React, { Fragment, useState } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { availableOperators, discountTypes, ruleProps, TableHeaders } from "../../../js/Helpers";
import { TableHead, ThreeDotsLoader } from "../Commons/Common";
import SelectRewardType from "./SelectRewardType";
import * as yup from "yup";
import moment from "moment";


const NoRule = () => {
    return (
        <Fragment>
            <tr>
                <td colSpan={3} className={'text-center pt-4 pb-4'}>
                    <img src="/no-rule.svg"></img>
                    <h6 className="mt-3">No rules have been created yet!</h6>
                    <p>Create a new rule by clicking on Create Rule Button.</p>
                </td>
            </tr>

        </Fragment>
    )
}
export const RewardRuleTable = (props) => {
    return (
        <Fragment>
            <Table responsive className="mb-0">
                <TableHead headers={TableHeaders.REWARD_RULE.data} hasCheckbox={TableHeaders.REWARD_RULE.hasCheckbox}></TableHead>
                <tbody>
                    {
                        !props.rewardData.isLoading && !!props.rewardData.data.length && props.rewardData.data.map((rule, index) => {
                            return <Fragment>
                                <tr key={index}>
                                    <td>
                                        <h6 className="mb-0">{rule.name}</h6>
                                        <span style={{ fontSize: '12px' }}>{rule.description}</span>
                                    </td>
                                    <td>{moment(parseInt(rule.created_at)).format('LLLL')}</td>
                                    <td>{rule.status}</td>
                                    <td><i className="fa fa-trash" role="button" onClick={() => props.deleteRule(rule.uuid)}></i></td>
                                </tr>
                            </Fragment>
                        })
                    }
                    {
                        !props.rewardData.isLoading && !props.rewardData.data.length && (
                            <NoRule></NoRule>
                        )
                    }
                    {!!props.rewardData.isLoading && (
                        <Fragment>
                            <tr>
                                <td colSpan={3}>
                                    <ThreeDotsLoader
                                        text={"Hold on! We are fetching latest data"}
                                        height={100}
                                        width={100}
                                        vAlign={"start"}
                                    />
                                </td>
                            </tr>
                        </Fragment>
                    )}
                </tbody>
            </Table>
        </Fragment>
    )
}

export const RewardModal = (props) => {
    let [selectedType, setSelectedType] = useState('');
    let history = useHistory();
    let toggleModal = () => {
        props.callback()
    }
    let selectRewardType = () => {
        history.push(`${selectedType}`)
        props.callback()
    }
    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={props.showModal}
            size="lg"
            onExited={() => {
                return;
            }}
            onHide={() => {
                return;
            }}
        >
            <Modal.Header>
                <Modal.Title>Select Rule Type</Modal.Title>
                <Button
                    variant=""
                    className="close"
                    onClick={() => toggleModal()}
                >
                    <span>&times;</span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <SelectRewardType callback={(type) => setSelectedType(type)}></SelectRewardType>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger light" onClick={() => toggleModal()}>
                    Close
                </Button>
                <Button
                    variant=""
                    type="button"
                    className="btn btn-primary"
                    disabled={!selectedType}
                    onClick={() => selectRewardType()}
                >
                    Select
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    description: yup.string().required("Description is required")
})

