import React from "react";
import { Card, Button, Table, Form as BootstrapForm } from "react-bootstrap";
import { Fragment, useState } from "react";
import { getTextOrLinkTemplateJson } from "./AutoReplyHelper";
import { useFormik, FieldArray, FormikProvider } from "formik";
import { saveAutoReplyRule } from "../../../services/PostsService";
const TextOrLinkForm = ({
  saveRule,
  submitData,
  templateType,
  nextStepCallback,
  templateData,
  uuid
}) => {
  const formik = useFormik({
    initialValues: !!uuid ? templateData : getTextOrLinkTemplateJson,
    onSubmit: (values) => {
      nextStepCallback("templatePayload", values, templateType);
    },
  });
  return (
    <Fragment>
      <div className={"genric-temp-form"}>
      <FormikProvider value={formik}>
        <form
          className={"mb-2 bg-white rounded p-4"}
          onSubmit={formik.handleSubmit}
          controlId={"message.text"}
        >
          <label>Text Or Link</label>
          <input
            className={"auto-reply-input"}
            type="text"
            name="message.text"
            onChange={formik.handleChange}
            placeholder={"Add text with link here"}
            value={formik.values.message.text}
          ></input>
          <div>
            <button
                type={"submit"}
                className={"mt-2  btn btn-primary pull-right rounded"}
            >
              Submit
            </button>
          </div>
        </form>
      </FormikProvider>
        <div></div>
      </div>
    </Fragment>
  );
};

export default TextOrLinkForm;
