import React, { Fragment, useState, useEffect } from "react";
import { message } from "../../message/message";
import { connect, useDispatch } from "react-redux";
import {
  getFacebookPages,
  setFbPageCredentials,
  addOrgUser,
  getOrgList,
  checkFbAuth,
} from "../../services/PostsService";
import { setPageList, connectedPageList } from "../../store/actions";
import { fbAuthAction, OrgAction } from "../../store/actions/AuthActions";
import { ListGroup } from "react-bootstrap";
import _ from "lodash";
import swal from "sweetalert";
import { showAlert } from "../../js/Helpers";
import {
  FacebookLogOut,
  ThreeDotsLoader,
  InstaNotConnected,
} from "../components/Commons/Common";
import {
  updateFBPermissions,
  saveOrgDetailsInLocalStorage,
  saveConnectedPageDataInLocalStorage,
} from "../../services/AuthService";
import { useHistory } from "react-router-dom";
const FacebookPages = (props) => {
  console.log("facebook pages props", props);
  const { failedMessage, doesNotConnectedInstaMessage } = message;

  let orgDetails = props.auth.org;
  let [pageList, setPageList] = useState([]);
  let [connectedPageData, setConnectedPageData] = useState({});
  let [selectedOrg, setSelectedOrg] = useState("");
  let dispatch = useDispatch();
  let [isSelecting, setIsSelecting] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  let getPages = async () => {
    let response = await getFacebookPages();
    console.log(response);
    if (!!response) {
      //ispatch(setPageList(response.data.pageData));
      let filteredList = _.filter(response.data.pageData[0], function (o) {
        return !!o.instagram_business_account;
      });
      setPageList(filteredList);
      //dispatch(setPageList())
      //let connectedPageData = response?.data?.connectedPageData
      setConnectedPageData(response.data.connectedPageData);
      dispatch(connectedPageList(connectedPageData));
      setIsLoading(false);
      return connectedPageData;
    }
    return null;
  };

  let getSelectedOrg = async () => {
    try {
      let response = await getOrgList()
        .then()
        .catch((error) => {
          console.log(error);
        });

      const selectedOrgData = response?.data?.organizations.filter(
        (obj) => obj.org_token === selectedOrg
      );
      return selectedOrgData && selectedOrgData.length
        ? selectedOrgData[0]
        : {};
    } catch (err) {
      return {};
    }
  };

  let checkOrgCreatedOrNot = async () => {
    const data = await getSelectedOrg().catch(() => {
      swal({
        title: "Failed",
        text: failedMessage,
        icon: "error",
        // button: "Okay",
      });
    });
    //can we add await
    !!data &&
      checkFbAuth(data?.org_token)
        .then((res) => {
          saveOrgDetailsInLocalStorage(data);
          dispatch(OrgAction(data));
          setTimeout(() => {
            let connectedPageData = res.data.connectedPageData;
            if (res.data.loggedIn && !_.isEmpty(connectedPageData)) {
              saveConnectedPageDataInLocalStorage(connectedPageData);
              dispatch(connectedPageList(connectedPageData));
              dispatch(fbAuthAction(res.data.token));
              updateFBPermissions(dispatch);
              props.history.push("/");
            } else if (!!res.data.loggedIn && !!_.isEmpty(connectedPageData)) {
              props.history.push("./facebook-pages");
            } else {
              props.history.replace("./fb-login");
            }
          }, 500);
        })
        .catch((err) => {
          swal({
            title: "Failed",
            text: failedMessage,
            icon: "error",
            // button: "Okay",
          });
        });
  };

  useEffect(() => {
    setIsLoading(true);
    !!props.pageListReducer.list.length &&
      (() => {
        setPageList(props.pageListReducer.list);
        setIsLoading(false);
      })();
    !_.isEmpty(props.connectedPageReducer.data) &&
      (() => {
        setConnectedPageData(props.connectedPageReducer.data);
        console.log(connectedPageData, "selected page data");
      })();
    !props.pageListReducer.list.length &&
      (() => {
        getPages();
      })();
    return () => {
      setPageList([]);
    };
  }, []);
  let setFbPageData = (selectedPage) => {
    if (!!localStorage.getItem("fbPageData")) {
      localStorage.removeItem("fbPageData");
      localStorage.setItem("fbPageData", JSON.stringify(selectedPage));
      dispatch(connectedPageList(selectedPage));
    } else {
      localStorage.setItem("fbPageData", JSON.stringify(selectedPage));
      dispatch(connectedPageList(selectedPage));
    }
    setConnectedPageData(selectedPage);
    swal({
      title: "Success",
      text: "Page Selected",
      icon: "success",
      button: "Okay",
    });
    // window.location.href = "/"
  };
  let selectPage = (data) => {
    !_.isEqual(data.id, connectedPageData?.id) &&
      (() => {
        showAlert(
          "Are you Sure?",
          "Do you want to select?",
          true,
          "warning",
          true,
          () => {
            // setIsSelecting(true);
            setFbPageCredentials(data)
              .then((res) => {
                console.log(res);
                // setIsSelecting(false);
                let selectedPage = {
                  pageId: data.id,
                  iconUrl: data.iconUrl,
                  accessToken: data.access_token,
                };
                if (res.data.status === "failed") {
                  showAlert("", res.data.message, true, "warning", true, () => {
                    addOrgUser(data.id)
                      .then((res) => {
                        setIsSelecting(true);
                        setFbPageData(selectedPage);
                        updateFBPermissions(dispatch);
                        setSelectedOrg(res?.data?.org_token);
                      })
                      .catch();
                  });
                } else {
                  if (!!res.data.data.instagram_page_id) {
                    setIsSelecting(true);
                    setFbPageData(selectedPage);
                    updateFBPermissions(dispatch);
                    setSelectedOrg(res?.data?.data?.org_token);
                  } else {
                    swal({
                      title: "Failed",
                      text: doesNotConnectedInstaMessage,
                      icon: "error",
                      // button: "Okay",
                    });
                  }
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        );
      })();
  };
  return (
    <>
      <div className="p-5">
        {!!pageList.length && (
          <FacebookLogOut
            text={"Facebook Logout"}
            orgToken={orgDetails.org_token}
            props={props}
          />
        )}

        <div
          className={"basic-list-group"}
          style={{ width: "50%", margin: "0 auto" }}
        >
          {!!isLoading && (
            <ThreeDotsLoader height={100} width={100} text={"Fetching Pages"} />
          )}
          {!isLoading && (
            <>
              {" "}
              {pageList.length ? (
                <Fragment>
                  <ListGroup as={"ul"}>
                    {pageList.map((list, i) => {
                      return (
                        <Fragment>
                          <ListGroup.Item
                            key={i}
                            as={"li"}
                            className={
                              "org-list d-flex align-items-center justify-content-between"
                            }
                          >
                            <div className={"d-flex align-items-center"}>
                              <img
                                className={"img-fluid mr-3"}
                                src={
                                  list?.instagram_business_account
                                    ?.profile_picture_url
                                }
                              />
                              <div>
                                <h6 className={"m-0"}>{list.name}</h6>
                                <p className="pb-0 mb-0">
                                  {list?.instagram_business_account?.username}
                                </p>
                              </div>
                            </div>
                            {!!connectedPageData &&
                            (!!connectedPageData?.page_id ||
                              connectedPageData?.pageId) &&
                            (connectedPageData?.page_id === list.id ||
                              connectedPageData?.pageId === list.id) ? (
                              <button className="btn btn-secondary">
                                Selected
                              </button>
                            ) : (
                              <button
                                type={"button"}
                                className={"btn"}
                                style={{
                                  backgroundColor: "#6bb12a",
                                  color: "white",
                                }}
                                disabled={isSelecting}
                                onClick={() => selectPage(list)}
                              >
                                Select
                              </button>
                            )}
                          </ListGroup.Item>
                        </Fragment>
                      );
                    })}
                  </ListGroup>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn mt-2"
                      style={{ backgroundColor: "#6bb12a", color: "white" }}
                      disabled={_.isEmpty(connectedPageData)}
                      onClick={checkOrgCreatedOrNot}
                    >
                      Continue
                    </button>
                  </div>
                </Fragment>
              ) : (
                <InstaNotConnected />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps)(FacebookPages);
