import {
    ORG_LIST_ACTION
} from "../actions/AuthActions"
const initialState = {
    orgList: []
};
export default function OrgList(state = initialState, action) {
    if (action.type === ORG_LIST_ACTION) {
        return {
            ...state,
            orgList: action.payload
        }
    }
    return state
}