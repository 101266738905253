import React, { Fragment } from 'react'
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch, useRouteMatch, Redirect, BrowserRouter } from 'react-router-dom';
import SelectRewardType from '../components/RewardProgram/SelectRewardType';
import CreateRewardRule from '../components/RewardProgram/CreateRewardRule';
import RewardList from '../components/RewardProgram/RewardList';
import { Row } from 'react-bootstrap';
const Rewards = (props) => {
    const { path } = useRouteMatch();
    document.title = "Rewards - NitroCollab";
    return (
        <BrowserRouter>
            <Row>
                <Switch>
                    <Route path={`${path}/list`} component={RewardList}></Route>
                    <Route path={`${path}/:type`} component={CreateRewardRule}></Route>
                    <Redirect from={`${path}`} to={`${path}/list`}></Redirect>
                </Switch>

            </Row >
        </BrowserRouter>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps)(Rewards);
