
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import store  from '../store/store';
import { getUserDetails, tokenTimeDifference, refreshToken } from './TokenService';
import { useDispatch } from 'react-redux';
import { logout } from '../store/actions/AuthActions';
import _, { isEmpty } from "lodash";
import moment from 'moment';
const { dispatch, history } = store;
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SERVICE_URL,
});
function addHeadersToConfig(config) {
    config.params = config.params || {};
    config.headers['Access-Control-Allow-Origin'] = "*"
    config.headers['Content-Type'] = 'application/json';
    config.headers["x-wigzo-host"] = process.env.REACT_APP_WIGZO_HOST
    config.headers['Accept'] = 'application/json';
    return config;
}
axiosInstance.interceptors.request.use(
    (config) => {
        const userDetails = getUserDetails();
        config = addHeadersToConfig(config)
        if (!_.isEmpty(userDetails)) {
            const tokenExpiredDiff = tokenTimeDifference(userDetails);
            if (tokenExpiredDiff > 10) {
                dispatch(logout()) //need to login again as there is no activity since 1 min after token got expired
                return;
            } else if (tokenExpiredDiff < 10 && tokenExpiredDiff > 0) { //token needs to refresh
                (async () => {
                    const newToken = await refreshToken(userDetails.token);
                    config.headers["token"] = newToken?.data?.token;
                    return config;
                })()
            } else {
                config.headers["token"] = userDetails.token;
                return config
            }
        }
        return config

    },
    (error) => {
        return Promise.reject(error);
    }
)


export default axiosInstance;
