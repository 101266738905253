import {APPEND_NEW_POSTS, APPEND_NEW_RULE, DELETE_RULE, SET_AUTO_REPLIES, SET_BRANDS_POST, TOGGLE_RULE,UPDATE_RULE} from "./PostTypes";

export const addTodo = (data) => {
	return{
		type: "ADD_TODO",
		payload: {
			id: new Date().getTime(),
			data:data,
		},
	}
}
export const deleteTodo = (id) => {
	return{
		type: "DELETE_TODO",
		id
	}
}
export const removeTodo = () => {
	return{
		type: "REMOVE_TODO"
	}
}
export const setPageList = (data) => {
	return {
		type: "PAGE_LIST",
		payload: data
	}
}
export const connectedPageList = (data) => {
	return {
		type: "CONNECTED_PAGE",
		payload: data
	}
}
/* export const editTodo = (id) =>{
    return{
        type: "EDIT_TODO",
        id
    }
} */

export const setAutoReplies = (data) => {
	return {
		type: SET_AUTO_REPLIES, payload: data
	}
}
export const setBrandPosts = (data) => {
	return {
		type: SET_BRANDS_POST, payload: data
	}
}
export const appendNewPosts = (data) => {
	return {
		type: APPEND_NEW_POSTS, payload: data
	}
}
export const appendNewReply = (data) => {
	return {
		type: APPEND_NEW_RULE, payload: data
	}
}
export const deleteAutoReply = (data) => {
	return {
		type: DELETE_RULE, payload: data
	}
}
export const toggleAutoReply = (data) => {
	return {
		type: TOGGLE_RULE, payload: data
	}
}
export const updateAutoReply = (data) => {
	return {
		type: UPDATE_RULE,payload: data
	}
}