import React from "react";
import { Fragment, useState } from "react";
import {
  GenericTemplateValidationSchema,
  getGenericTemplateJson,
} from "./AutoReplyHelper";
import { useParams } from "react-router-dom";
import { useFormik, FieldArray, FormikProvider } from "formik";
import { TemplatePreview } from "./AutoReplyHelper";

export const GenericTemplateForm = ({
  history,
  saveRule,
  submitData,
  templateType,
  saveGenericFormData,
  genericFormDetails,
}) => {
  console.log(genericFormDetails);
  const { uuid } = useParams();
  let formik = useFormik({
    initialValues: !!uuid
      ? genericFormDetails.template
      : getGenericTemplateJson,
    onSubmit: (values) => {
      //submitData.template = values;
      // submitData.template.message.attachment.payload.template_type =
      //  templateType;
      //saveRule(submitData);
      saveGenericFormData(values);
      history.push("/autoreply/list");
    },
    GenericTemplateValidationSchema,
  });
  return (
    <Fragment>
      <div className={"genric-temp-form"}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <div className="w-100">
              <FieldArray
                name="message.attachment.payload.elements"
                render={(arrayHelpers) => (
                  <>
                    {!!formik.values?.message?.attachment?.payload?.elements &&
                      formik.values?.message?.attachment?.payload?.elements.map(
                        (element, index) => (
                          <div
                            key={index}
                            className={"bg-white rounded p-4 mb-2"}
                          >
                            <h6 className={"d-flex justify-content-between"}>
                              Card #{index + 1}
                              {formik.values.message.attachment.payload.elements
                                .length > 1 && (
                                <i
                                  className={"fa fa-times"}
                                  role={"button"}
                                  onClick={() => arrayHelpers.remove(index)}
                                ></i>
                              )}
                            </h6>
                            <div>
                              <div
                                style={{ gap: "20px" }}
                                className={"w-100 d-flex"}
                              >
                                <div className={"genric-form-row"}>
                                  <label className={"input-button-span"}>
                                    Heading
                                  </label>
                                  <input
                                    className={"auto-reply-input"}
                                    name={`message.attachment.payload.elements[${index}].title`}
                                    value={element.title}
                                    onChange={formik.handleChange}
                                  />
                                </div>

                                <div className={"genric-form-row"}>
                                  <label className={"input-button-span"}>
                                    Sub-Heading
                                  </label>
                                  <input
                                    className={"auto-reply-input"}
                                    name={`message.attachment.payload.elements[${index}].subtitle`}
                                    value={element.subtitle}
                                    onChange={formik.handleChange}
                                  />
                                </div>
                              </div>
                              <div className={"pt-4 w-100"}>
                                <div className={"genric-form-row"}>
                                  <label className={"input-button-span"}>
                                    Image Url
                                  </label>
                                  <input
                                    className={"auto-reply-input"}
                                    name={`message.attachment.payload.elements[${index}].image_url`}
                                    value={element.image_url}
                                    onChange={formik.handleChange}
                                  />
                                </div>
                                <div className={"pt-4"}>
                                  <div className={"genric-form-row"}>
                                    <label className={"input-button-span"}>
                                      Redirect Image Url
                                    </label>
                                    <input
                                      className={"auto-reply-input"}
                                      name={`message.attachment.payload.elements[${index}].default_action.url`}
                                      value={element.default_action.url}
                                      onChange={formik.handleChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <FieldArray
                              name={`message.attachment.payload.elements[${index}].buttons`}
                              render={(buttonArrayHelpers) => (
                                <div>
                                  {!!element.buttons.length &&
                                    element.buttons.map(
                                      (button, childIndex) => (
                                        <Fragment>
                                          <p key={childIndex}>
                                            <i
                                              className={"fa fa-times"}
                                              style={{
                                                display: "flex",
                                                float: "right",
                                              }}
                                              role={"button"}
                                              onClick={() =>
                                                buttonArrayHelpers.remove(
                                                  childIndex
                                                )
                                              }
                                            ></i>
                                          </p>
                                          <div>
                                            {!!element.buttons.length && (
                                              <h6>Button #{childIndex + 1}</h6>
                                            )}
                                          </div>

                                          <div
                                            className={"w-100  d-flex"}
                                            style={{ gap: "20px" }}
                                          >
                                            <div
                                              key={childIndex}
                                              className={"genric-form-row"}
                                            >
                                              <label
                                                className={"input-button-span"}
                                              >
                                                Button Title
                                              </label>
                                              <input
                                                className="auto-reply-input"
                                                name={`message.attachment.payload.elements[${index}].buttons[${childIndex}].title`}
                                                value={button.title}
                                                onChange={formik.handleChange}
                                              />
                                            </div>
                                            <div
                                              key={childIndex}
                                              className={"genric-form-row"}
                                            >
                                              <label
                                                className={"input-button-span"}
                                              >
                                                Button Link
                                              </label>
                                              <input
                                                className="auto-reply-input"
                                                placeholder={"Add redirect URL"}
                                                name={`message.attachment.payload.elements[${index}].buttons[${childIndex}].url`}
                                                value={button.url}
                                                onChange={formik.handleChange}
                                              />
                                            </div>
                                          </div>
                                        </Fragment>
                                      )
                                    )}
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <span
                                      role={"button"}
                                      className={"text-primary pt-2"}
                                      onClick={() =>
                                        buttonArrayHelpers.push({
                                          title: `Enter Button Text`,
                                          type: "web_url",
                                          url: "",
                                        })
                                      }
                                    >
                                      + Add Button
                                    </span>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        )
                      )}
                    <div>
                      <span
                        role={"button"}
                        className={"text-info"}
                        onClick={() => {
                          arrayHelpers.push({
                            title: "Welcome Back!",
                            image_url:
                              "https://storage.googleapis.com/media.wigzo.com/uploads/pF3NmMonSuaRiJ2M6eDv4A/userimagegallery/55801d1e-37e3-47f2-b3b4-2b55c18fdc1e.png",
                            subtitle: "We have the right hat for everyone.",
                            default_action: {
                              type: "web_url",
                              url: "https://www.originalcoastclothing.com",
                            },
                            buttons: [],
                          });
                        }}
                      >
                        + Add card
                      </span>
                    </div>
                    <button
                      role={"button"}
                      className={"btn btn-primary  genric-temp-btn"}
                    >
                      Submit
                    </button>
                  </>
                )}
              />
            </div>
          </form>
        </FormikProvider>
        <div style={{ border:"1px solid #BCBCBC",position:"relative", top:"-117px",borderRadius:"2px",height:"fitContent" }}>
          <h4 className={'pt-3 text-center mb-0'}>Preview</h4>
          {templateType !== "text" && (
            <div className={'p-3'}>
              <TemplatePreview
                values={formik.values}
                templateType={templateType}
                history={history}
              />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
