import React, {
  Fragment,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { promiseTrackerHoc } from "react-promise-tracker";
import * as Loader from "react-loader-spinner";
import _ from "lodash";
import { facebookLogout } from "../../../services/PostsService";
import { ToastContainer, toast } from "react-toastify";
import { saveFbTokenInLocalStorage } from "../../../services/AuthService";
import { fbAuthAction } from "../../../store/actions/AuthActions";
import { connectedPageList } from "../../../store/actions/index";
import {
  Button,
  Card,
  Modal,
  Table,
  Badge,
  Col,
  Carousel,
  ListGroup,
} from "react-bootstrap";
import {
  TableHeaders,
  initialQuery,
  translationPool,
} from "../../../js/Helpers";
import avatar1 from "../../../images/avatar/1.jpg";
import TimeAgo from "react-timeago";
import Moment from "react-moment";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import swal from "sweetalert";
import InfluencersFilters from "../GrowthTools/InfluencerFilter";
import { abbreviateNumber } from "js-abbreviation-number";
import { format } from "date-fns";
import PerfectScrollbar from "react-perfect-scrollbar";
import PostModal from "./PostModal";
export function TabWidget({ title, iconUrl, numbers, extraInfo }) {
  return (
    <Fragment>
      <div className="tab-widget">
        <img src={iconUrl} />
        <div>
          <h3>{title}</h3>
          <h2>{numbers}</h2>
          <span>{extraInfo}</span>
        </div>
      </div>
    </Fragment>
  );
}
export function UnsupportedMedia({ paramlink, isModal }) {
  return (
    <Fragment>
      <div
        className={`unsupported-media bg-black d-flex align-items-center justify-content-center ${
          !!isModal ? "w-100" : ""
        }`}
        style={{ minHeight: "276px" }}
      >
        <a
          href={!!isModal ? paramlink : "javascript:void(0)"}
          {...(!!isModal && { target: "_blank" })}
        >
          <img src="/not_supported_media.svg" />
          <p>Media not supported</p>
        </a>
        {/* <h3>Unsupported Media</h3> */}
        {/* <a href={paramlink} target={"_blank"}>
          view on instagram
        </a> */}
      </div>
    </Fragment>
  );
}
export function CarouselPost({ data }) {
  return (
    <Fragment>
      <Carousel>
        {data.map((datum) => {
          return (
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={datum.media_url}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.outerHTML = `<video controls class="carousel-video d-block w-100" style={{ height: "276px" }}>
              <source src=${datum.media_url} type="video/mp4" />
            </video>`;
                }}
              ></img>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </Fragment>
  );
}
// export function InstaPostCard({ data }) {
//   return (
//     <Fragment>
//       <div className={"insta-post-card"}>
//         <div
//           onClick={() => {
//             window.open(data?.extra_data?.permalink, "_blank");
//           }}
//           role={"button"}
//         >
//           <img src={data?.profile?.extra_data?.profile_picture_url || null} onError={({ currentTarget }) => {
//             currentTarget.onerror = null;
//             currentTarget.src = "/content/fallback-src.svg"
//           }} />
//           <span onClick={() => window.open(`${process.env.REACT_APP_URL}/creators/${data.username}/profile`, '_blank')}>{data.username}</span>
//         </div>
//         <div
//           className={"profile d-flex justify-content-between"}
//           style={
//             !data?.profile?.followers_count
//               ? {
//                 visibility: "hidden",
//               }
//               : {}
//           }
//         >
//           <span className={"d-flex align-items-center"}>
//             <h5 className={"mb-0 mr-1"}>
//               {abbreviateNumber(data?.profile?.followers_count) || "N/A"}
//             </h5>
//             <p className={"mb-0"}>Followers</p>
//           </span>
//           <span className={"d-flex align-items-center"}>
//             <h5 className={"mb-0 mr-1"}>
//               {abbreviateNumber(data?.profile?.extra_data?.follows_count) || "N/A"}
//             </h5>
//             <p className={"mb-0"}>Following</p>
//           </span>
//         </div>
//         <div className={data.media_type === "story_mention" && !data?.extra_data?.mime_type.includes("video") ? "image-story-container" : ""}>
//           {data.media_type === "VIDEO" && !!data.media_url ? (
//             <video controls style={{ height: "350px" }}>
//               <source src={data.media_url} type="video/mp4" />
//             </video>
//           ) : data.media_type === "VIDEO" && !data.media_url ? (
//             <UnsupportedMedia paramlink={data?.extra_data?.permalink} />
//           ) : data.media_type === "IMAGE" && !!data.media_url ? (
//             <img src={data.media_url} />
//           ) : data.media_type === "CAROUSEL_ALBUM" && data?.extra_data?.children ? (
//             <CarouselPost data={data.extra_data.children.data}></CarouselPost>
//           ) : data.media_type === "CAROUSEL_ALBUM" && !data?.extra_data?.children ? (
//             <img src={data.media_url}></img>
//           ) : data.media_type === "story_mention" && data?.extra_data?.mime_type && data?.extra_data?.mime_type.includes("video") ? (
//             <video style={{ height: '350px' }}>
//               <source src={data.media_url} type={data?.extra_data?.mime_type}></source>
//             </video>
//           ) : data.media_type === "story_mention" && data?.extra_data?.mime_type && !data?.extra_data?.mime_type.includes("video") ? (
//             <img src={data.media_url}></img>
//           ) : (
//             <UnsupportedMedia paramlink={data?.extra_data?.permalink} />
//           )}
//           {/*  {
//                        data.media_type === "VIDEO" && (() => {
//                            return !!data.media_url ?  <video controls>
//                                <source src={data.media_url} type="video/mp4" />
//                            </video> :  <img src="/video-fallback.jpg" />
//                        })
//                    }
//                    {
//                        data.media_type === "IMAGE" && <img src={data.media_url} />
//                    }*/}
//         </div>
//         <div className={"justify-content-start"}>
//           <span className={"d-flex mr-3 insta-like align-items-center"}>
//             <h5 className={"mr-1 mb-0"}>{data?.extra_data?.like_count || 0}</h5>
//             <i
//               className="fa fa-heart"
//               aria-hidden="true"
//               style={{ color: "red" }}
//             ></i>
//           </span>
//           <span className={"d-flex insta-comment align-items-center"}>
//             <h5 className={"mr-1 mb-0"}>{data?.extra_data?.comments_count || 0}</h5>
//             <i className="fa fa-comment-o" aria-hidden="true"></i>
//           </span>
//           {
//             !!data?.extra_data?.permalink && (
//               <div style={{ width: '100%' }} className={'text-right'}>
//                 <a href={data.extra_data.permalink} target="_blank" className="ml-3 text-decoration-underline" style={{ fontSize: '11px' }}>View on Instagram</a>
//               </div>
//             )
//           }

//         </div>
//         <div>
//           {!!data.insights &&
//             data.insights.length &&
//             data.insights.map((insight) => {
//               return (
//                 (insight.name === "impressions" ||
//                   insight.name === "engagement") && (
//                   <div key={insight.id}>
//                     <h5>{insight.title}</h5>
//                     <p>{insight.values[0].value}</p>
//                   </div>
//                 )
//               );
//             })}
//           {/* <div>
//                        <h5>Impressions</h5>
//                        <p>1.21K</p>
//                    </div>
//                    <div>
//                        <h5>Engagement</h5>
//                        <p>0.98%</p>
//                    </div>*/}
//         </div>
//       </div>
//     </Fragment>
//   );
// }
export function InstaPostCard({ data, profileIcon, userName, showProfile }) {
  let [show, setShow] = useState(false);
  let hideModal = () => {
    setShow(false);
  };
  let showModal = () => {
    setShow(true);
  };
  let userProfile = {
    userName: data?.profile?.username || data?.handle || null,
    profileIcon:
      data?.profile?.extra_data?.profile_picture_url || profileIcon || null,
    handle: data.username || userName,
    followers: data?.profile?.extra_data?.followers_count
      ? abbreviateNumber(data?.profile?.extra_data?.followers_count)
      : "0",
    following: data?.profile?.extra_data?.follows_count
      ? abbreviateNumber(data?.profile?.extra_data?.follows_count)
      : "0",
  };
  return (
    <Fragment>
      <PostModal
        showModal={show}
        data={data}
        userProfile={userProfile}
        hideModal={() => hideModal()}
        mediaType={data.media_type}
        mediaUrl={data.media_url}
      />
      <div className="insta-card">
        <div
          className="user pl-3 pr-3 pt-2 pb-2 position-relative
        .
        "
        >
          <a href="javascript:void(0)" className="mr-3">
            <img
              src={
                data?.profile?.extra_data?.profile_picture_url ||
                profileIcon ||
                null
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/content/fallback-src.svg";
              }}
            ></img>
          </a>
          <span
            role="button"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_URL}/creators/${data.username}/profile`,
                "_blank"
              )
            }
          >
            {data.username || userName}
          </span>
          <i
            className="fa fa-eye pull-right position-absolute"
            style={{ right: "10px" }}
            role="button"
            onClick={() => showModal()}
          ></i>
        </div>
        {!!showProfile && (
          <div className="stats pl-3 pr-3 pt-2 pb-2">
            <span className={"d-flex align-items-center"}>
              <h5 className={"mb-0 mr-1"}>
                {data?.profile?.extra_data?.followers_count
                  ? abbreviateNumber(data?.profile?.extra_data?.followers_count)
                  : "0"}
              </h5>
              <p className={"mb-0"}>Followers</p>
            </span>
            <span className={"d-flex align-items-center"}>
              <h5 className={"mb-0 mr-1"}>
                {data?.profile?.extra_data?.follows_count
                  ? abbreviateNumber(data?.profile?.extra_data?.follows_count)
                  : "0"}
              </h5>
              <p className={"mb-0"}>Following</p>
            </span>
          </div>
        )}

        <div
          className="media d-block"
          role="button"
          onClick={() => showModal()}
        >
          <div className="position-relative">
            {data.media_type === "VIDEO" && !!data.media_url ? (
              <div className="video position-relative">
                <video
                  style={{ height: "276px", width: "100%", background: "grey" }}
                >
                  <source src={data.media_url} type="video/mp4" />
                </video>
              </div>
            ) : data.media_type === "VIDEO" && !data.media_url ? (
              <div className="unsupported position-relative">
                <UnsupportedMedia paramlink={data?.extra_data?.permalink} />
              </div>
            ) : data.media_type === "IMAGE" && !!data.media_url ? (
              <div className="image positon-relative">
                <img src={data.media_url} />
              </div>
            ) : data.media_type === "CAROUSEL_ALBUM" &&
              data?.extra_data?.children ? (
              <div className="album position-relative">
                <CarouselPost
                  data={data.extra_data.children.data}
                ></CarouselPost>
              </div>
            ) : data.media_type === "CAROUSEL_ALBUM" &&
              !data?.extra_data?.children ? (
              <div className="image positon-relative">
                <img src={data.media_url}></img>
              </div>
            ) : data.media_type === "story_mention" &&
              data?.extra_data?.mime_type &&
              data?.extra_data?.mime_type.includes("video") ? (
              <div className="video position-relative">
                <video style={{ height: "306px", width: "auto" }}>
                  <source
                    src={data.media_url}
                    type={data?.extra_data?.mime_type}
                  ></source>
                </video>
              </div>
            ) : data.media_type === "story_mention" &&
              data?.extra_data?.mime_type &&
              !data?.extra_data?.mime_type.includes("video") ? (
              <div className="image position-relative">
                <img src={data.media_url}></img>
              </div>
            ) : (
              <div className="unsupported position-relative">
                <UnsupportedMedia paramlink={data?.extra_data?.permalink} />
              </div>
            )}
          </div>
        </div>
        <div className="likes-comment d-flex p-2">
          <span className={"d-flex mr-3 insta-like align-items-center"}>
            <h5 className={"mr-1 mb-0"}>{data?.extra_data?.like_count || 0}</h5>
            <i
              className="fa fa-heart"
              aria-hidden="true"
              style={{ color: "red" }}
            ></i>
          </span>
          <span className={"d-flex insta-comment align-items-center"}>
            <h5 className={"mr-1 mb-0"}>
              {data?.extra_data?.comments_count || 0}
            </h5>
            <i className="fa fa-comment-o" aria-hidden="true"></i>
          </span>
          {!!data?.extra_data?.permalink && (
            <div style={{ width: "100%" }} className={"text-right"}>
              <a
                href={data.extra_data.permalink}
                target="_blank"
                className="ml-3 text-decoration-underline"
                style={{ fontSize: "11px" }}
              >
                View on Instagram{" "}
                <i class="fa fa-external-link" aria-hidden="true"></i>
              </a>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}
export function InstaStory({
  datum,
  padding,
  height,
  profile,
  showProfile,
  profileIcon,
  userName,
}) {
  return (
    <Fragment>
      <div
        key={datum.id}
        className={"insta-story"}
        style={{ padding: !!padding ? padding : "10px" }}
      >
        <div>
          <img
            className="story-user"
            src={
              datum?.profile?.extra_data?.profile_picture_url ||
              profile?.profile_picture_url ||
              profile?.extra_data?.profile_picture_url ||
              profileIcon
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "/content/fallback-src.svg";
            }}
          ></img>
          <span
            className="story-username"
            role="button"
            onClick={() => {
              window.open(
                `https://instagram.com/${datum?.profile?.handle}`,
                "_blank"
              );
            }}
          >
            {datum?.profile?.handle ||
              profile?.handle ||
              profile?.handle ||
              userName}
          </span>
          <span></span>
        </div>
        {!!showProfile && (
          <div className="d-flex justify-content-between">
            <span className="d-flex align-items-center">
              <h5 className="mb-0 mr-1">
                {abbreviateNumber(
                  datum?.profile?.extra_data?.followers_count ||
                    profile?.extra_data?.followers_count
                )}
              </h5>
              <p className="mb-0">Followers</p>
            </span>
            <span className="d-flex align-items-center">
              <h5 className="mb-0 mr-1">
                {abbreviateNumber(
                  datum?.profile?.extra_data?.follows_count ||
                    profile?.extra_data?.follows_count
                )}
              </h5>
              <p className="mb-0">Following</p>
            </span>
          </div>
        )}

        {!!datum.extra_data &&
        datum?.extra_data?.mime_type &&
        datum.extra_data.mime_type.includes("video") ? (
          <video
            controls
            style={{ height: !!height ? height : "450px", width: "100%" }}
          >
            <source src={datum.media_url} type={datum.extra_data.mime_type} />
          </video>
        ) : (
          <div
            style={{
              height: !!height ? height : "450px",
              position: "relative",
              paddingTop: "75.25%",
            }}
            className="image-story-container"
          >
            <img src={datum.media_url}></img>
          </div>
        )}
      </div>
    </Fragment>
  );
}
export function TitleHeader({ title }) {
  return (
    <Fragment>
      <div className="d-flex justify-content-start align-items-center mb-3 mt-5">
        <h3 className="m-0">{title}</h3>
        <i
          className="fa fa-bar-chart ml-3"
          style={{ fontSize: "20px", color: "#4C02FF" }}
        ></i>
      </div>
    </Fragment>
  );
}

export function UserGreeting({ userData }) {
  return (
    <Fragment>
      <div className={"user-greetings"}>
        <h3>Welcome Back, {userData.fullName}&#128075;</h3>
        <p>Your current status and analytics are here</p>
      </div>
    </Fragment>
  );
}
export function KpiWidget({ data, isActive }) {
  const iconSet = {
    impressions: "/impressions.svg",
    reach: "/engagement.svg",
    user_posts: "/posts.svg",
    tagged_posts: "/posts.svg",
  };
  return (
    <Fragment>
      <div className={`kpi-widget d-flex p-3 ${isActive && "active"}`}>
        <i className="fa fa-info" title={data.description}></i>
        <img src={iconSet[data.name]} className={"mr-3"} />
        <div className={"d-flex flex-column"}>
          <h3 className={"mt-1"}>{data.title}</h3>
          <p>{abbreviateNumber(data.value)}</p>
        </div>
      </div>
    </Fragment>
  );
}

export function FacebookLogOut({ text, orgToken, props }) {
  let [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  console.log("props in logout component", props);
  let logout = () => {
    setIsLoading(true);
    facebookLogout(orgToken)
      .then((res) => {
        if (res.data.status === "success") {
          setIsLoading(false);
          dispatch(fbAuthAction(false));
          dispatch(connectedPageList({}));
          localStorage.removeItem("isFbLoggedIn");
          localStorage.removeItem("fbPageData");
          props.history.push("/auth/fb-login");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Something went wrong. Please try again", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  };
  return (
    <Fragment>
      <button
        className={"btn btn-outline-success pull-right"}
        disabled={!!isLoading}
        onClick={() => logout()}
      >
        {text}
        {!!isLoading && <i className={"fa fa-spin fa-spinner ml-2"}></i>}
        {!isLoading && <i className={"fa fa-power-off ml-2"}></i>}
      </button>
      <ToastContainer />
    </Fragment>
  );
}
export function CustomCheckbox({ id }) {
  return (
    <div className="custom-control custom-checkbox checkbox-success check-xs mr-3">
      <input
        type="checkbox"
        className="custom-control-input"
        id={id}
        required=""
      />
      <label className="custom-control-label" htmlFor={id}></label>
    </div>
  );
}
const Spinner = (props) => {
  //const { promiseInProgerss } = usePromiseTracker();
  const types = {
    threeDots: "ThreeDots",
  };
  return (
    props.promiseInProgress && (
      <div className={"loader-view"}>
        <Loader.ThreeDots color={"#4C02FF"} height={100} width={100} />
        <h4>{"Fetching Post"}</h4>
      </div>
    )
  );
};
export const ThreeDotsLoader = ({ height, width, text, vAlign, hAlign }) => {
  return (
    <div
      className={"loader-view"}
      style={{ justifyContent: vAlign, alignItems: hAlign }}
    >
      <Loader.ThreeDots color={"#4C02FF"} height={height} width={width} />
      <h4>{text}</h4>
    </div>
  );
};
export const TableCard = ({
  isLoading,
  headers,
  cellsData,
  showTitle,
  hasCheckbox,
}) => {
  return (
    <div className={"border-grey bg-white rounded m-0"}>
      {!!showTitle && (
        <Card.Header>
          <Card.Title>Creators</Card.Title>
        </Card.Header>
      )}
      <Card.Body className={"pl-3 pr-3 pb-2 pt-0"}>
        <Table responsive bordered className={"mb-0"}>
          <thead>
            <tr>
              {headers.map((head, index) => {
                let returnMarkup =
                  !!hasCheckbox && index === 0 ? (
                    <th
                      className={!!hasCheckbox && "d-flex align-items-center"}
                    >
                      <CustomCheckbox id={"all_creators"} />
                      <span className={"text-black"}>{head}</span>
                    </th>
                  ) : (
                    <th>
                      <span className={"text-black"}>{head}</span>
                    </th>
                  );
                return returnMarkup;
              })}
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              cellsData.map((creator, index) => {
                return (
                  <Fragment>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          {!!hasCheckbox && (
                            <CustomCheckbox id={creator.creatorName + index} />
                          )}
                          <img
                            src={creator.extra_data.profile_picture_url}
                            className="rounded-lg mr-2"
                            width="24"
                            alt=""
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = "/content/fallback-src.svg";
                            }}
                          />{" "}
                          <div className="d-flex flex-column position-relative">
                            <Link to={`/creators/${creator.handle}/profile`}>
                              <span
                                className="w-space-no"
                                style={{ textDecoration: "underline" }}
                              >
                                {!!creator.username
                                  ? creator.username
                                  : creator.handle}
                                {!!creator.has_permission && (
                                  <img
                                    src="/checked-green.svg"
                                    title="Signed Up"
                                    style={{
                                      position: "relative",
                                      left: "5px",
                                      bottom: "1px",
                                    }}
                                  ></img>
                                )}
                              </span>
                            </Link>
                            <a
                              href={`${process.env.REACT_APP_URL}/p/${creator.handle}`}
                              target="_blank"
                            >
                              <span
                                style={{
                                  textDecoration: "underline",
                                  fontSize: "11px",
                                }}
                              >
                                {`${process.env.REACT_APP_URL}/p/${creator.handle}`}
                              </span>
                            </a>
                          </div>
                        </div>
                      </td>
                      <td className="tagged-post-count-column">
                        <div className="d-flex justify-content-between">
                          <span
                            title={creator?.media_count?.tagged_media_count}
                          >
                            {parseInt(
                              creator?.media_count?.tagged_media_count
                            ) < 10
                              ? creator?.media_count?.tagged_media_count
                              : "10+"}{" "}
                            posts
                          </span>
                          <span
                            title={creator?.media_count?.story_mention_count}
                          >
                            {parseInt(
                              creator?.media_count?.story_mention_count
                            ) < 10
                              ? creator?.media_count?.story_mention_count
                              : "10+"}{" "}
                            stories
                          </span>
                          <span>
                            <Link to={"/content"}>
                              <i
                                className="fa fa-eye"
                                style={{ color: "#4C02FF" }}
                              ></i>
                            </Link>
                          </span>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="d-flex align-items-center justify-content-center">
                          <a
                            href={`https://ig.me/m/${creator.handle}`}
                            target="_blank"
                          >
                            <i className="fa fa-paper-plane"></i>
                          </a>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          {abbreviateNumber(creator.followers_count, 2)}
                        </div>
                      </td>
                      <td>{creator.engagement_rate.toFixed(2)}%</td>
                      <td>
                        {creator.last_posted_at
                          ? moment(
                              new Date(parseInt(creator.last_posted_at))
                            ).format("LLL")
                          : moment().subtract(1, "days").format("LLL")}
                      </td>
                      {/* <td>
                        <Badge
                          className={"badge-secondary light badge-rounded"}
                        >
                          {translationPool[creator.subscription_source]}
                        </Badge>
                      </td> */}
                    </tr>
                  </Fragment>
                );
              })}
          </tbody>
        </Table>
        {!!isLoading && (
          <ThreeDotsLoader
            text={"Hold on! We are fetching latest data"}
            height={100}
            width={100}
            vAlign={"start"}
          />
        )}
      </Card.Body>
    </div>
  );
};
export const LoadingIndicator = promiseTrackerHoc(Spinner);
export const FModal = forwardRef((props, ref) => {
  console.log("modal props", props);
  let [showModal, setShowModal] = useState(false);
  const criteria = useSelector((store) => store.filters.criteria);
  useImperativeHandle(ref, () => ({
    showModal() {
      setShowModal(true);
    },
  }));
  let applyFilter = () => {
    hideModal();
    props.callback(criteria);
  };
  let hideModal = () => {
    props.hideModalCallBack();
  };
  return (
    <Modal
      className="fade bd-example-modal-lg"
      show={props.showModal}
      size="lg"
      onExited={() => {
        return;
      }}
      onHide={() => {
        return;
      }}
    >
      <Modal.Header>
        <Modal.Title>{props.modalTitle}</Modal.Title>
        <Button variant="" className="close" onClick={() => hideModal()}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <InfluencersFilters
          criteria={criteria}
          type={props.type}
        ></InfluencersFilters>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger light" onClick={() => hideModal()}>
          Close
        </Button>
        <Button
          variant=""
          type="button"
          className="btn btn-primary"
          onClick={applyFilter}
        >
          {props.confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});
export const TableHead = (props) => {
  let headers = props.headers;
  let hasCheckbox = props.hasCheckbox;
  return (
    <Fragment>
      <thead>
        <tr>
          {headers.map((head, index) => {
            let returnMarkup =
              !!hasCheckbox && index === 0 ? (
                <th className={!!hasCheckbox && "d-flex align-items-center"}>
                  <CustomCheckbox id={"all_creators"} />
                  <span className={"text-black"}>{head}</span>
                </th>
              ) : (
                <th>
                  <span className={"text-black"}>{head}</span>
                </th>
              );
            return returnMarkup;
          })}
        </tr>
      </thead>
    </Fragment>
  );
};
export const GrowthToolsTable = ({ growthToolData, isLoading, deleteTool }) => {
  console.log("growth tool data", growthToolData);
  return (
    <Fragment>
      <Table responsive className={"mb-0"}>
        <TableHead
          headers={TableHeaders.GROWTH_TOOLS.data}
          hasCheckbox={TableHeaders.GROWTH_TOOLS.hasCheckbox}
        />
        <tbody>
          {!isLoading &&
            growthToolData.map((tool, index) => {
              return (
                <Fragment>
                  <tr key={tool.uuid}>
                    <td>
                      <a href={tool?.metadata?.render_url} target="_blank">
                        <span>{tool?.metadata?.render_url}</span>
                      </a>
                    </td>
                    <td>
                      {tool?.subscription_source} {tool.uuid}
                    </td>
                    <td>
                      <Moment fromNow>
                        {moment(new Date(parseInt(tool?.created_at))).format(
                          "LLLL"
                        )}
                      </Moment>
                    </td>
                    <td>
                      <CopyToClipboard
                        text={tool?.metadata?.render_url}
                        onCopy={() => {
                          swal({
                            title: "Copied",
                            text: "Copied to clipboard",
                            icon: "success",
                            button: "Okay",
                          });
                        }}
                      >
                        <i
                          className="fa fa-copy ml-3"
                          style={{
                            fontSize: "23px",
                            color: "#000000bd",
                            cursor: "pointer",
                          }}
                        ></i>
                      </CopyToClipboard>
                      <i
                        className="fa fa-trash ml-3"
                        style={{
                          fontSize: "23px",
                          color: "#000000bd",
                          cursor: "pointer",
                        }}
                        onClick={() => deleteTool(tool.uuid)}
                      ></i>
                    </td>
                  </tr>
                </Fragment>
              );
            })}
          {!!isLoading && (
            <ThreeDotsLoader
              text={"Hold on! We are fetching latest data"}
              height={100}
              width={100}
              vAlign={"start"}
            />
          )}
        </tbody>
      </Table>
    </Fragment>
  );
};
export const HeadingStrip = ({ title, hasIcon }) => {
  return (
    <Fragment>
      <Col xl={12} lg={12}>
        <div className="heading-strip ml-2">{title}</div>
      </Col>
    </Fragment>
  );
};
export const BigLinkButton = ({ link, label, target }) => {
  return (
    <Fragment>
      <a
        role="button"
        href={link}
        className={"btn btn-outline-info text-left mt-3"}
        style={{ width: "70%" }}
        target={target}
      >
        {label}
        <i className="fa fa-arrow-right ml-5 pull-right"></i>
      </a>
    </Fragment>
  );
};
export const InstaNotConnected = () => {
  return (
    <Fragment>
      <Col
        xl={12}
        md={12}
        lg={12}
        className={
          "d-flex justify-content-center align-items-center flex-column"
        }
      >
        <img src="/nopagefoundfb.svg"></img>
        <h3 className={"text-center"} style={{ color: "#777777" }}>
          Looks like you dont have any facebook page connected with the
          Instagram.
        </h3>
        <BigLinkButton
          link="https://knowledge.fluen.co/2022/08/23/how-to-connect-with-fluenco/"
          label="How to link your account to Fluenco"
          target="_blank"
        ></BigLinkButton>
      </Col>
    </Fragment>
  );
};
export const PermissionMissing = () => {
  return (
    <Fragment>
      <div className="d-flex flex-column align-items-center">
        <img src="/content/no_post.svg" alt="No Post" />
        <h3>We are all set, waiting for someone to mention you in a post</h3>
      </div>
    </Fragment>
  );
};
export const LoaderAreas = {
  DASHBOARD: "dashboard-area",
};

export const GoBack = () => {
  const history = useHistory();
  return (
    <Fragment>
      <div className="mb-3" style={{ marginLeft: "-10px", marginTop: "-10px" }}>
        <i
          className="fa fa-long-arrow-left mr-2"
          title="Go Back"
          style={{ color: "#4C02FF" }}
          role="button"
          onClick={() => {
            history.goBack();
          }}
        ></i>
        <span
          style={{ color: "#4C02FF" }}
          onClick={() => {
            history.goBack();
          }}
          role="button"
        >
          Back
        </span>
      </div>
    </Fragment>
  );
};
export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};
export const HeaderStrip = (props) => {
  let parentCallback = () => {
    !!props.callback && props.callback();
  };
  return (
    <Fragment>
      <Col xl={12} md={12}>
        <div
          className={
            "d-flex flex-wrap search-job bg-white rounded py-3 px-md-3 px-0 mb-4 align-items-center"
          }
        >
          <div className="col-xl-3 col-xxl-4 col-md-4 border-right">
            <h3>{props.title}</h3>
          </div>
          <div
            className={
              "col-xl-9 col-xxl-8 col-md-8 d-flex flex-wrap justify-content-end"
            }
          >
            <button
              type={!props.type ? props.type : ""}
              form={"rewardRuleForm"}
              className=" btn btn-rounded "
              style={{ backgroundColor: "#6bb12a", color: "white" }}
              onClick={() => parentCallback()}
            >
              {props.buttonTitle}
            </button>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};
