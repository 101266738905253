import swal from "sweetalert";
import { message } from "../../message/message";
import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
  saveFbTokenInLocalStorage,
  loginWithWigzo,
  syncLoginData,
  loginResponseObject,
  confirmedSignup,
} from "../../services/AuthService";
import { getOrgList } from "../../services/PostsService";
import { extractDataFromJwt } from "../../services/TokenService";
import { DESTROY_SESSION } from "./PostTypes";

export var SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export var SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export var LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export var LOGIN_FAILED_ACTION = "[login action] failed login";
export var LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export var LOGOUT_ACTION = "[Logout action] logout action";
export var ORG_ACTION = "[Org Action] Organization Selected";
export var Fb_Auth_Action = "[FB Action] Fb Logged In";
export var ORG_LIST_ACTION = "[ORG List Action]";

export function signupAction(data, history) {
  const {signupFailedMessage} = message;
  return (dispatch) => {
    signUp(data)
      .then((response) => {
        //ispatch(confirmedSignupAction(response.data));
        dispatch(loadingToggleAction(false));
        if (response.data.status === "success") {
          confirmedSignup(history);
        } else {
          dispatch(
            signupFailedAction(signupFailedMessage)
          );
        }
      })
      .catch((error) => {
        dispatch(loadingToggleAction(false));
        let errorMessage = "";
        if (error.response.data.code === 1002) {
          errorMessage = formatError("USER_EXIST");
          dispatch(signupFailedAction(errorMessage));
        } else {
          dispatch(
            signupFailedAction(signupFailedMessage)
          );
        }

        //dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logout(history) {
  localStorage.removeItem("userDetails");
  localStorage.removeItem("orgDetails");
  localStorage.removeItem("isFbLoggedIn");
  localStorage.removeItem("fbPageData");
  localStorage.removeItem("tokenDetails");
  //dispatch({ type: DESTROY_SESSION });
  history.push("/auth/login");
  //window.location.href = '/auth/login'
  //window.location.reload();
  return {
    type: LOGOUT_ACTION,
  };
}
let isOrgs = async () => {
  let response = await getOrgList().catch((error) => {
      console.log(error);
    });
  return  response && response?.data?.status === "success" ? true : false;
};

function successLogin(response, dispatch, props, loginFromWigzo) {
  console.log(props, "success login");
  if (response.data.status === "failure" || response.data.status === "fail") {
    const errorMessaage = formatError(response.data.details);
    dispatch(loginFailedAction(errorMessaage));
  } else {
    //let extractedRes = loginResponseObject(response.data, loginFromWigzo, dispatch)
    let extractedRes = extractDataFromJwt(
      response.data,
      loginFromWigzo,
      dispatch
    );
    saveTokenInLocalStorage(extractedRes);
    dispatch(loginConfirmedAction(extractedRes));
    if (!!response?.data?.data?.is_fb_logged_in) {
      saveFbTokenInLocalStorage(true);
      dispatch(fbAuthAction(true));
      isOrgs().then((isOrg) => {
        if (!!isOrg) {
          props.history.push("./organizations");
        } else {
          props.history.push("./facebook-pages");
        }
      });
    } else {
      props.history.push("./fb-login");
    }
    // !!loginFromWigzo && syncLoginData(extractedRes.orgData.token, extractedRes.token).then(res => {
    //     dispatch(loginConfirmedAction(extractedRes));
    //     !!res && props.history.push('/auth/organizations')
    // }).catch(err => {
    //     dispatch(loginFailedAction("Something went wrong. Please try again."))
    // })
  }
}

export function loginAction(email, password, props) {
  const {loginFailMessage}= message;
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        successLogin(response, dispatch, props, false);
      })
      .catch((error) => {
        //const errorMessage = formatError(error.response.data);
        console.log(error?.response?.data, "catch block");
        dispatch(loginFailedAction(loginFailMessage));
        /** 
         * 
         since login with wigzo removed so we need to remove this api call

        */
        // if (error.response.data.code === 1004) {
        //   loginWithWigzo(email, password)
        //     .then((res) => {
        //       successLogin(res, dispatch, props, true);
        //     })
        //     .catch((err) => {
        //       dispatch(loginFailedAction("Something went wrong"));
        //     });
        // } else {
        //   dispatch(loginFailedAction("Something went wrong"));
        // }
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}

export function OrgAction(data) {
  return {
    type: ORG_ACTION,
    payload: data,
  };
}
export function fbAuthAction(data) {
  return {
    type: Fb_Auth_Action,
    payload: data,
  };
}
export function orgListAction(data) {
  return {
    type: ORG_LIST_ACTION,
    payload: data,
  };
}
