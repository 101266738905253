import axiosInstance from "../services/AxiosInstance";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import _ from "lodash";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { getSetLatestTaggedPosts } from "../store/actions/PostActions";
import moment from "moment";
import { subDays } from "date-fns";
export function getPosts() {
  return axiosInstance.get(`posts.json`);
}
export function createPost(postData) {
  return axiosInstance.post(`posts.json`, postData);
}

export function updatePost(post, postId) {
  return axiosInstance.put(`posts/${postId}.json`, post);
}

export function deletePost(postId) {
  return axiosInstance.delete(`posts/${postId}.json`);
}

export function formatPosts(postsData) {
  let posts = [];
  for (let key in postsData) {
    posts.push({ ...postsData[key], id: key });
  }

  return posts;
}
export function getInstaPost(token, num, mediaType, fromDashboard, criteria) {
  let data = {
    insta_source: "tags",
    media_type: !!mediaType ? mediaType : "IMAGE",
  };
  if (mediaType === "STORY_MENTION") {
    data["media_type"] = "STORY_MENTION";
  }
  let perPage = !!fromDashboard ? (window.innerWidth > 1300 ? 4 : 6) : 12;
  let url = `/fluenco/insta/post/${token}?page=${!!num ? num - 1 : 0
    }&perpage=${perPage}`;
  url =
    !!criteria && !!criteria.length
      ? `${url}&query=${btoa(JSON.stringify(criteria))}`
      : url;
  return trackPromise(axiosInstance.post(url, data), undefined);
}
export function getPageInsights(token, apiToken, dateRange) {
  let config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // let data = {
  //   start_date: "2022-07-19T06:51:50+0000",
  //   end_date: "2022-07-22T06:51:50+0000",
  // };
  let data = {
    start_date: moment(dateRange.startDate)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ssZZ"),
    end_date: moment(dateRange.endDate)
      .add(1, "days")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ssZZ"),
  };
  return axiosInstance.post(
    `/fluenco/insta/dashboard/graphdata/${token}`,
    data
  );
}
export function getOrgList(state) {
  const url = `/fluenco/organizations`;
  return axiosInstance.get(url);
}
export function checkFbAuth(token) {
  const url = `/fluenco/fbmessenger/credentials?org=${token}`;
  return axiosInstance.get(url);
}
export function facebookLogin(token) {
  const url = `/fluenco/fb/adcampaign/messaging/wigzo/${token}?restful=true`;
  return axiosInstance.get(url);
}
export function saveToken(token) {
  const url = `/fluenco/fb/token/save`;
  const data = {
    token: token,
  };
  return axiosInstance.post(url, data);
}
export function getFacebookPages() {
  const url = `/fluenco/fb/pages/wigzo`;
  return axiosInstance.post(url);
}
export function setFbPageCredentials(pageData) {
  const url = `/fluenco/fbmessenger/credentials`;
  const data = {
    page_id: pageData?.id || pageData?.pageId,
  };
  return axiosInstance.post(url, data);
}
export function facebookLogout(orgToken) {
  const url = `/fluenco/fb/logout/${orgToken}`;
  return axiosInstance.get(url);
}
export function getCreators(orgToken, pageNum, perPag, criteria) {
  let url = `/fluenco/insta/creators/${orgToken}?page=${pageNum - 1
    }&perpage=${perPag}`;
  if (!_.isEmpty(criteria)) {
    let criteriaString = JSON.stringify(criteria);
    url = `${url}&query=${btoa(criteriaString)}`;
  }
  return axiosInstance.get(url);
}
export function getGrowthToolsCampaign(orgToken) {
  const url = `/fluenco/growth-tools/${orgToken}`;
  return axiosInstance.get(url);
}

export function createGrwothTool(orgToken, file) {
  const url = `/fluenco/growth-tools/${orgToken}`;
  let formData = new FormData();
  formData.append("html", file);
  formData.append("subscription_source", "GROWTH_TOOL_LANDING_PAGE");
  let config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axiosInstance.post(url, formData, config);
}
export function uploadCoupons(orgToken, file) {
  const url = `/fluenco/incentive-rules/coupons/${orgToken}`;
  let formData = new FormData();
  formData.append("coupons", file);
  let config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return axiosInstance.post(url, formData, config);
}
export function deleteGrowthTool(orgToken, uuid) {
  const url = `/fluenco/growth-tools/${orgToken}/${uuid}`;
  return axiosInstance.delete(url);
}
export function deleteRewardRule(orgToken, uuid) {
  const url = `/fluenco/incentive-rules/${orgToken}/${uuid}`;
  return axiosInstance.delete(url);
}
export function getInfluencerProfile(handle, period, token) {
  const url = `/fluenco/insta/creators/insights/${handle}/${period}/${token}`;
  let data = {
    start_date: moment(subDays(new Date(), 7))
      .utc()
      .format("YYYY-MM-DDTHH:mm:ssZZ"),
    end_date: moment(new Date())
      .add(1, "days")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ssZZ"),
  };
  return axiosInstance.post(url, data);
}
export function getOrgsByUser(handle) {
  const url = `/fluenco/insta/creators/brands/${handle}`;
  return axiosInstance.get(url);
}
export function getFbPermissions() {
  const url = "/fluenco/permissions";
  return axiosInstance.get(url);
}
function getPromiseObject(defaultPost, token) {
  let promiseObj = {};
  Object.keys(defaultPost).forEach((key) => {
    promiseObj[key] = getInstaPost(token, 1, key);
  });
  return promiseObj;
}
// export function getLatestTaggedPosts(token, defaultPosts) {
//   let fetchedPost = { ...defaultPosts };
//   let latestPromisePool = getPromiseObject(defaultPosts, token)
//   console.log(latestPromisePool);

//   return Promise.all(Object.values(latestPromisePool)).then(values => {
//     console.log('from there', values)
//     let extractedValues =
//     return values;
//   })
// }
export function getRewardRules(token, pageNum) {
  const url = `/fluenco/incentive-rules/${token}`;
  return axiosInstance.get(url);
}
export function saveOrUpdateRule(data, token) {
  const dataToPost = { ...data };
  //dataToPost['start_time'] = Date.now(dataToPost.start_time);
  dataToPost["start_time"] = moment(dataToPost.start_time).valueOf();
  //dataToPost['end_time'] = Date.now(dataToPost.end_time);
  dataToPost["end_time"] = moment(dataToPost.end_time).valueOf();
  const url = `/fluenco/incentive-rules/${token}`;
  return axiosInstance.post(url, dataToPost);
}
export function getDummyPosts(token, nextUrl) {
  //const url = `https://dummyjson.com/posts`;
  //const url = `/fluenco/comment-reply/media/${token}/10`;
  const url = `/fluenco/comment-reply/media/${token}`;
  return axiosInstance.post(url, { next: !!nextUrl ? nextUrl : "" });
}
export function saveAutoReplyRule(data, token, uuid, ruleType) {
  const url = !!uuid
    ? `/fluenco/${ruleType === 'auto_reply' ? 'comment-reply' : 'default-reply'}/${token}/${uuid}`
    : `/fluenco/${ruleType === 'auto_reply' ? 'comment-reply' : 'default-reply'}/${token}`;
  return !!uuid ? axiosInstance.put(url, data) : axiosInstance.post(url, data);
}
export function getAutoReplies(orgToken, ruleType, page, perPage) {
  const url = `/fluenco/${ruleType === 'auto_reply' ? 'comment-reply' : 'default-reply'}/rules/${orgToken}?page=${page > 0 ? page - 1 : 0}&perpage=${perPage}`;
  return axiosInstance.get(url);
}
export function deleteAutoReplyRule(token, uuid, ruleType) {
  const url = `/fluenco/${ruleType === 'auto_reply' ? 'comment-reply' : 'default-reply'}/${token}/${uuid}`;
  return axiosInstance.delete(url);
}


export function validatePost(mediaId) {
  const url = `/fluenco/comment-reply/validate-media/${mediaId}`;
  return axiosInstance.get(url);
}
export function fetchRule(ruleId, orgToken, ruleType) {
  const url = `/fluenco/${ruleType === 'auto_reply' ? 'comment-reply' : 'default-reply'}/rules/${orgToken}/${ruleId}`;
  return axiosInstance.get(url);
}
export function toggleCommentAutorepy(status, orgToken, uuid, ruleType) {
  const url = `/fluenco/${ruleType === 'auto_reply' ? 'comment-reply' : 'default-reply'}/${status === "ACTIVE" ? "disable" : "enable"
    }/${orgToken}/${uuid} `;
  return axiosInstance.get(url);
}
//This api will add this user to an existing organization

export function addOrgUser(fbPageId) {
  const url = `/fluenco/add/user`;
  const data = {
    page_id: fbPageId,
  };
  return axiosInstance.post(url, data);
}

//This api is use to validate Fb page token
export function getValidateFBPageToken(orgToken) {
  const url = `/fluenco/refresh-page-token/${orgToken}`;
  return axiosInstance.get(url);
}

// Story reply apis

export function saveStoryReply(token, uuid, data) {
  const url = !!uuid ? `/fluenco/story-reply/${token}/${uuid}` : `/fluenco/story-reply/${token}`;
  return !!uuid ? axiosInstance.put(url, data) : axiosInstance.post(url, data);
}

export function getStoryReplies(orgToken) {
  const url = `/fluenco/story-reply/rules/${orgToken}`;
  return axiosInstance.get(url);
}

export function toggleStoryAutorepy(status, orgToken, uuid) {
  const url = `/fluenco/story-reply/${status === "ACTIVE" ? "disable" : "enable"
    }/${orgToken}/${uuid} `;
  return axiosInstance.get(url);
}