import {
  CONFIRMED_CREATE_POST_ACTION,
  CONFIRMED_DELETE_POST_ACTION,
  CONFIRMED_EDIT_POST_ACTION,
  CONFIRMED_GET_POSTS,
  CREATE_POST_ACTION,
  GET_FILTERS,
  SET_FILTER_ACTION,
  SET_PAGE_INSIGHTS,
  GET_PAGE_INSIGHTS,
  FB_PERMISSIONS,
  LATEST_POST,
  TAGGED_IMAGE,
  TAGGED_STORIES,
  TAGGED_VIDEOS
} from "../actions/PostTypes";
import { initialInsights } from "../../js/Helpers";
const initialState = {
  posts: [],
};
const filterInitialState = {
  criteria: [],
};
const pageInsightsInitialState = {
  data: initialInsights,
};
const defaultLatestPosts = {
  video: { data: [], hasError: false, isLoading: false },
  image: { data: [], hasError: false, isLoading: false },
  stories: { data: [], hasError: false, isLoading: false }
}
const fbPermissions = [];
const defaultTaggedImages = {
  data: [], isLoading: false, hasError: false
}
export function PostsReducer(state = initialState, actions) {
  if (actions.type === CREATE_POST_ACTION) {
    const post = {
      id: Math.random(),
      title: "Post Title 2asdasd",
      description: "Sample Description 2asdasdas",
    };

    const posts = [...state.posts];
    posts.push(post);
    return {
      ...state,
      posts,
    };
  }

  if (actions.type === CONFIRMED_DELETE_POST_ACTION) {
    const posts = [...state.posts];
    const postIndex = posts.findIndex((post) => post.id === actions.payload);

    posts.splice(postIndex, 1);

    return {
      ...state,
      posts,
    };
  }

  if (actions.type === CONFIRMED_EDIT_POST_ACTION) {
    const posts = [...state.posts];
    const postIndex = posts.findIndex((post) => post.id === actions.payload.id);

    posts[postIndex] = actions.payload;
    return {
      ...state,
      posts,
    };
  }

  if (actions.type === CONFIRMED_CREATE_POST_ACTION) {
    const posts = [...state.posts];
    posts.push(actions.payload);

    return {
      ...state,
      posts,
    };
  }

  if (actions.type === CONFIRMED_GET_POSTS) {
    return {
      ...state,
      posts: actions.payload,
    };
  }
  return state;
}
export function FilterReducer(state = filterInitialState, action) {
  if (action.type === SET_FILTER_ACTION) {
    const criteria = [...state.criteria];
    criteria.push(action.payload);
    return {
      ...state,
      criteria: action.payload,
    };
  }
  return state;
}
export function PageInsightsReducer(state = pageInsightsInitialState, action) {
  if (action.type === GET_PAGE_INSIGHTS) {
    return {
      ...state,
      data: action.payload,
    };
  }
  return state;
}
export function FbPermissionReducer(state = fbPermissions, action) {
  if (action.type === FB_PERMISSIONS) {
    return {
      ...state,
      data: action.payload
    }
  }
  return state;
}
export function LatestPostReducer(state = defaultLatestPosts, action) {
  if (action.type === LATEST_POST) {
    return {
      ...state,
      data: action.payload
    }
  }
  return state
}
export function LatestTaggedImagesReducer(state = defaultTaggedImages, action) {
  if (action.type === TAGGED_IMAGE) {
    return {
      ...state,
      data: action.payload
    }
  }
  if (action.type === TAGGED_VIDEOS) {

  }
  if (action.type === TAGGED_STORIES) {

  }
  return state
}