import React, { Fragment, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {message} from "../../message/message"
import {
  getFacebookPages,
  saveToken,
  getOrgList,
} from "../../services/PostsService";
import FacebookLogin from "react-facebook-login";
import {
  saveFbTokenInLocalStorage,
  saveConnectedPageDataInLocalStorage,
} from "../../services/AuthService";
import { fbAuthAction } from "../../store/actions/AuthActions";
import { setPageList, connectedPageList } from "../../store/actions";
import _ from "lodash";
import swal from "sweetalert";

const LoginWithFacebook = (props) => {
  const {failedMessage}=message;
  let orgDetails = props.auth.org;
  const dispatch = useDispatch();
  let [fbData, setFbData] = useState({ data: {} });
  let [isLoading, setIsLoading] = useState(false);
  const fbAppId = process.env.REACT_APP_FB_APP_ID;
  const fbScopes = process.env.REACT_APP_FB_APP_SCOPE;
  let token = "";
  let isOrgs = async () => {
    let response = await getOrgList().catch((error) => {
        swal({
          title: "Failed",
          text: failedMessage,
          icon: "error",
        });
      });

    return  response && response?.data.status === "success" ? true : false;
  };
  let isPageConnected = async () => {
    let response = await getFacebookPages().catch(()=>{
      swal({
        title: "Failed",
        text: failedMessage,
        icon: "error",
      });
    });
    let isConnectPageOrNot = "";
    if (!!response) {
      // dispatch(setPageList(response.data.pageData));
      let connectedPageData = response?.data?.pageData;
      if (!!connectedPageData) {
        dispatch(connectedPageList(connectedPageData));
        isConnectPageOrNot = connectedPageData && connectedPageData.length;
        saveConnectedPageDataInLocalStorage(connectedPageData);
      }
    }
    return !!isConnectPageOrNot;
  };
  let login = (response) => {
    console.log(response);
    !!response.accessToken &&
      (async () => {
        setFbData({ data: response });
        token = response.accessToken;
        saveFbTokenInLocalStorage(!!response.accessToken);
        let tokenResponse = await saveToken(token).catch(()=>{
          swal({
            title: "Failed",
            text: failedMessage,
            icon: "error",
          });
        });
        !!tokenResponse && continueToNextStep();
      })();
  };
  let continueToNextStep = () => {
    setIsLoading(true);
    isOrgs().then((isOrg) => {
      if (!!isOrg) {
          isPageConnected().then((res) => {
          setIsLoading(false);
          dispatch(fbAuthAction(fbData.data.accessToken));
          if (!!res) {
            props.history.push("./organizations");
          }
          setTimeout(() => {
            if (!res) props.history.push("./facebook-pages");
          }, 500);
        });
      }
      setTimeout(() => {
        if (!isOrg) props.history.push("./facebook-pages");
      }, 500);
    });
  };
  return (
    <Fragment>
      <div className="login-form-bx">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-7 box-skew d-flex flex-column align-items-center justify-content-center">
              <h3 className="mb-1 font-w600">
                Welcome to <span style={{ color: "#4C02FF" }}>NitroCollab</span>
              </h3>
              <p className="">Select organization to continue.</p>
              {_.isEmpty(fbData.data) && (
                <div>
                  {/*<button type={'button'} className={'btn btn-primary'} onClick={login} >Login With Facebook</button>*/}
                  <FacebookLogin
                    appId={fbAppId}
                    autoLoad={false}
                    fields={"name,email,picture"}
                    scope={fbScopes}
                    callback={login}
                  />
                </div>
              )}
              {!_.isEmpty(fbData.data) && (
                <div>
                  <button className={"btn btn-primary"} type={"button"}>
                    Logged In as {fbData.data.name}
                  </button>
                </div>
              )}
              {!_.isEmpty(fbData.data) && (
                <div>
                  <button
                    className={"btn btn-primary mt-5"}
                    type={"button"}
                    onClick={continueToNextStep}
                  >
                    {!!isLoading ? (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    ) : (
                      <>Continue</>
                    )}
                  </button>
                </div>
              )}
            </div>
            <div className="col-lg-6 col-md-5 d-flex box-skew1 justify-content-center align-items-center">
              <img src="/auth-bg.svg" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    ...state,
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(LoginWithFacebook);
