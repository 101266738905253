import React, { Fragment, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { HeaderStrip } from '../Commons/Common';
import { Card, Col } from 'react-bootstrap';
import { RewardModal, RewardRuleTable } from './RewardProgramCommons';
import { getRewardRules, deleteRewardRule } from './../../../services/PostsService';
import { showAlert } from '../../../js/Helpers';
import swal from 'sweetalert';

const RewardList = (props) => {
    let [rewardRule, setRewardRule] = useState({ isLoading: false, data: [], count: 0 });
    let [showModal, setShowModal] = useState(false);
    const org = props.auth.org;
    let getRuleList = () => {
        getRewardRules(org.org_token).then(res => {
            !!res.data && setRewardRule({ isLoading: false, data: res.data.items, count: res.data.count })
        })
            .catch(err => {
                setRewardRule({ isLoading: false, data: [], count: 0 })
            })
    }
    useEffect(() => {
        getRuleList()
    }, [])
    let toggleModal = () => {
        setShowModal(!showModal)
    }
    let deleteTool = (uuid) => {
        showAlert(
            "Are you sure?",
            "Do you want to delete?",
            true,
            "warning",
            true,
            () => {
                deleteRewardRule(org.org_token, uuid).then((res) => {
                    swal({
                        title: "Deleted",
                        icon: "success",
                        button: "Okay",
                    }).then((isConfirm) => {
                        getRuleList();
                    });
                });
            }
        );
    };
    return (
        <Fragment>
            <HeaderStrip title={'Overview'} buttonTitle={'Create'} callback={() => toggleModal()}></HeaderStrip>
            <RewardModal showModal={showModal} callback={() => toggleModal()}></RewardModal>
            <Col xl={12} md={12}>
                <Card>
                    <Card.Body className={"pl-3 pr-3 pb-2 pt-0"}>
                        <RewardRuleTable rewardData={rewardRule} deleteRule={(uuid) => {
                            deleteTool(uuid)
                        }} />
                    </Card.Body>
                </Card>
            </Col>
        </Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        ...state
    }
}
export default connect(mapStateToProps)(RewardList);