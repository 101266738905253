import React from "react";
import { Fragment, useState } from "react";
import { useFormik, FieldArray, FormikProvider } from "formik";
import {
  defaultSchema,
  templateTypes,
  autoReplyDefaultData,
  dynamicValidationSchema,
  autoReplyDynamicData,
  ProductForm,
  TemplatePreview,
  BascicFormSchema,
  GenericTemplateValidationSchema,
  getGenericTemplateJson,
} from "./AutoReplyHelper";

import TextOrLinkForm from "./TextFormTemplate";
import { saveAutoReplyRule } from "../../../services/PostsService";
import { GenericTemplateForm } from "./GenricTemplateForm";
import { showAlert } from "../../../js/Helpers";
export default function MessageForm({
  selectedPost,
  orgDetails,
  history,
  nextStepCallback,
  genericFormData,
  genericFormDetails,
  uuid,
  selectedTemplateType,
  textFormData

}) {
  let [templateType, setTemplateType] = useState(selectedTemplateType);
  function saveTemplatePayload(payload) {
    payload["template_type"] = templateType;
    nextStepCallback("templatePayload", payload, templateType);
  }
  return (
    <>
      <div className={"genric-temp-form"}>
        <div className={"form-group bg-white rounded p-3"}>
          <label>Select Message Type</label>
          <select
            disabled={!!uuid}
            className={`auto-reply-input ${!!uuid && 'disabled'}`}
            name="template.type"
            value={templateType}
            onChange={(e) => setTemplateType(e.target.value)}
            title={!!uuid ? `You can't change template type while editing` : 'Change template type.'}
          >
            {templateTypes.map((type) => {
              return (
                <option value={type.value} key={type.value}>
                  {type.label}
                </option>
              );
            })}
          </select>
        </div>
        <div></div>
      </div>
      {templateType === "generic" && (
        <GenericTemplateForm
          templateType={templateType}
          saveGenericFormData={saveTemplatePayload}
          history={history}
          genericFormDetails={genericFormDetails}
        />
      )}

      {templateType === "text" && (
        <TextOrLinkForm
          nextStepCallback={nextStepCallback}
          templateType={templateType}
          history={history}
          templateData={textFormData}
          uuid={uuid}
        />
      )}
    </>
  );
}
