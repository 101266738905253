import React, {
  useState,
  Fragment,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";
import { updateFilters } from "../../../store/actions/PostActions";
import {
  filterQuery,
  initialQuery,
  filterPropsPool,
  availableOperators,
  availableSource,
  defaultQueryForProperty
} from "../../../js/Helpers";
import _ from "lodash";
const InfluencersFilters = forwardRef((props, ref) => {
  console.log('filters', props, 'props pool', filterPropsPool)
  let criteria = props.criteria.length ? props.criteria : [initialQuery[props.type]];
  const [query, setQuery] = useState(criteria);
  const dispatch = useDispatch();
  let handlePropertyChange = (index, value) => {
    let queryInput = [...query];
    let currentQuery = { ...queryInput[index] };
    currentQuery = defaultQueryForProperty[value];
    queryInput[index] = currentQuery;
    setQuery(queryInput);
    dispatch(updateFilters(queryInput));
  }
  let handleChange = (index, value, prop, type) => {
    let queryInput = [...query];
    let currentQuery = { ...queryInput[index] };
    currentQuery[prop] = type === "number" && !!value ? parseInt(value) : value;
    queryInput[index] = currentQuery;
    setQuery(queryInput);
    dispatch(updateFilters(queryInput));
  };
  let removeFilter = (index) => {
    const queryRows = [...query];
    queryRows.splice(index, 1);
    setQuery(queryRows);
  };
  return (
    <Fragment>
      {!!query &&
        query.map((q, index) => {

          const { property, operator, value1 } = q;
          return (
            <Row className={"mb-3"} key={index}>
              <Col xl="4" md="4">
                <select
                  name="property"
                  value={q.property}
                  id={`property${index}`}
                  className={"col-lg-12 col-md-12 prop-select"}
                  onChange={(e) =>
                    //handleChange(index, e.target.value, "property", q.inputType)
                    handlePropertyChange(index, e.target.value)
                  }
                >
                  {filterPropsPool[props.type].map((pool) => {
                    return <option value={pool.value}>{pool.label}</option>;
                  })}
                </select>
              </Col>
              <Col xl="3" md="3">
                <select
                  value={q.operator}
                  id={`operator${index}`}
                  onChange={(e) => {
                    handleChange(
                      index,
                      e.target.value,
                      "operator",
                      q.inputType
                    );
                  }}
                  className={"col-lg-12 col-md-12 filter-select"}
                  name="operator"
                  style={{
                    lineHeight: "40px",
                    color: "#7e7e7e",
                    paddingLeft: " 15px",
                  }}
                >

                  {
                    availableOperators[property].map((operator) => {
                      return (
                        <option value={operator.value}>{operator.label}</option>
                      );
                    })}
                </select>
              </Col>
              <Col xl="4" md="4">
                {_.find(filterPropsPool[props.type], { value: property }).dataType ===
                  "integer" && (
                    <Fragment>
                      <input
                        type={
                          _.find(filterPropsPool[props.type], ["value", property]).inputType
                        }
                        className={"col-lg-12 col-md-12 filter-input"}
                        name="value1"
                        onChange={(e) =>
                          handleChange(index, e.target.value, "value1", "number")
                        }
                        value={parseInt(value1)}
                      ></input>
                    </Fragment>
                  )}
                {_.find(filterPropsPool[props.type], { value: q.property }).inputType ===
                  "dropdown" && (
                    <Fragment>
                      <select
                        value={q.value1}
                        onChange={(e) =>
                          handleChange(index, e.target.value, "value1")
                        }
                        id={`value${q.property}`}
                        className="col-lg-12 col-md-12 filter-select"
                        //options={availableSource}
                        style={{
                          lineHeight: "40px",
                          color: "#7e7e7e",
                          paddingLeft: " 15px",
                        }}
                      >
                        {availableSource.map((source) => {
                          return (
                            <option value={source.value}>{source.label}</option>
                          );
                        })}
                      </select>
                    </Fragment>
                  )}
              </Col>
              <Col xl={1} md={1}>
                {query.length > 1 && (
                  <i
                    className="fa fa-times fa-2x text-danger"
                    onClick={() => removeFilter(index)}
                  ></i>
                )}
              </Col>
            </Row>
          );
        })}
      <Row>
        <Col xl={11} lg={11}>

          <button
            className="btn btn-secondary pull-right"
            onClick={() => {

              setQuery([...query, initialQuery[props.type]]);
            }}
          >
            Add Filter
          </button>
        </Col>
        <Col xl={1} lg={12}></Col>
      </Row>
    </Fragment>
  );
});
export default InfluencersFilters;
