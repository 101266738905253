export const isAuthenticated = (state) => {
    if (!!state?.auth?.auth?.token) return true;
    return false;
};
export const isAuthorized = (state) => {
    if (!!state.auth.org.org_token) return true;
    return false;
}
export const isFbLoggedIn = (state) => {
    if (!!state.auth.fb.isFbLoggedIn) return true;
    return false;
}

