import {
	APPEND_NEW_POSTS,
	APPEND_NEW_RULE, DELETE_RULE,
	GET_AUTO_REPLIES,
	GET_BRANDS_POST,
	SET_AUTO_REPLIES,
	SET_BRANDS_POST,
	TOGGLE_RULE,
	UPDATE_RULE
} from "../actions/PostTypes";
import _ from "lodash"
const initialData = {
	list : []
}
const pageDataInitital = {
	list: []
}
const selectedFbPageInitial = {
	data: {}
}
export const todoReducers = (state = initialData, action) =>{
	switch(action.type){
		case "ADD_TODO" : 
		const {id, data} = action.payload;
		 // console.log(action);
		return{
			...state,
			list: [
				...state.list,
				{
					id: id,
					data: data,
				}
			]
		}
		case "DELETE_TODO" : 
		const newList = state.list.filter((elem) => elem.id !== action.id)
		
		return{
			...state,
			list: newList 
		}
		
		case "REMOVE_TODO" : return{
			...state,
			list: []
		}
		default: return state;
        
        /* case "EDIT_TODO" : 
        const newEditItem = state.list.find((elem) => elem.id === id)
        return{
            ...state,
			list: newEditItem 
        } 
        ////diffrent type
        case "EDIT TODO" :
        const editItem = (id) => {
            let newEditItem = state.list.find((elem) => {
                return elem.id === action.id
            }); 
            console.log(newEditItem);
        } */   
	}
}
export const pageListReducer = (state = pageDataInitital, action) => {
	if (action.type === 'PAGE_LIST'){
		return {
			...state,
			list: action.payload
		}
	}
	return state;
}
export const connectedPageReducer = (state = selectedFbPageInitial, action) => {
	if(action.type === 'CONNECTED_PAGE') {
		return {
			...state,
			data: action.payload
		}
	}
	return state;
}
export const autoRepliesReducer = (state = {data: [], count: 0}, action) => {
	switch (action.type) {
		case GET_AUTO_REPLIES:
			return state
		case SET_AUTO_REPLIES:
			return {
				...state,
				...action.payload
			}
		case APPEND_NEW_RULE:
			state.data.unshift(action.payload)
			return {
				...state,
			}
		case DELETE_RULE:
			_.remove(state.data, datum => datum.rule_uuid === action.payload);
			return {
				...state
			};
		case TOGGLE_RULE:
			let ruleIndex = _.findIndex(state.data, ["rule_uuid", action.payload.uuid]);
			state.data[ruleIndex].status = action.payload.status === "ACTIVE" ? 'INACTIVE' : "ACTIVE";
			return {
				...state
			}
		case UPDATE_RULE:
			let updateRuleIndex =_.findIndex(state.data,["rule_uuid", action.payload.uuid]);
			state.data[updateRuleIndex] = action.payload.data;
			return {
				...state
			}

	}
	return state
}
export const brandPostReducer = (state= {data: [], count: 0, isLoading: false, nextUrl: ''}, action) => {
	switch (action.type) {
		case GET_BRANDS_POST:
			return state
		case SET_BRANDS_POST:
			return {
				...state,
				...action.payload
			}
		case APPEND_NEW_POSTS:
			state.data.unshift(action.payload);
			return {
				...state,
				...action.payload
			}
	}
	return state
}