import React, { Fragment } from 'react'
import { Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'
import { rewardTypes } from '../../../js/Helpers'
const SelectRewardType = (props) => {
    const { path } = useRouteMatch()
    let onTypeChange = (e) => {
        props.callback(e.target.value)
    }
    return (
        <Fragment>
            <div className='w-100 d-flex justify-content-around flex-wrap mt-5'>
                {
                    Object.keys(rewardTypes).map(type => {
                        return (
                            <Fragment>
                                <label className='' key={rewardTypes[type].value}>
                                    <input type='radio' className='reward-type-input' disabled={!!rewardTypes[type].disabled} name='rewardType' value={rewardTypes[type].value} onChange={(e) => onTypeChange(e)}></input>
                                    <div className={!!rewardTypes[type].disabled ? "reward-type disabled" : "reward-type"} style={{ background: rewardTypes[type].bgColor }}>
                                        <img src={rewardTypes[type].iconUrl} className={'mb-2'}></img>
                                        <h3 className='mt-2'>{rewardTypes[type].title}</h3>
                                        <p className='text-center'>{rewardTypes[type].description}</p>
                                    </div>
                                </label>
                            </Fragment>)
                    })
                }
            </div>
        </Fragment>
    )
}

export default (SelectRewardType)