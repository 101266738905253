import axios from "axios";
import moment, { months } from "moment";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import { orgListAction } from "../store/actions/AuthActions";
import { extractOrgList } from "./AuthService";
export const getUserDetails = () => {
  let user = {};
  try {
    user = JSON.parse(localStorage.getItem("tokenDetails"));
  } catch (err) {
    user = {};
  }
  //const user = JSON.parse(localStorage.getItem("userDetails"));
  return user;
};
export const tokenTimeDifference = (details) => {
  const expireTime = details?.expireDate;
  const currentTime = moment();

  const difference = currentTime.diff(expireTime, "m");
  return difference;
};
export const refreshToken = (oldToken) => {
  const url = `${process.env.REACT_APP_SERVICE_URL}/fluenco/auth/refreshtoken`;
  let config = {
    headers: {
      token: oldToken,
    },
  };
  return axios.post(url, {}, config);
};
export function saveTokenInLocalStorage(tokenDetails) {
  // !tokenDetails.expireDate && (tokenDetails.expireDate = new Date(
  //     new Date().getTime() + 24000 * 1000,
  // ));
  localStorage.setItem("tokenDetails", JSON.stringify(tokenDetails));
}

function parseJwt(token) {
  var base64Payload = token.split(".")[1];
  var payload = Buffer.from(base64Payload, "base64");
  return JSON.parse(payload.toString());
}

export function extractDataFromJwt(data, fromWigzo, dispatch) {
  let token = data?.data?.jwt;
  let decodedToken =  jwt_decode(token);
  let retVal = {
    userDetails: JSON.parse(decodedToken.user_data),
    expireDate: moment(decodedToken.exp * 1000),
    token: token,
  };
  return retVal;
}
