import axios from "axios";
import swal from "sweetalert";
import {
  loginConfirmedAction,
  logout,
  OrgAction,
  fbAuthAction,
  orgListAction,
} from "../store/actions/AuthActions";
import { connectedPageList } from "../store/actions";
import { getSetFbPermissions } from "../store/actions/PostActions";
import { getFbPermissions } from "./../services/PostsService";
import axiosInstance from "./AxiosInstance";
import _ from "lodash";
import moment from "moment";
export function signUp(data) {
  //axios call
  return axios.post(
    `${process.env.REACT_APP_SERVICE_URL}/fluenco/auth/register`,
    data
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  let config = {
    headers: {
      "x-wigzo-host": process.env.REACT_APP_WIGZO_HOST,
    },
  };
  return axios.post(
    //`https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    `${process.env.REACT_APP_SERVICE_URL}/fluenco/auth/login`,
    postData,
    config
  );
}
export function loginWithWigzo(email, password) {
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `${process.env.REACT_APP_SERVICE_URL}/fluenco/auth/login/wigzo`,
    postData
  );
}
export function syncOrgData(orgToken) {
  const postData = {
    org_token: orgToken,
  };
  // let config = {
  //     headers: {
  //         "token": apiToken
  //     }
  // }
  return axiosInstance.post(
    `${process.env.REACT_APP_SERVICE_URL}/fluenco/auth/sync/wigzo`,
    postData
  );
}

export function formatError(errorResponse) {
  let errorMsg =
    !!errorResponse && !!errorResponse.error && !!errorResponse.error.message
      ? errorResponse.error.message
      : errorResponse;
  switch (errorMsg) {
    case "EMAIL_EXISTS":
      //return 'Email already exists';
      swal("Oops", "Email already exists", "error");
      break;
    case "EMAIL_NOT_FOUND":
      //return 'Email not found';
      swal("Oops", "Email not found", "error", { button: "Try Again!" });
      break;
    case "INVALID_PASSWORD":
      //return 'Invalid Password';
      swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
      break;
    case "USER_DISABLED":
      return "User Disabled";
    case "USER_EXIST":
      swal("Oops", "Email already exists", "error");
      break;
    default:
      return "Invalid Email or Password";
  }
}
export function confirmedSignup(history) {
  swal("Congratulations", "Your account has been successfully created", {
    button: "Continue",
  }).then((isConfirm) => {
    history.push("./login");
  });
}
export function extractOrgList(data) {
  return _.map(data, (e) => {
    return e.org_data;
  });
}
export function loginResponseObject(data, fromWigzo, dispatch) {
  let retVal = {
    token: !!fromWigzo ? data.data.token_data.jwt : data.data.jwt,
    expireDate: !!fromWigzo
      ? data.data.token_data.expireDate
      : data.data.expireDate,
    orgData: !!fromWigzo ? data?.data?.org_data : {},
  };
  !!fromWigzo &&
    !_.isEmpty(data?.data?.org_data) &&
    dispatch(orgListAction(extractOrgList(data.data.org_data)));
  return retVal;
}
export function saveTokenInLocalStorage(tokenDetails) {
  !tokenDetails.expireDate &&
    (tokenDetails.expireDate = new Date(new Date().getTime() + 24000 * 1000));
  localStorage.setItem("tokenDetails", JSON.stringify(tokenDetails));
}
export function saveFbTokenInLocalStorage(token) {
  localStorage.setItem("isFbLoggedIn", token);
}
export function saveConnectedPageDataInLocalStorage(data) {
  localStorage.setItem("fbPageData", JSON.stringify(data));
}
export function saveOrgDetailsInLocalStorage(orgDetails) {
  localStorage.setItem("orgDetails", JSON.stringify(orgDetails));
}
export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}
export function isOrgSelected(dispatch) {
  let orgDetails = localStorage.getItem("orgDetails");
  !!orgDetails && (orgDetails = JSON.parse(orgDetails));
  !!orgDetails && dispatch(OrgAction(orgDetails));
  return !!orgDetails;
}
export function isFbLoggedIn(dispatch) {
  let isLoggedIn = localStorage.getItem("isFbLoggedIn");
  !!isLoggedIn && JSON.parse(isLoggedIn);
  dispatch(fbAuthAction(isLoggedIn));
  return !!isLoggedIn;
}
export function isPageConnected(dispatch) {
  let pageDetails = localStorage.getItem("fbPageData");
  !!pageDetails && (pageDetails = JSON.parse(pageDetails));
  !!pageDetails && dispatch(connectedPageList(pageDetails));
  return !!pageDetails;
}
export function dispatchLoginActionAndStartTimer(
  dispatch,
  history,
  tokenDetails,
  expireDate,
  todaysDate
) {
  dispatch(loginConfirmedAction(tokenDetails));
  const timer =
    moment(expireDate).toDate().getTime() -
    moment(todaysDate).toDate(todaysDate).getTime();
  runLogoutTimer(dispatch, timer, history);
}
export function updateFBPermissions(dispatch) {
  getFbPermissions().then((permissions) => {
    dispatch(getSetFbPermissions(permissions.data.data));
  });
}
export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("tokenDetails");
  let tokenDetails = "";
  if (!tokenDetailsString) {
    dispatch(logout(history));
    return;
  }
  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = moment(tokenDetails.expireDate);
  let todaysDate = moment();

  if (todaysDate.diff(expireDate, "m") > 1) {
    dispatch(logout(history));
    return;
  }
  updateFBPermissions(dispatch);
  dispatchLoginActionAndStartTimer(
    dispatch,
    history,
    tokenDetails,
    expireDate,
    todaysDate
  );
  !isFbLoggedIn(dispatch) && history.push('./fb-login');
  !!isFbLoggedIn(dispatch) && !!isOrgSelected(dispatch) && console.log("redirecitng to url");
  !!isFbLoggedIn(dispatch) && !isOrgSelected(dispatch) && history.push('/auth/organizations');

}
