import React, { Fragment, useState } from 'react'
import { Col, Row } from 'react-bootstrap';

export default function UserOrg({ orgList, getProfile }) {
  console.log('org list', orgList)
  let [selectedOrg, setSelectedOrg] = useState(orgList[0]);
  console.log(selectedOrg)
  let handleChange = (value) => {
    //console.log(JSON.parse(value), typeof value)
    setSelectedOrg(JSON.parse(value))
  }
  let prepareDataForParent = () => {
    let data = { token: selectedOrg.org_token };
    getProfile(data)
  }
  return (
    <Fragment>
      <Row className='d-flex justify-content-center align-items-center mt-5'>
        <Col xl={6} lg={6} className={'d-flex flex-column justify-content-center align-items-center'}>
          <h3 className='text-center'>This influencer is associated with many brands. Please select any brand from dropdown to continue. </h3>
          <select
            name="Organizations"
            //value={selectedOrg}
            className={"col-lg-12 col-md-12 prop-select"}
            onChange={(e) =>
              //handleChange(index, e.target.value, "property", q.inputType)
              handleChange(e.target.value)
            }
          >
            {orgList.map((pool) => {
              return <option value={JSON.stringify(pool)}>{pool.org_name}</option>;
            })}
          </select>
          <button className='btn btn-primary mt-3' onClick={() => prepareDataForParent()}>Continue</button>
        </Col>
      </Row>
    </Fragment>
  )
}
