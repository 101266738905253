import React from "react";
import { BrowserRouter, Switch, useRouteMatch, Route, Redirect } from "react-router-dom";
import { Row } from "react-bootstrap";
import StoryReplyForm from "../components/StoryReply/StoryReplyForm";

const StoryReply = () => {
    const { path } = useRouteMatch();
    document.title = "Story Reply";
    return (
        <BrowserRouter>
            <Row>
                <Switch>
                    <Route path={`${path}/create`} component={StoryReplyForm}></Route>
                    <Route path={`${path}/edit`} component={StoryReplyForm}></Route>
                    <Redirect from={`${path}`} to={`${path}/create`}></Redirect>
                </Switch>
            </Row>
        </BrowserRouter>
    )
}

export default StoryReply;