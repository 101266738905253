import React from "react";
import {connect} from "react-redux";
import {BrowserRouter, Switch, useRouteMatch, Route, Redirect} from "react-router-dom";
import {Row} from "react-bootstrap";
import AutoReplyList from "../components/AutoReply/AutoReplyList";
import CreateAutoReply from "../components/AutoReply/CreateAutoReply";

const AutoReply = () => {
    const {path} = useRouteMatch();
    document.title = "Auto Reply";
    return (
        <BrowserRouter>
            <Row>
                <Switch>
                    <Route path={`${path}/list`} component={AutoReplyList}></Route>
                    <Route path={`${path}/create/`} component={CreateAutoReply}></Route>
                    {/*<Route exact path={`${path}/create/`} component={CreateAutoReply}></Route>*/}
                    <Route path={`${path}/edit/:uuid`} component={CreateAutoReply}/>
                    <Redirect from={`${path}`} to={`${path}/list`}></Redirect>
                </Switch>
            </Row>
        </BrowserRouter>
    )
}

export default AutoReply;