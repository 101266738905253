import React, { Fragment, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import data from "../bootstrap/alertData";
import { PostTypes } from "../../../js/Helpers";
import { CarouselPost, UnsupportedMedia } from "./Common";
import PerfectScrollbar from "react-perfect-scrollbar";
import { abbreviateNumber } from "js-abbreviation-number";
import moment from "moment";
import _ from "lodash"
const PostModal = (props) => {
    const   renderTags = (str) => {
        if (!!str) {
            let result = [];
            let lastIndex = 0;
            const regexp = /\B\#\w\w+\b/g
            str.replace(regexp, (tag, tagIndex) => {
                result.push(str.slice(lastIndex, tagIndex));
                lastIndex = tagIndex + tag.length;
                result.push(<a key={tagIndex} href={`http://instagram.com/tags/${tag.substring(1)}`} className={'btn-link'} target={'_blank'}>{tag}</a>)
            })
            let retStr = result.length ? result : str;
            return retStr;
        } else {
            return str;
        }

    }

    useEffect(() => {
        // window.Hashtag.setOptions({
        //     insta: `<a href="http://instagram.com/tags/{#n}" target="_blank">{#}</a>`
        // })
        // window.Hashtag.replaceTags("#post-caption", 'insta');
        !!props.showModal && (document.body.style.overflow = 'unset');
    }, [props.showModal])
    return (
        <Modal className="fade bd-example-modal-lg rounded-0 post-modal"
            show={props.showModal}
            size="lg"
            backdrop={true}
            onExited={() => {
                return props.hideModal();
            }}
            onHide={() => {
                return props.hideModal();
            }}>
            <Modal.Title className="position-relative">
                <span className="modal-close" onClick={() => {
                    props.hideModal();
                }}>X</span>
            </Modal.Title>
            <Modal.Body className="p-0">
                <div className="m-0 h-100 d-flex flex-row" style={{ minHeight: '450px' }}>
                    <div className="p-0 w-100 media-box d-flex align-items-center justify-content-center" style={{ minHeight: "450px" }}>
                        <div className="d-flex align-items-center justify-content-center p-5"
                             style={{width: (props.mediaType === PostTypes.VIDEO.value && !props.mediaUrl) || (props.mediaType === PostTypes.CAROUSEL_ALBUM.value && props.data?.extra_data?.children) ? '100%' : 'auto'}}>
                            {
                                props.mediaType === PostTypes.VIDEO.value && !!props.mediaUrl ? (
                                    <video controls autoplay className="w-100 h-100">
                                        <source src={props.mediaUrl}></source>
                                    </video>
                                ) : props.mediaType === PostTypes.VIDEO.value && !props.mediaUrl ? (
                                    <UnsupportedMedia permalink={props.data.permalink} isModal={true} />
                                ) : props.mediaType === PostTypes.IMAGE.value && !!props.mediaUrl ? (
                                    <img src={props.mediaUrl} className="w-100 h-100" style={{ objectFit: 'contain' }} />
                                ) : props.mediaType === PostTypes.CAROUSEL_ALBUM.value && props.data?.extra_data?.children ? (
                                    <CarouselPost data={props.data.extra_data.children.data} />
                                ) : <UnsupportedMedia permalink={props.data.permalink} isModal={true}></UnsupportedMedia>

                            }
                        </div>
                    </div>
                    <div className="w-100 caption-box">
                        {
                            !_.isEmpty(props?.userProfile) && (
                                <div className="user p-3 d-flex justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <img src={props.userProfile.profileIcon} className={'rounded-avatar mr-2'} />
                                        <div>
                                            <h5 className="m-0">{props.userProfile.handle}</h5>
                                            <span className="m-0">{props.userProfile.userName}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <span><span style={{ color: '#000000' }}>{props.userProfile.followers}</span> Followers</span>
                                        <span><span style={{ color: '#000000' }}>{props.userProfile.following}</span> Following</span>
                                    </div>
                                </div>
                            )


                        }

                        <PerfectScrollbar>
                            <div className="p-3" id="post-caption" style={{ maxHeight: '400px' }}>
                                {renderTags(props?.data?.extra_data?.caption || props.data.caption)}
                            </div>
                        </PerfectScrollbar>
                        <div className="w-100 likes-comment p-3 d-flex align-items-center justify-content-between">
                            <div>
                                <p className="p-0 mb-1">{!!props.data?.created_at ? moment(props.data?.created_at).fromNow() : null}</p>
                                <div>
                                    <span className="mr-4">
                                        {!!props.data?.extra_data?.like_count ? abbreviateNumber(props.data?.extra_data?.like_count) : '0'}
                                        <i
                                            className="fa fa-heart ml-2"
                                            aria-hidden="true"
                                            style={{ color: "red" }}
                                        ></i>
                                    </span>
                                    <span>
                                        {!!props.data?.extra_data?.comments_count ? abbreviateNumber(props.data?.extra_data?.comments_count) : '0'}
                                        <i className="fa fa-comment-o ml-2" aria-hidden="true"></i>
                                    </span>
                                </div>
                            </div>
                            <div>
                                <a href={props.data?.extra_data?.permalink} target={'_blank'}>
                                    view on instagram
                                    <i className="fa fa-external-link ml-1"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default PostModal;