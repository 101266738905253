import React from "react";
import {Button, Modal} from "react-bootstrap";
import Filters from './FIlters'

const FilterModal = (props) => {
    let hideModal = () => {
        props.hideModalCallBack()
    }
    let handleFormSubmission = (data) => {
        //console.log("Data from Filter component", data);
        props.callback(data);
    }
    return (
        <Modal
            className="fade bd-example-modal-lg"
            show={props.showModal}
            size="lg"
            backdrop={true}
            onExited={() => {
                return;
            }}
            onHide={() => {
                return;
            }}
        >
            <Modal.Header>
                <Modal.Title>{props.modalTitle}</Modal.Title>
                <Button
                    variant=""
                    className="close"
                    onClick={() => hideModal()}
                >
                    <span>&times;</span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Filters type={props.type} formId={props.formId} criteria={props.criteria} applyFilterCallback={(data) => handleFormSubmission(data)}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger light" onClick={() => hideModal()}>
                    Close
                </Button>
                <Button
                    variant=""
                    type="submit"
                    className="btn btn-primary"
                    form={props.formId}
                   // onClick={applyFilter}
                >
                    {props.confirmButtonText || 'Apply Filter'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default FilterModal;