import { abbreviateNumber } from "js-abbreviation-number";
import _ from "lodash";
import moment from "moment";
import swal from "sweetalert";
import * as yup from "yup";

export function showAlert(
  title,
  text,
  showChancelButton,
  icon,
  buttons,
  confirmCallback
) {
  swal({
    title: title,
    text: text,
    icon: icon,
    buttons: true,
  }).then((isConfirm) => {
    !!isConfirm && confirmCallback();
  });
}
export const Channels = {
  INSTAGRAM: { label: "Instagram", value: "instagram" },
};
export const TableHeaders = {
  CREATORS: {
    hasCheckbox: true,
    data: [
      "Creator Name",
      "Tagged Post",
      "DM",
      "Followers",
      "Engagement Rate",
      "Last Posted Date",
    ],
  },
  GROWTH_TOOLS: {
    hasCheckbox: false,
    data: ["Landing Page Url", "Subscription Source", "Created At", "Action"],
  },
  REWARD_RULE: {
    hasCheckbox: false,
    data: ["Name", "Created At", "Status", "Action"],
  },
  AUTO_REPLIES: {
    hasCheckbox: false,
    data: ["Rule", "Keywords", "Template Type", "Updated At", "Action"],
  },
  DEFAULT_REPLIES: {
    hasCheckbox: false,
    data: ["Rule", "Template Type", "Updated At", "Action"],
  }
};
export const filterQuery = {
  property: "",
  operator: "",
  value1: null,
};

export const initialQuery = {
  creators: {
    property: "followers_count",
    operator: "gt",
    value1: 0,
  },
  content: {
    property: "likes_count",
    operator: "gt",
    value1: 0,
  },
};
export const filterPropsPool = {
  creators: [
    {
      label: "Followers Count",
      value: "followers_count",
      dataType: "integer",
      inputType: "number",
    },
    {
      label: "Engagement Rate",
      value: "engagement_rate",
      dataType: "integer",
      inputType: "number",
    },
    {
      label: "Source",
      value: "subscription_source",
      dataType: "string",
      inputType: "dropdown",
    },
  ],
  content: [
    {
      label: "Username",
      value: "username",
      dataType: "string",
      inputType: "text",
    },
    {
      label: "Likes",
      value: "likes_count",
      dataType: "integer",
      inputType: "number",
    },
    {
      label: "Comments",
      value: "comments_count",
      dataType: "integer",
      inputType: "number",
    },
  ],
};
export const discountTypes = [
  {
    label: "Percentage",
    value: "percent",
  },
  {
    label: "Amount",
    value: "amount",
  },
];
export let defaultQueryForProperty = {
  followers_count: { property: "followers_count", operator: "gt", value1: 0 },
  engagement_rate: { property: "engagement_rate", operator: "gt", value1: 0 },
  subscription_source: {
    property: "subscription_source",
    operator: "eq",
    value1: "TAGGED_POST",
  },
};
let operatorForNumbers = [
  { label: "Greater Than or Equal To", value: "ge" },
  { label: "Greater Than", value: "gt" },
  { label: "Not Equals", value: "ne" },
  { label: "Equals", value: "eq" },
  { label: "Less Than", value: "lt" },
  { label: "Less Than or Equal To", value: "le" },
];
let operatorForString = [
  { label: "Is", value: "eq" },
  { label: "Is not", value: "ne" },
];
let operatorForEnums = [
  { label: "Is", value: "eq" },
  { label: "Is not", value: "ne" },
];
export const availableOperators = {
  followers_count: operatorForNumbers,
  engagement_rate: operatorForNumbers,
  subscription_source: operatorForEnums,
  tags: operatorForNumbers,
  impressions: operatorForNumbers,
  engagement: operatorForNumbers,
  reach: operatorForNumbers,
  story_mentions: operatorForNumbers,
  username: operatorForString,
  likes_count: operatorForNumbers,
  comments_count: operatorForNumbers,
};
export const availableSource = [
  { label: "Tagged Post", value: "TAGGED_POST" },
  { label: "Landing Page", value: "GROWTH_TOOL_LANDING_PAGE" },
  { label: "Story Mention", value: "STORY_MENTION" },
];
export const translationPool = {
  followers_count: "Followers",
  gt: "greater than",
  subscription_source: "Source",
  ORGANIC: "Tagged Post",
  engagement_rate: "Engagement Rate",
  GROWTH_TOOL_LANDING_PAGE: "Landing Page",
  TAGGED_POST: "Tagged Post",
  eq: "Equals",
  ne: "Not Equals",
  STORY_MENTION: "Story Mention",
  ge: "Greater than or Equals To",
  le: "Less than or Equals to",
  username: "Username",
  likes_count: "Likes",
  comments_count: "Comments",
};
export const chartDataForPosts = {
  options: {
    chart: {
      id: "posts",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [],
    },
    dataLabels: {
      enabled: false,
    },
  },
  series: [
    {
      name: "Posts",
      data: [],
      color: "#4D09F6",
    },
  ],
};
export const chartDataForImpressions = {
  options: {
    chart: {
      id: "Impressions",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [],

      labels: {
        show: !(window.innerWidth < 768),
      },
    },
    dataLabels: {
      enabled: false,
    },
  },
  series: [
    {
      name: "Impressions",
      data: [],
      color: "#1AB1F4",
    },
  ],
};
export const chartDataForEngagement = {
  options: {
    chart: {
      id: "Reach",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [],
      labels: {
        show: !(window.innerWidth < 768),
      },
    },
    dataLabels: {
      enabled: false,
    },
  },
  series: [
    {
      name: "Reach",
      data: [],
      color: "#FFA400",
    },
  ],
};
export const chartDataTaggedPost = {
  options: {
    chart: {
      id: "Tagged Post",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: ["Thu", "Fri", "Sat", "Sun", "Mon", "Tue", "Wed"],
    },
    dataLabels: {
      enabled: false,
    },
  },
  series: [
    {
      name: "Tagged Posts",
      data: [4, 3, 1, 5, 8, 2, 7],
      color: "#0F8313",
    },
  ],
};
export const initialInsights = [
  {
    title: "Posts",
    description: "Total number of posts by you",
    name: "user_posts",
    value: 0,
  },
  {
    title: "Impressions",
    description:
      "The number of times any content from your profile or about your profile entered a person's screen",
    name: "impressions",
    value: 0,
  },
  {
    title: "Reach",
    description:
      "Number of people were exposed to your content during a campaign",
    name: "reach",
    value: 0,
  },
  {
    title: "Tagged Post",
    description: "Total number of posts you are tagged in.",
    name: "tagged_posts",
    value: 0,
  },
];

let getValuesFromObject = (obj) => {
  return _.sum(obj.values, "value");
};
let getInsightByNameFromResponse = (name, data) => {
  let insights = _.map(data, (datum) => {
    return datum.insights.insights;
  });
  let insightByName = _.map(insights, (insight) => {
    return _.find(insight, (d) => {
      return d.name === name;
    });
  });
  return insightByName;
};
let extractInsightByProps = (name, data) => {
  let finalValue = _.sum(
    _.flattenDeep(
      _.map(getInsightByNameFromResponse(name, data), function (e) {
        return _.map(e.values, (f) => {
          return f.value;
        });
      })
    )
  );
  //return 0;
  return finalValue;
};
let extractChartDataFromResponse = (data, type, name) => {
  if (!data[0]?.insights && !data[0]?.insights.insights.length) return [];
  let insightMap = getInsightByNameFromResponse(name, data);
  let finalValue = _.map(
    _.sortBy(
      _.uniqBy(
        _.flattenDeep(
          _.map(insightMap, (e) => {
            return e.values;
          })
        ),
        "end_time"
      ),
      (dt) => {
        return new Date(dt["end_time"]);
      }
    ),
    type
  );
  return _.orderBy(finalValue);
};
export const extractInsightsDataFromResponse = (data, dataToExtract) => {
  switch (dataToExtract) {
    case "PAGE_INSIGHTS":
      if (!data.length) return initialInsights;
      if (!data[0]?.insights || !data[0].insights.insights.length)
        return initialInsights;
      initialInsights.map((insight) => {
        if (insight.name === "tagged_posts" || insight.name === "user_posts") {
          insight.value =
            insight.name === "tagged_posts"
              ? data[0].insights.tagged_posts_count
              : data[0].insights.user_posts_count;
          // insight.value = data[0].insights.length
          //   ? data[0].insights.map((val) => {
          //     if (
          //       val.hasOwnProperty(insight.name) ||
          //       val.hasOwnProperty(insight.name.slice(0, -1))
          //     ) {
          //       let name = val.hasOwnProperty(insight.name)
          //         ? insight.name
          //         : insight.name.slice(0, -1);
          //       return val[name].count;
          //     }
          //   })
          //   : data[0].insights[insight.name].data.length;
        } else {
          insight.value = extractInsightByProps(insight.name, data);
        }
      });
      return initialInsights;
  }
};
export const dummyPosts = [
  {
    id: 1,
    profile: {
      profile_picture_url:
        "https://scontent.fdel52-1.fna.fbcdn.net/v/t51.2885-15/292278223_806302227421052_5000913808591957914_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=86c713&_nc_ohc=jp0RgtcdVgUAX8iBBLu&_nc_ht=scontent.fdel52-1.fna&edm=AL-3X8kEAAAA&oh=00_AT_0-AMAUH7JCPqspZCXslEjBl5cWgUXvfpeMhmJjh_g2g&oe=62DDE466",
      followers_count: 3,
      follows_count: 3,
    },
    media_url:
      "https://scontent.cdninstagram.com/v/t51.29350-15/291866914_730095838264160_481270191293147575_n.webp?stp=dst-jpg&_nc_cat=106&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=BEnth0qAxE4AX82gHfq&_nc_ht=scontent.cdninstagram.com&edm=AJ7ooaQEAAAA&oh=00_AT8tfPf3KnzHJA6oOXSqHWZ0prCK4oRhwY4UvPESf721GA&oe=62DECAD2",
    username: "test_wigzoadil",
    like_count: 3,
    comment_count: 3,
  },
  {
    id: 3,
    profile: {
      profile_picture_url:
        "https://scontent.fdel52-1.fna.fbcdn.net/v/t51.2885-15/292278223_806302227421052_5000913808591957914_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=86c713&_nc_ohc=jp0RgtcdVgUAX8iBBLu&_nc_ht=scontent.fdel52-1.fna&edm=AL-3X8kEAAAA&oh=00_AT_0-AMAUH7JCPqspZCXslEjBl5cWgUXvfpeMhmJjh_g2g&oe=62DDE466",
      followers_count: 3,
      follows_count: 3,
    },
    media_url:
      "https://scontent.cdninstagram.com/v/t51.29350-15/287704380_986699035333465_3694697142118784153_n.webp?stp=dst-jpg&_nc_cat=100&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=mZiIvQpSRrQAX8QOMVm&_nc_ht=scontent.cdninstagram.com&edm=AJ7ooaQEAAAA&oh=00_AT-U8PS58AnlctHMI9vcZ79Dee19IaH6yUOuvUembCHvEA&oe=62E02094",
    username: "test_wigzoadil",
    like_count: 3,
    comment_count: 3,
  },
  {
    id: 3,
    profile: {
      profile_picture_url:
        "https://scontent.fdel52-1.fna.fbcdn.net/v/t51.2885-15/292278223_806302227421052_5000913808591957914_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=86c713&_nc_ohc=jp0RgtcdVgUAX8iBBLu&_nc_ht=scontent.fdel52-1.fna&edm=AL-3X8kEAAAA&oh=00_AT_0-AMAUH7JCPqspZCXslEjBl5cWgUXvfpeMhmJjh_g2g&oe=62DDE466",
      followers_count: 3,
      follows_count: 3,
    },
    media_url:
      "https://scontent.cdninstagram.com/v/t51.29350-15/285440438_751255826059948_1645770789562550200_n.webp?stp=dst-jpg&_nc_cat=107&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=wK4CRi1P5Y8AX_AsHma&_nc_ht=scontent.cdninstagram.com&edm=AJ7ooaQEAAAA&oh=00_AT_bv-4eZwdX8Ox9r3yM--o7cxMcx8UOaQQK_EQ2y4YsCA&oe=62DEE222",
    username: "test_wigzoadil",
    like_count: 3,
    comment_count: 3,
  },
  {
    id: 4,
    profile: {
      profile_picture_url:
        "https://scontent.fdel52-1.fna.fbcdn.net/v/t51.2885-15/292278223_806302227421052_5000913808591957914_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=86c713&_nc_ohc=jp0RgtcdVgUAX8iBBLu&_nc_ht=scontent.fdel52-1.fna&edm=AL-3X8kEAAAA&oh=00_AT_0-AMAUH7JCPqspZCXslEjBl5cWgUXvfpeMhmJjh_g2g&oe=62DDE466",
      followers_count: 3,
      follows_count: 3,
    },
    media_url:
      "https://scontent.cdninstagram.com/v/t51.29350-15/285440438_751255826059948_1645770789562550200_n.webp?stp=dst-jpg&_nc_cat=107&ccb=1-7&_nc_sid=8ae9d6&_nc_ohc=wK4CRi1P5Y8AX_AsHma&_nc_ht=scontent.cdninstagram.com&edm=AJ7ooaQEAAAA&oh=00_AT_bv-4eZwdX8Ox9r3yM--o7cxMcx8UOaQQK_EQ2y4YsCA&oe=62DEE222",
    username: "test_wigzoadil",
    like_count: 3,
    comment_count: 3,
  },
];
let chartData = () => { };
export const prepareChartData = (data, color, title, fromCreators) => {
  return {
    options: {
      chart: {
        id: title,
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        //categories: _.map(data[0].values, "end_time"),
        categories: !!fromCreators
          ? _.map(data[0].values, "end_time")
          : extractChartDataFromResponse(data, "end_time", title.toLowerCase()),
        labels: {
          formatter: function (value) {
            let retVal = !!value ? moment(value).format("YYYY-MM-DD") : "";
            return retVal;
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return abbreviateNumber(parseInt(value));
        },
      },
    },
    series: [
      {
        name: title,
        data: !!fromCreators
          ? _.map(data[0].values, "value")
          : extractChartDataFromResponse(data, "value", title.toLowerCase()),
        //data: _.map(data[0].values, "value"),
        color: color,
      },
    ],
  };
};

export const PostTypes = {
  // ALL: { iconUrl: '/content/all.svg', label: "All", value: 'ALL' },
  VIDEO: { iconUrl: "/content/reels.svg", label: "Reels", value: "VIDEO" },
  IMAGE: { iconUrl: "/content/postimage.svg", label: "Images", value: "IMAGE" },
  STORY_MENTION: {
    iconUrl: "/content/stories.svg",
    label: "Stories",
    value: "STORY_MENTION",
  },
  CAROUSEL_ALBUM: {
    iconUrl: "/content/postimage.svg",
    label: "Album",
    value: "CAROUSEL_ALBUM",
  },
};

export const extractLatestFromResponse = (data) => {
  let extractVideosImages = _.filter(data, function (o) {
    return o.media_type != "stories";
  });
  return extractVideosImages;
};
export const rewardTypes = {
  discount_coupon: {
    title: "Discount Coupon",
    value: "discount_coupon",
    description: "Send heavy discount coupon",
    bgColor: "#EDFBFE",
    iconUrl: "/discount_coupon.svg",
    disabled: false,
  },
  product_gifting: {
    title: "Product Gifitng",
    value: "product_gifting",
    description: "Send out free products to creators",
    bgColor: "#FDF6E9",
    iconUrl: "/product_gifting.svg",
    disabled: true,
  },
  paid_promotion: {
    title: "Paid Promotion",
    value: "paid_promotion",
    description: "Payment in exchange for posts",
    bgColor: " #E8E8E8",
    iconUrl: "/paid_promotion.svg",
    disabled: true,
  },
};
export const ruleProps = [
  { label: "Engagement", value: "engagement" },
  { label: "Impression", value: "impressions" },
  { label: "Reach", value: "reach" },
  { label: "Tags", value: "tags" },
  { label: "Story Mentions", value: "story_mentions" },
];
export const rewardRuleInitialData = {
  start_time: new Date(),
  end_time: new Date(),
  metadata: {
    reward_type: "coupon",
    discount_data: {
      discount_type: "percentage",
      discount_value: "10",
      currency: "INR",
    },
    reward_rule: {
      quantity: "5",
      operator: "eq",
      criteria: "tags",
    },
  },
  name: "",
  description: "",
};

export const registrationData = {
  registration_type: "brand",
  email: "",
  password: "",
  full_name: "",
  phone: "",
  // "org_domain": "",
  // "org_name": "",
};
export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const urlRegExp =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
export const signupSchemaValidation = yup.object({
  email: yup
    .string()
    .required("Email is Required")
    .email("Must be a valid email address"),
  full_name: yup.string().required("Name is required"),
  password: yup
    .string()
    .required("Password can not be blank")
    .min(4, "Password is too short. It should be of 4 characters minimum"),
  phone: yup
    .string()
    .required("Phone is required")
    .matches(phoneRegExp, "Phone number is not valid"),
  // org_domain: yup.string().required("Domain is requires").matches(urlRegExp, "Please Enter a valid url"),
  // org_name: yup.string().required("Organization name is required"),
});

export const appendHashtagScript = () => {
  const script = document.createElement("script");
  script.src = "/js/hashtag.min.js";
  script.async = true;
  script.type = "text/javascript";
  document.head.appendChild(script);
};
