import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
  loginFailedAction,
} from "../../store/actions/AuthActions";

//
//import logo from '../../images/logo.png'
//import logotext from '../../images/logo-text.png'

function Login(props) {
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  document.title = "Login - NitroCollab";
  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, props));
  }

  const refresh = () => {
    if (!!props.errorMessage) {
      dispatch(loginFailedAction(""));
    }
  };

  return (
    <div className="login-form-bx">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-7 box-skew d-flex align-items-center justify-content-center">
            <div className="authincation-content">
              <div className="mb-4">
                <h3 className="mb-1 font-w600">
                  Welcome to{" "}
                  <span style={{ color: "#6bb12a" }}>NitroCollab</span>
                </h3>
                <p className="">Sign in by entering information below</p>
              </div>
              {props.errorMessage && (
                <div className="bg-red-300 text-danger border border-danger p-1 my-2">
                  {props.errorMessage}
                </div>
              )}
              {props.successMessage && (
                <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                  {props.successMessage}
                </div>
              )}
              <form onSubmit={onLogin}>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Email</strong>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={refresh}
                  />
                  {errors.email && (
                    <div className="text-danger fs-12">{errors.email}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Password</strong>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onFocus={refresh}
                  />
                  {errors.password && (
                    <div className="text-danger fs-12">{errors.password}</div>
                  )}
                </div>
                <div className="form-row d-flex justify-content-between mt-4 mb-2">
                  <div className="form-group">
                    <div className="custom-control custom-checkbox ml-1 ">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="basic_checkbox_1"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="basic_checkbox_1"
                      >
                        Remember my preference
                      </label>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    disabled={props.showLoading}
                    className="btn btn-block"
                    style={{ backgroundColor: "#6bb12a", color: "white" }}
                  >
                    {props.showLoading && (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    )}{" "}
                    {props.showLoading && <>Please Wait...</>}
                    {!props.showLoading && <>Sign In</>}
                  </button>
                </div>
              </form>
              <div className="new-account mt-2">
                <p className="mb-0">
                  Don't have an account?{" "}
                  <Link className="text-black" to="./signup" onClick={refresh}>
                    Sign up
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-5 d-flex box-skew1 justify-content-center align-items-center">
            {/* <div className="inner-content align-self-center">
							<Link to="/dashboard" className="login-logo">
								<img src={logo} alt="" className="logo-icon mr-2"/>
								<img src={logotext} alt="" className="logo-text ml-1"/>
							</Link>
							<h2 className="m-b10 text-white">Login To You Now</h2>
							<p className="m-b40 text-white">User Experience & Interface Design Strategy SaaS Solutions</p>
							<ul className="social-icons mt-4">
								<li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
								<li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
								<li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
							</ul>
						</div> */}
            <img src="/auth-bg.svg" style={{ width: "80%" }} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
