import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import {
  PostsReducer,
  FilterReducer,
  PageInsightsReducer,
  FbPermissionReducer,
  LatestPostReducer,
  LatestTaggedImagesReducer
} from "./reducers/PostsReducer";
import OrgList from "./reducers/OrgReducer";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import { OrgReducer, FbAuthReducer } from "./reducers/OrgReducer";
//import rootReducers from './reducers/Index';
import {
  todoReducers,
  pageListReducer,
  connectedPageReducer,
  autoRepliesReducer, brandPostReducer
} from "./reducers/Reducers";
import { reducer as reduxFormReducer } from "redux-form";
import { DESTROY_SESSION } from "./actions/PostTypes";
const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  posts: PostsReducer,
  filters: FilterReducer,
  insights: PageInsightsReducer,
  fbPermission: FbPermissionReducer,
  latestTaggedPosts: LatestPostReducer,
  latestTaggedImages: LatestTaggedImagesReducer,
  auth: AuthReducer,
  todoReducers,
  pageListReducer,
  connectedPageReducer,
  OrgList,
  form: reduxFormReducer,
  autoReplies: autoRepliesReducer,
  brandPosts: brandPostReducer
});
const rootReducers = (state, action) => {
  if (action.type === DESTROY_SESSION) {
    state = undefined;
  }
  return reducers(state, action)
}
//const store = createStore(rootReducers);

const store = createStore(rootReducers, composeEnhancers(middleware));
export default store;