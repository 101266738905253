import React, { Fragment, useContext, useEffect, useState } from "react";
import { connect, ReactReduxContext, useDispatch } from "react-redux";
import * as Loader from "react-loader-spinner";
import ReactApexChart from "react-apexcharts";

//** Import Image */
import profileImg from "../../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";

//import ApexChart from "../../charts/apexcharts/ApexChart";
import JobSlide from "./JobSlide";
import { FeaturedSlide } from "./FeaturedSlide";
import DualLine3 from "../../charts/Chartjs/dualLine3";
import { Row, Col, Tab, Nav } from "react-bootstrap";
import {
  TabWidget,
  InstaPostCard,
  TitleHeader,
  UserGreeting,
  LoadingIndicatore,
  KpiWidget,
  ThreeDotsLoader,
  BigLinkButton,
  InstaStory
} from "../../Commons/Common";
import Pagination from "../../Commons/Pagination";
import {
  getInstaPost,
  getPageInsights,
  getLatestTaggedPosts
} from "../../../../services/PostsService";
import { trackPromise } from "react-promise-tracker";
import { ToastContainer, toast } from "react-toastify";
import Index from "../../../index";
import "rsuite/dist/rsuite.min.css";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  chartDataForEngagement,
  chartDataForImpressions,
  chartDataForPosts,
  chartDataTaggedPost,
  extractInsightsDataFromResponse,
  initialInsights,
  prepareChartData,
  extractLatestFromResponse
} from "../../../../js/Helpers";
import LatestTaggedImages from "./LatestTaggedImages";
import _ from "lodash";
import { DateRangePicker } from "rsuite";
import { addDays, subDays } from "date-fns";
import { getSetLatestTaggedPosts } from "../../../../store/actions/PostActions";
import { Link } from "react-router-dom";

const Home = (props) => {
  console.log("Home props", props);
  const { afterToday } = DateRangePicker;
  let [postData, setPostData] = useState({
    loading: false,
    data: [],
    count: 0,
    hasError: false,
  });
  let [pageInsights, setPageInsights] = useState({
    loading: false,
    data: initialInsights,
    hasError: false,
    errorMsg: "",
  });
  let [perPage, setPerPage] = useState(10);
  let [pageNum, setPageNum] = useState(1);
  let [activeTab, setActiveTab] = useState("impressions");
  const { store } = useContext(ReactReduxContext);
  let [infiniteScrollData, setInfiniteScrollData] = useState({
    hasMoreData: true,
    nextUrl: "",
  });
  let orgDetails = store.getState().auth.org;
  let userDetails = store.getState().auth.auth.userDetails;
  let [dateRange, setDateRange] = useState({
    startDate: subDays(new Date(), 7),
    endDate: new Date(),
  });
  let [chartData, setChartData] = useState({
    impression: chartDataForImpressions,
    reach: chartDataForEngagement,
  });
  const dispatch = useDispatch();
  let getTaggedPost = (num) => {
    setPostData({ loading: true, data: [], count: 0, hasError: false, errorMsg: "" });
    getInstaPost(orgDetails.org_token, num, 'IMAGE', true)
      .then((res) => {
        setPostData({ loading: false, data: extractLatestFromResponse(res.data.data), count: (res?.data?.count || 0), hasError: false });

        setInfiniteScrollData({
          hasMoreData: !!res.data.next,
          nextUrl: !!res.data.next ? res.data.next : "",
        });
        setPageNum(num)
      })
      .catch((err) => {
        setPostData({
          loading: false,
          data: [],
          hasError: true,
          count: 0,
          errorMsg: err,
          hasMoreData: true,
        });
        toast.error("Error in fetching Posts.Please try again", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  }
  let setPageInsightsAndGraph = (res) => {
    let insightsData = extractInsightsDataFromResponse(
      res.data,
      "PAGE_INSIGHTS"
    );
    setPageInsights({
      loading: false,
      data: insightsData,
      hasError: false,
      errorMsg: "",
    });
    let chartOptions = {
      impression: prepareChartData(
        res.data,
        "#1AB1F4",
        "Impressions",
      ),
      reach: prepareChartData(
        res.data,
        "#FFA400",
        "Reach"
      ),
    };
    setChartData(chartOptions);
  }
  useEffect(() => {
    document.title = "NitroCollab - Home";
    const fetchData = () => {
      // getLatestTaggedPosts(orgDetails.org_token, props.latestTaggedPosts).then(res => {
      //   console.log('response from there', res)
      // });
      // dispatch(getSetLatestTaggedPosts(latestTaggedPost));
      getTaggedPost(pageNum);
      getPageInsights(orgDetails.org_token, userDetails.api_token, dateRange)
        .then((res) => {
          setPageInsightsAndGraph(res)
        })
        .catch((err) => {
          setPageInsights({
            loading: false,
            data: initialInsights,
            hasError: false,
            errorMsg: "",
          });
          toast.error("Error in fetching Page Insights.Please try again", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        });
    };
    fetchData();
  }, [setPageInsights]);
  useEffect(() => {
    console.log("chart data updated");
  }, [activeTab]);
  useEffect(() => {
    console.log("chart data updated");
  }, [pageInsights]);
  useEffect(() => {
    console.log("date Updated", dateRange);
    fetchInsights();
  }, [dateRange]);
  let fetchInsights = async () => {
    let fetchedData = await getPageInsights(
      orgDetails.org_token,
      userDetails.api_token,
      dateRange
    );
    setPageInsightsAndGraph(fetchedData)
  };
  let switchTab = (tab) => {
    setActiveTab(tab);
  };
  let fetchNext = (event) => {



    //let concatedData = postData.data.concat(data.data.data);


  };

  return (
    <InfiniteScroll dataLength={0}>
      <Fragment>
        <UserGreeting userData={userDetails} />
        <Col xl={12} className={"d-flex justify-content-end mb-4"}>
          <DateRangePicker
            block={true}
            value={[dateRange.startDate, dateRange.endDate]}
            cleanable={false}
            placement={"auto"}
            preventOverflow={true}
            disabledDate={afterToday()}
            onOk={(value) => {
              setDateRange({ startDate: value[0], endDate: value[1] });
            }}
          ></DateRangePicker>
        </Col>
        <Row>
          {props.insights.data.map((datum) => {
            return _.isEqual(datum.name, "reach") ||
              _.isEqual(datum.name, "impressions") ? (
              <Col
                xl={3}
                key={datum.id}
                className={""}
                onClick={() => setActiveTab(datum.name)}
              >
                <KpiWidget data={datum} isActive={activeTab === datum.name} />
              </Col>
            ) : (
              <Col xl={3} key={datum.id} className={""}>
                <KpiWidget data={datum} isActive={activeTab === datum.name} />
              </Col>
            );
          })}
        </Row>
        <Row className={"bg-white pt-2"}>
          <Col lg={12}>
            {/* {activeTab === "user_post" && (
                <ReactApexChart
                  options={chartDataForPosts.options}
                  series={chartDataForPosts.series}
                  type="area"
                  height={350}
                />
              )} */}
            {activeTab === "impressions" && (
              <ReactApexChart
                options={chartData["impression"].options}
                series={chartData["impression"].series}
                type="area"
                height={350}
              />
            )}
            {activeTab === "reach" && (
              <ReactApexChart
                options={chartData["reach"].options}
                series={chartData["reach"].series}
                type="area"
                height={350}
              />
            )}
            {/* {activeTab === "tagged_post" && (
                <ReactApexChart
                  options={chartDataTaggedPost.options}
                  series={chartDataTaggedPost.series}
                  type="area"
                  height={350}
                />
              )} */}
          </Col>
        </Row>
        <TitleHeader title={"Latest Tagged Post"} />
        {!postData.loading && !!postData.data.length ? (
          <Fragment>
            {/* <div className={"four-equals"}>
              {postData.data.map((datum) => {
                return (
                  (datum.media_type === 'STORY_MENTION') ? <InstaStory datum={datum} height={'430px'} /> :
                    <div key={datum.id} className={"mb-3"}>
                      <InstaPostCard data={datum} isWithStory={datum.media_type === 'STORY_MENTION' ? true : false} />
                    </div>
                );
              })}
            </div> */}
            <div className="f-grid-box" style={postData.data.length === 4 ? { justifyContent: 'space-between' } : {}}>
              {
                postData.data.map((datum) => {
                  return (
                    <InstaPostCard data={datum} isWithStory={datum.media_type === 'STORY_MENTION' ? true : false} showProfile={true} />
                  )

                })
              }
            </div>
          </Fragment>
        ) : !!postData.loading ? (
          <ThreeDotsLoader
            height={100}
            width={100}
            text={"Hold on! We are fetching latest post."}
          />
        ) : !postData.loading && !postData.data.length ? (
          <Fragment>
            <div className="d-flex flex-column align-items-center">
              <img src="/content/no_post.svg" alt="No Post" />
              <h3 className={'mt-3'}>We are all set, waiting for someone to mention you in a post</h3>
            </div>
            <ToastContainer />
          </Fragment>
        ) : (
          <></>
        )}
        {/* <div className={"d-flex justify-content-center mb-2 mt-2"}>
          {postData.count > perPage && (
            <Pagination
              totalRecords={postData.count}
              pageLimit={perPage}
              pageNeighbours={2}
              currentPage={parseInt(pageNum)}
              onPageChanged={(e) => getTaggedPost(parseInt(e.target.text))}
            ></Pagination>
          )}
        </div> */}
        <div className="d-flex justify-content-center mb-4 mt-3">
          {/* <LatestTaggedImages mediaType={'image'}></LatestTaggedImages> */}
          <Link className="ai-icon" to="/content">
            <button className="btn btn-outline-info">View All Posts</button>
          </Link>
        </div>
      </Fragment>
    </InfiniteScroll>

    //   );
    // } else {
    //   return (
    //     <Fragment>
    //       <h1>No Tagged Post</h1>
    //       <ToastContainer />
    //     </Fragment>
    //   );
    // }
  );
};
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};
export default connect(mapStateToProps)(Home);
