import React, { Fragment, useState } from "react";
import { useFormik, Formik, Form } from "formik";
import { DatePicker, DateRangePicker } from "rsuite";
import { rewardRuleInitialData, discountTypes, ruleProps, availableOperators, showAlert } from "../../../js/Helpers";
import CreateRewardRule from "./CreateRewardRule";
import { uploadCoupons } from "../../../services/PostsService";
import { isBefore } from "date-fns";
import { validationSchema } from "./RewardProgramCommons";
import swal from "sweetalert";
export default function RuleForm(props) {
    let [ruleScheduling, setRuleScheduling] = useState({ startDate: new Date(), endDate: new Date() })
    let [endDate, setEndDate] = useState(new Date())
    let [selectedFile, setSelectedFile] = useState(null)
    const formik = useFormik({
        initialValues: { ...props.formData },
        onSubmit: values => {
            //alert(JSON.stringify(values, null, 2));
            props.saveRule(values)
        },
        validationSchema
    })
    let { beforeToday } = DateRangePicker;
    let changeDateTime = (dt, type) => {
        let currentDate = { ...ruleScheduling };
        currentDate[type] = dt;
        setRuleScheduling(currentDate);
    }
    let uploadFile = () => {
        uploadCoupons(props.org.org_token, selectedFile).then(res => {
            swal({
                title: "Coupon Uploaded",
                icon: "success",
                button: "Okay",
            })
            console.log("coupons upload response", res)
        })
    }
    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit} id="rewardRuleForm">
                <div className="row mb-4">
                    <label className="mb-2 col-lg-3 col-md-3">
                        <strong className="">Name</strong>
                    </label>
                    <input type="text" name="name" value={formik.values.name} onChange={formik.handleChange}
                        className="form-control col-lg-9 col-md-9"
                    />
                    <span className="pull-right">{formik.errors.name}</span>
                </div>
                <div className="row mb-4">
                    <label className="mb-2 col-lg-3 col-md-3">
                        <strong className="">Description</strong>
                    </label>
                    <input type="text" name="description" value={formik.values.description} onChange={formik.handleChange}
                        className="form-control col-lg-9 col-md-9"
                    />
                    <span className="pull-right">{formik.errors.description}</span>
                </div>
                {
                    (props.type === 'discount_coupon') && (
                        <div className="row mb-4">
                            <label className="mb-2 col-lg-3 col-md-3">
                                <strong className="">Upload Coupons</strong>
                            </label>
                            <div className="col-lg-9 col-md-9 d-flex flex-column align-items-center justify-content-center p-0">
                                {/* <select className={"col-lg-4 col-md-4 prop-select"} name="metadata.discount_data.discount_type"
                                    value={formik.values.metadata.discount_data.discount_type} onChange={formik.handleChange}
                                >
                                    {discountTypes.map((pool) => {
                                        return <option value={pool.value}>{pool.label}</option>;
                                    })}
                                </select>

                                <input
                                    type="text" name="metadata.discount_data.discount_value" value={formik.values.metadata.discount_data.discount_value}
                                    className="form-control col-lg-8 col-md-8" onChange={formik.handleChange}
                                /> */}
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        className="custom-file-input"
                                        onChange={(e) => setSelectedFile(e.target.files[0])}
                                    />
                                    <label className="custom-file-label">
                                        {selectedFile?.name ? selectedFile.name : "Choose file"}
                                    </label>
                                </div>
                                <button className="btn btn-primary mt-3 " title={!selectedFile?.name ? 'Upload coupons csv' : ''} disabled={!selectedFile?.name} onClick={() => uploadFile()}>Upload</button>
                            </div>
                        </div>

                    )
                }
                <div className="row mb-4">
                    <div className="mb-2 col-lg-3 col-md-3 pr-5">
                        <select className={"w-100 p-0 prop-select black"} name="metadata.reward_rule.criteria"
                            value={formik.values.metadata.reward_rule.criteria} onChange={formik.handleChange}
                        >
                            {ruleProps.map((pool) => {
                                return <option value={pool.value}>{pool.label}</option>;
                            })}
                        </select>

                    </div>
                    <div className="col-lg-9 col-md-9 d-flex justify-content-between align-items-start p-0">
                        <select className={"w-100 col-lg-6 col-md-7 p-0 prop-select"} name="metadata.reward_rule.operator"
                            value={formik.values.metadata.reward_rule.operator} onChange={formik.handleChange}
                        >
                            {availableOperators[formik.values.metadata.reward_rule.criteria].map((pool) => {
                                return <option value={pool.value}>{pool.label}</option>;
                            })}
                        </select>
                        <input className="col-lg-5 col-md-5 form-control" type="number" name="metadata.reward_rule.quantity" value={formik.values.metadata.reward_rule.quantity} onChange={formik.handleChange}></input>
                    </div>

                </div>
                <div className="row mb-4">
                    <label className="mb-2 col-lg-3 col-md-3">
                        <strong className="">Start Time</strong>
                    </label>
                    <div className="col-lg-5 col-md-5">
                        <DatePicker format="yyyy-MM-dd HH:mm" name="start_time" className={'w-100'} disabledDate={beforeToday()}
                            value={formik.values.start_time} onOk={(value) => {
                                formik.setFieldValue("start_time", value)
                                setEndDate(value)
                            }} />
                    </div>
                </div>
                <div className="row mb-4">
                    <label className="mb-2 col-lg-3 col-md-3">
                        <strong className="">End Time</strong>
                    </label>
                    <div className="col-lg-5 col-md-5">
                        <DatePicker format="yyyy-MM-dd HH:mm" name="end_time" className={'w-100'} disabledDate={date => date.getDate() < endDate.getDate()}
                            disabledHours={hour => hour < endDate.getHours()}
                            disabledMinutes={minute => minute < endDate.getMinutes()}
                            value={formik.values.end_time} onOk={(value) => {
                                formik.setFieldValue("end_time", value)
                            }} />
                    </div>
                </div>
            </form>
        </Fragment>
    )
}