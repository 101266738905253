import React, { useEffect, useState, Fragment } from "react";
import { connect, useSelector } from "react-redux";
import {
  getGrowthToolsCampaign,
  createGrwothTool,
  deleteGrowthTool,
} from "../../../services/PostsService";
import { TableHeaders, showAlert } from "../../../js/Helpers";
import { Row, Col, Card } from "react-bootstrap";
import { GrowthToolsTable } from "../Commons/Common";
import swal from "sweetalert";
import { CopyToClipboard } from "react-copy-to-clipboard";

export function Create(props) {
  console.log("create props", props);
  let [selectedFile, setSelectedFile] = useState(null);
  document.title = "NitroCollab - Programs"
  const org = useSelector((store) => store.auth.org);
  let uploadFile = () => {
    createGrwothTool(org.org_token, selectedFile).then((res) => {
      !!res?.data?.metadata?.render_url &&
        (() => {
          swal({
            title: "Link Generated",
            text: res.data.metadata.render_url,
            icon: "success",
            button: "Copy Link",
            closeOnConfirm: true,
          }).then((isConfirm) => {
            navigator.clipboard.writeText(res.data.metadata.render_url);
            swal({
              title: "Copied",
              text: "Copied to clipboard",
              icon: "success",
              button: "Okay",
            }).then((isConfirm) => {
              props.history.push("/programs/overview");
            });
          });
        })();
    });
  };
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  return (
    <Fragment>
      <Row>
        <Col lg={12} md={12}>
          <Card>
            <Card.Header>
              <Card.Title>Upload html file to get landing page Url</Card.Title>
            </Card.Header>
            <Card.Body
              className={
                "d-flex justify-content-center flex-column align-items-center"
              }
            >
              <div className="input-group mb-3 col-lg-6 col-md-6">
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    onChange={handleFileSelect}
                  />
                  <label className="custom-file-label">
                    {selectedFile?.name ? selectedFile.name : "Choose file"}
                  </label>
                </div>
              </div>
              <button
                className="btn btn-primary"
                disabled={!selectedFile?.name}
                onClick={uploadFile}
              >
                Upload
              </button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}
export function Overview() {
  const org = useSelector((store) => store.auth.org);
  console.log("growth tool crete", org);
  let [growthTools, setGrowthTools] = useState({ items: [], count: 0 });
  let [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getCreatedGrowthTools();
    document.title = "NitroCollab - Programs"
  }, []);
  let getCreatedGrowthTools = () => {
    setIsLoading(true);
    getGrowthToolsCampaign(org.org_token).then((res) => {
      setGrowthTools({ items: res.data.items, count: res.data.count });
      setIsLoading(false);
    });
  };
  let deleteTool = (uuid) => {
    showAlert(
      "Are you sure?",
      "Do you want to delete?",
      true,
      "warning",
      true,
      () => {
        deleteGrowthTool(org.org_token, uuid).then((res) => {
          swal({
            title: "Deleted",
            icon: "success",
            button: "Okay",
          }).then((isConfirm) => {
            getCreatedGrowthTools();
          });
        });
      }
    );
  };
  return (
    <Fragment>
      <Card className={"border-grey"}>
        <Card.Body className={"pl-3 pr-3 pb-2 pt-0"}>
          <GrowthToolsTable
            growthToolData={growthTools.items}
            isLoading={isLoading}
            deleteTool={(uuid) => deleteTool(uuid)}
          />
        </Card.Body>
      </Card>
    </Fragment>
  );
}

