import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import logo from "../../images/logo-full.png";
import logoText from "../../images/fluenco-logo-text.png";
import {
  loadingToggleAction,
  signupAction,
  loginFailedAction,
} from "../../store/actions/AuthActions";
import { registrationData, signupSchemaValidation } from "./../../js/Helpers";
import { useFormik } from "formik";
const validationSchema = signupSchemaValidation;
function Register(props) {
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  // const formik = useFormik({
  //     initialValues: { ...registrationData },
  //     onSubmit: values => {
  //         alert(JSON.stringify(values))
  //     },
  //     validationSchema
  // })
  const formik = useFormik({
    initialValues: { ...registrationData },
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      //props.saveRule(values)
      onSignUp(values);
    },
    validationSchema,
  });
  function onSignUp(data) {
    // e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (error) return;
    dispatch(loadingToggleAction(true));

    dispatch(signupAction(data, props.history));
  }

  const refresh = () => {
    if (!!props.errorMessage) {
      dispatch(loginFailedAction(""));
    }
  };

  return (
    <div className="login-form-bx">
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-lg-6 col-md-7 box-skew d-flex align-items-center justify-content-center"
            style={{ overflow: "auto" }}
          >
            <div className="authincation-content">
              <div className="mb-4">
                <h3 className="mb-1 font-w600">
                  Welcome to{" "}
                  <span style={{ color: "#6bb12a" }}>NitroCollab</span>
                </h3>
                <p className="">Sign up by entering information below</p>
              </div>
              {props.errorMessage && (
                <div className="bg-red-300 text-danger border border-danger p-1 my-2">
                  {props.errorMessage}
                </div>
              )}
              <form onSubmit={formik.handleSubmit}>
                <input
                  type="hidden"
                  value={formik.values.registration_type}
                ></input>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Email</strong>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onFocus={refresh}
                  />
                  {formik.errors.email && (
                    <div className="text-danger fs-12">
                      {formik.errors.email}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Full Name</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="full_name"
                    value={formik.values.full_name}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.full_name && (
                    <div className="text-danger fs-12">
                      {formik.errors.full_name}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Phone</strong>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.phone && (
                    <div className="text-danger fs-12">
                      {formik.errors.phone}
                    </div>
                  )}
                </div>
                {/* <div className="form-group">
                                    <label className="mb-2 ">
                                        <strong className="">Organization Domain</strong>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="org_domain"
                                        value={formik.values.org_domain}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.errors.org_domain && (
                                        <div className="text-danger fs-12">{formik.errors.org_domain}</div>
                                    )}
                                </div> */}
                {/* <div className="form-group">
                                    <label className="mb-2 ">
                                        <strong className="">Organization Name</strong>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="org_name"
                                        value={formik.values.org_name}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.errors.org_name && (
                                        <div className="text-danger fs-12">{formik.errors.org_name}</div>
                                    )}
                                </div> */}
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Password</strong>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.password && (
                    <div className="text-danger fs-12">
                      {formik.errors.password}
                    </div>
                  )}
                </div>
                {/* <div className="form-group">
                                    <label className="mb-2 ">
                                        <strong className="">Confirm Password</strong>
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        name="confirmPassword"
                                        value={formik.values.confirmPassword}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.errors.confirmPassword && (
                                        <div className="text-danger fs-12">{formik.errors.confirmPassword}</div>
                                    )}
                                </div> */}
                {/* <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                    <div className="form-group">
                                        <div className="custom-control custom-checkbox ml-1 ">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="basic_checkbox_1"
                                            />
                                            <label
                                                className="form-check-label"
                                                htmlFor="basic_checkbox_1"
                                            >
                                                Remember my preference
                                            </label>
                                        </div>
                                    </div>
                                </div> */}
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-block"
                    style={{ backgroundColor: "#6bb12a", color: "white" }}
                  >
                    {props.showLoading && (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    )}{" "}
                    {props.showLoading && <>Please Wait...</>}
                    {!props.showLoading && <>Register</>}
                  </button>
                </div>
              </form>
              <div className="new-account mt-2">
                <p className="mb-0">
                  Have an account?{" "}
                  <Link className="text-black" to="./login" onClick={refresh}>
                    Log In
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-5 d-flex box-skew1 justify-content-center align-items-center">
            {/* <div className="inner-content align-self-center">
							<Link to="/dashboard" className="login-logo">
								<img src={logo} alt="" className="logo-icon mr-2"/>
								<img src={logotext} alt="" className="logo-text ml-1"/>
							</Link>
							<h2 className="m-b10 text-white">Login To You Now</h2>
							<p className="m-b40 text-white">User Experience & Interface Design Strategy SaaS Solutions</p>
							<ul className="social-icons mt-4">
								<li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
								<li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
								<li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
							</ul>
						</div> */}
            <img src="/auth-bg.svg" style={{ width: "80%" }} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);
