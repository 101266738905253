import React, { Fragment, useEffect, useState } from "react";
import Switch from "react-switch";
import { connect } from "react-redux";
import { HeaderStrip, useQuery } from "../Commons/Common";
import { useHistory } from "react-router-dom";
import { setAutoReplies, deleteAutoReply, toggleAutoReply, updateAutoReply } from "../../../store/actions";
import { Card, Col, Nav, Tab } from "react-bootstrap";
import { AutoReplyRuleTable, SelectRuleTypeModal } from "./AutoReplyHelper";
import Pagination from "../Commons/Pagination";
import {
  deleteRewardRule,
  getAutoReplies,
  deleteAutoReplyRule,
  fetchRule
} from "../../../services/PostsService";
import { showAlert } from "../../../js/Helpers";
import swal from "sweetalert";

const AutoReplyList = ({
  orgDetails,
  list,
  count,
  setAutoReplies,
  deleteAutoReply,
  toggleAutoReply,
  updateAutoReply
}) => {
  let history = useHistory();
  let searchParams = useQuery();
  let [pageNum, setPageNum] = useState(1);
  let perPage = 12;
  let [showModal, setShowModal] = useState(false);
  let [repliesList, setRepliesList] = useState({ data: [], count: 0 });
  let [isFetching, setIsFetching] = useState(false);
  function setReplies() {
    setTimeout(function () {
      setAutoReplies({ data: ["hu", "hu", "idsfh"], count: 5 });
    }, 1000);
  }
  let [ruleType, setRuleType] = useState(searchParams.get("type") || 'auto_reply');
  function getList(tab, page) {
    setIsFetching(true);
    setRepliesList({ data: [], count: 0 });
    getAutoReplies(orgDetails.org_token, tab || ruleType, page, perPage).then((res) => {
      setIsFetching(false);
      !!res.data &&
        res.data.items &&
        res.data.items.length &&
        setRepliesList({ data: res.data.items, count: res.data.count })
    });
  }
  function deleteRule(ruleUuid) {
    showAlert(
      "Are you sure?",
      "Do you want to delete?",
      true,
      "warning",
      true,
      () => {
        deleteAutoReplyRule(orgDetails.org_token, ruleUuid, ruleType).then((res) => {
          swal({
            title: "Deleted",
            icon: "success",
            button: "Okay",
          }).then((isConfirm) => {
            getList();
          });
        });
      }
    );
  }
  let handleTabChange = (tab) => {
    setRuleType(tab);
    getList(tab);
  }
  let toggleModal = () => {
    setShowModal(!showModal)
  };

  useEffect(() => {
    console.log("component reloaded");
    getList();
  }, []);
  return (
    <Fragment>
      <HeaderStrip
        title={"Overview"}
        buttonTitle={"Create Rule"}
        callback={() => toggleModal()}
      />
      <SelectRuleTypeModal showModal={showModal} toggleModal={() => toggleModal()} ruleType={ruleType} />
      <Col xl={12} md={12}>
        <Card>
          <div className='custom-tab-1 pl-3 pr-3'>
            <Tab.Container defaultActiveKey={ruleType} onSelect={handleTabChange}>
              <Nav as='ul' className='nav-tabs'>
                <Nav.Item as='li'>
                  <Nav.Link eventKey={'auto_reply'}>
                    Keyword Reply
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as='li'>
                  <Nav.Link eventKey={'default_reply'}>
                    Default Reply
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Tab.Container>
          </div>
          <Card.Body className={"pl-3 pr-3 pb-2 pt-0"}>
            <AutoReplyRuleTable
              orgDetails={orgDetails}
              ruleData={repliesList.data}
              history={history}
              deleteRule={(uuid) => {
                deleteRule(uuid);
              }}
              toggleRule={toggleAutoReply}
              ruleType={ruleType}
              getList={(ruleType) => getList(ruleType)}

            // editRule={updateAutoReply}
            />
            <div className={"d-flex justify-content-center mb-2 mt-3 pl-3 pr-3"}>
              {repliesList.count > perPage && (
                <Pagination
                  totalRecords={repliesList.count}
                  pageLimit={perPage}
                  pageNeighbours={3}
                  currentPage={parseInt(pageNum)}
                  onPageChanged={(e) => {
                    setPageNum(parseInt(e.target.text))
                    getList(undefined, parseInt(e.target.text), perPage)
                  }}
                ></Pagination>
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    ...state,
    orgDetails: state.auth.org,
    count: state.autoReplies.count,
    list: state.autoReplies,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setAutoReplies: (data, ruleType) => {
      dispatch(setAutoReplies({ ruleType: ruleType, data: data }));
    },
    deleteAutoReply: (uuid, ruleType) => {
      dispatch(deleteAutoReply({ ruleType: ruleType, data: uuid }));
    },
    toggleAutoReply: (uuid, status) => {
      dispatch(toggleAutoReply({ uuid: uuid, status: status }))
    },
    updateAutoReply: (data, uuid) => {
      dispatch(updateAutoReply({ data: data, uuid: uuid }))
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AutoReplyList);