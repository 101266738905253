import React, {useEffect, Fragment, useState} from 'react';
import {availableOperators, availableSource, filterPropsPool, initialQuery} from "../../../js/Helpers";
import {Formik, Form, FieldArray} from "formik"
import {Col, Row} from "react-bootstrap";
import _ from "lodash";
const Filters = (props) => {
    let criteria = props?.criteria?.length ? props.criteria : [initialQuery[props.type]];
    let [query, setQuery] = useState(criteria);
    return (
        <Fragment>
            <Formik initialValues={{
                criteria: query
            }} onSubmit={values => {props.applyFilterCallback(values)}}>
                {({values, handleChange}) => (
                    <Form noValidate autocomplete={'off'} id={props.formId}>
                        <FieldArray name={'criteria'}>
                            {({push, remove}) => (
                                <Fragment>
                                    {values.criteria.map((criterion, index) => {
                                        const property = `criteria[${index}].property`;
                                        const operator = `criteria[${index}].operator`;
                                        const value1 = `criteria[${index}].value1`
                                        return (
                                            <Row className={"mb-3"} key={index}>
                                                <Col xl="4" md="4">
                                                    <select
                                                        name={property}
                                                        value={criterion.property}
                                                        id={`property${index}`}
                                                        className={"col-lg-12 col-md-12 prop-select"}
                                                        onChange={handleChange}
                                                    >
                                                        {filterPropsPool[props.type].map((pool) => {
                                                            return <option value={pool.value}>{pool.label}</option>;
                                                        })}
                                                    </select>
                                                </Col>
                                                <Col xl="3" md="3">
                                                    <select
                                                        value={criterion.operator}
                                                        id={`operator${index}`}
                                                        className={"col-lg-12 col-md-12 filter-select"}
                                                        name={operator}
                                                        onChange={handleChange}
                                                        style={{
                                                            lineHeight: "40px",
                                                            color: "#7e7e7e",
                                                            paddingLeft: " 15px",
                                                        }}
                                                    >
                                                        {availableOperators[criterion.property].map((operator) => {
                                                            return (
                                                                <option value={operator.value}>{operator.label}</option>
                                                            );
                                                        })}
                                                    </select>
                                                </Col>
                                                <Col xl="4" md="4">
                                                    {['integer', 'string'].includes(_.find(filterPropsPool[props.type], { value: criterion.property }).dataType) && (
                                                        <Fragment>
                                                            <input
                                                                type={
                                                                    _.find(filterPropsPool[props.type], ["value", criterion.property]).inputType
                                                                }
                                                                className={"col-lg-12 col-md-12 filter-input"}
                                                                name={value1}
                                                                onChange={handleChange}
                                                                value={criterion.value1}
                                                            ></input>
                                                        </Fragment>
                                                    )}
                                                    {_.find(filterPropsPool[props.type], { value: criterion.property }).inputType ===
                                                    "dropdown" && (
                                                        <Fragment>
                                                            <select
                                                                value={criterion.value1}
                                                                onChange={handleChange}
                                                                id={`value${criterion.property}`}
                                                                className="col-lg-12 col-md-12 filter-select"
                                                                //options={availableSource}
                                                                name={value1}
                                                                style={{
                                                                    lineHeight: "40px",
                                                                    color: "#7e7e7e",
                                                                    paddingLeft: " 15px",
                                                                }}
                                                            >
                                                                {availableSource.map((source) => {
                                                                    return (
                                                                        <option value={source.value}>{source.label}</option>
                                                                    );
                                                                })}
                                                            </select>
                                                        </Fragment>
                                                    )}
                                                </Col>
                                                <Col xl={1} md={1}>
                                                    {values.criteria.length > 1 && (
                                                        <i
                                                            className="fa fa-times fa-2x text-danger"
                                                            onClick={() => remove(index)}
                                                        ></i>
                                                    )}
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                    <Row>
                                        <Col xl={11} lg={11}>
                                            <button
                                                className="btn btn-secondary pull-right"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    push({...initialQuery[props.type]});
                                                }}
                                            >
                                                Add Filter
                                            </button>
                                        </Col>
                                        <Col xl={1} lg={12}></Col>
                                    </Row>
                                </Fragment>
                            )}

                        </FieldArray>

                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}
export default Filters;